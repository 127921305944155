export function getStatusClass(status) {
  switch (status) {
    case "New":
      return "statusNew";
    case "Accepted":
      return "statusAccepted";
    case "Picked":
      return "statusPicked";
    case "Waiting":
      return "statusWaiting";
    case "Shipped":
      return "statusShipped";
    case "On the way":
      return "statusOnTheWay";
    case "Cancelled":
      return "statusCancelled";
    case "Delivered":
      return "statusDelivered";
    default:
      return "Waiting";
  }
}
