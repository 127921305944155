import React, { useEffect, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Provider } from "react-redux";

import "./App.css";
import { Sidebar } from "./components/sidebar/Sidebar";
import { Header } from "./components/header/Header";
import { Dashboard } from "./components/dashboard/Dashboard";
import { Deliveries } from "./components/deliveries/Deliveries";
import { OrderInformation } from "./components/UI/OrderInformation";
import { ThreeDotsModal } from "./components/modals/ThreeDotsModal";
import { EditDelivery } from "./components/modals/EditDelivery";
import { Drivers } from "./components/drivers/Drivers";
import { AddNewDriver } from "./components/modals/AddNewDriver";
import { DriverLocation } from "./components/modals/DriverLocation";
import { DriverInfo } from "./components/modals/DriverInfo";
import { DeleteModal } from "./components/modals/DeleteModal";
import { Addresses } from "./components/addresses/Addresses";
import { AddCustomerAddress } from "./components/modals/AddCustomerAddress";
import { DeleteCustomerAddress } from "./components/modals/DeleteCustomerAddress";
import { Login } from "./pages/login/Login";
import { Register } from "./pages/register/Register";
import store from "./store";
import { AddLaundry } from "./components/modals/AddLaundry";
import { ErrorMsg } from "./components/errorMsg/ErrorMsg";
import { SuccessNotificationModal } from "./components/modals/SuccessNotification";
import { ResetPassword } from "./pages/resetPassword/ResetPassword";
import { logoutUser } from "./redux/auth/slices/userSlice";
import {
  toggleAddresses,
  toggleDashboard,
  toggleDeliveries,
  toggleDrivers,
} from "./redux/globalFunctions/globalFnSlice";
import { AllDriversMap } from "./components/modals/allDriversMap/AllDriversMap";
import { fetchDriverLocation } from "./redux/driver/thunks/getDriverLocation";

function App() {
  const {
    isDashboardClicked,
    isDriversClicked,
    isDeliveriesClicked,
    isAddressesClicked,
    isOrderInfoClicked,
    showThreeDotsModal,
    isModalsBgShown,
    isDeleteModalShown,
    isDeleteConfirmShown,
    isEditModalShown,
    isAddNewDriverShown,
    isDriverLocationShown,
    isDriverInfoShown,
    isAddNewCustomerAddress,
    isDeleteCustomerAddress,
    isAddLaundry,
    isErrorMsg,
    errorMsg,
    successMsg,
    isMapShown,
  } = useSelector((state) => state.global);

  const userState = useSelector((state) => state.user);
  const token = userState.token;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tokenExpiryTime = useSelector((state) => state.user.tokenExpiryTime);
  const driverLocations = useSelector(
      (state) => state.driverLocation.locations.data
    );

  useEffect(() => {
      const currentTime = new Date().getTime();
      if (!driverLocations || driverLocations.length === 0 && !(tokenExpiryTime < currentTime)) {
        dispatch(fetchDriverLocation({}));
      }
    }, [dispatch, driverLocations]);


  // Function for logging out the user if the token expires.
  useEffect(() => {
    const currentTime = new Date().getTime();
    if (tokenExpiryTime < currentTime) {
      dispatch(logoutUser());
      if (location.pathname !== "/register" && location.pathname !== "/reset") {
        navigate("/login");
      }
    }
  }, [tokenExpiryTime, dispatch, location.pathname]);

  // State to manage if the path is checked
  const [isPathChecked, setIsPathChecked] = useState(false);

  useEffect(() => {
    if (location.pathname === "/deliveries") {
      dispatch(toggleDeliveries());
    } else if (location.pathname === "/dashboard") {
      dispatch(toggleDashboard());
    } else if (location.pathname === "/drivers") {
      dispatch(toggleDrivers());
    } else if (location.pathname === "/addresses") {
      dispatch(toggleAddresses());
    }
    setIsPathChecked(true);
  }, [location.pathname]);

  if (!isPathChecked) {
    // Return null or a loader while the path is being checked
    return null;
  }

  return (
    <Provider store={store}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/reset" element={<ResetPassword />} />
        <Route
          path="*"
          element={
            token ? (
              <div className="container">
                <Sidebar />
                <div className="headerAndContent">
                  <Header />
                  {isDashboardClicked && <Dashboard />}
                  {isDeliveriesClicked && <Deliveries />}
                  {isDriversClicked && <Drivers />}
                  {isAddressesClicked && <Addresses />}
                </div>
                {isOrderInfoClicked && <OrderInformation />}
                {showThreeDotsModal && <ThreeDotsModal />}
                {isModalsBgShown && (
                  <div className="modals">
                    {isDeleteModalShown && <DeleteModal />}
                    {isDeleteConfirmShown && (
                      <SuccessNotificationModal msg={successMsg} />
                    )}
                    {isEditModalShown && <EditDelivery />}
                    {isAddNewDriverShown && <AddNewDriver />}
                    {isDriverLocationShown && <DriverLocation />}
                    {isDriverInfoShown && <DriverInfo />}
                    {isAddNewCustomerAddress && <AddCustomerAddress />}
                    {isDeleteCustomerAddress && <DeleteCustomerAddress />}
                    {isAddLaundry && <AddLaundry />}
                    {isErrorMsg && <ErrorMsg msg={errorMsg} />}
                    {isMapShown && <AllDriversMap />}
                  </div>
                )}
              </div>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
      </Routes>
    </Provider>
  );
}

export default App;