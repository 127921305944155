import classes from "./DownloadApp.module.css";
import xIcon from "../../../images/deliveries/deliveriesModals/xIcon.svg";
import { useDispatch } from "react-redux";
import { setIsDownloadAppShown } from "../../../redux/globalFunctions/globalFnSlice";
import { useEscapeKeyClose } from "../../../helperFunctions/customHooks/useEscapeKeyClose";
import { useRef } from "react";
import { useClickOutsideClose } from "../../../helperFunctions/customHooks/useClickOutsideClose";
import PlaystoreLogo from "../../../images/playstore.png";
import iosAppStoreLogo from "../../../images/Apple-Logo.png";
import translations from "../../../translations.json";

export function DownloadApp() {
  const dispatch = useDispatch();
  const modalRef = useRef();

  function handleCloseModal() {
    dispatch(setIsDownloadAppShown(false));
  }

  useEscapeKeyClose(handleCloseModal);
  useClickOutsideClose(modalRef, handleCloseModal);

  const text = translations.downloadAppModal;

  return (
    <div className={classes.container} ref={modalRef}>
      <header>
        <h3>{text.heading}</h3>
        <span>
          <img src={xIcon} alt={text.closeIconAlt} onClick={handleCloseModal} />
        </span>
      </header>

      <div className={classes.apps}>
        <div className={classes.android}>
          <span>{text.android}</span>
          <img src={PlaystoreLogo} alt={text.androidAlt} />
        </div>
        <div className={classes.ios}>
          <span>{text.ios}</span>
          <img src={iosAppStoreLogo} alt={text.iosAlt} />
        </div>
      </div>
    </div>
  );
}
