import { createSlice } from "@reduxjs/toolkit";
import { fetchVehicles } from "../thunks/getVehicleThunk";

const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState: {
    vehicles: [],
    status: "idle", // 'idle' | 'loading' | 'success' | 'error'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVehicles.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchVehicles.fulfilled, (state, action) => {
        state.vehicles = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(fetchVehicles.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      });
  },
});

export default vehiclesSlice.reducer;
