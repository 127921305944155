// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #676c7e;
  font-size: 14px;
  line-height: 18.2px;
  padding: 10px 16px 10px 16px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  width: 97%;

  &:hover, &.selected {
    box-shadow: 0px 2px 4px 0px #00000014;
    box-shadow: 0px 6px 20px 0px #00000014;
    background-color: #f6f6f6;
    cursor: pointer;
  }
}

.tableData {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/DashboardTable.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,cAAc;EACd,eAAe;EACf,mBAAmB;EACnB,4BAA4B;EAC5B,yBAAyB;EACzB,kBAAkB;EAClB,UAAU;;EAEV;IACE,qCAAqC;IACrC,sCAAsC;IACtC,yBAAyB;IACzB,eAAe;EACjB;AACF;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB","sourcesContent":[".tableRow {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  color: #676c7e;\n  font-size: 14px;\n  line-height: 18.2px;\n  padding: 10px 16px 10px 16px;\n  border: 1px solid #f0f0f0;\n  border-radius: 8px;\n  width: 97%;\n\n  &:hover, &.selected {\n    box-shadow: 0px 2px 4px 0px #00000014;\n    box-shadow: 0px 6px 20px 0px #00000014;\n    background-color: #f6f6f6;\n    cursor: pointer;\n  }\n}\n\n.tableData {\n  width: 100px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
