import { createSlice } from "@reduxjs/toolkit";
import { createDepartment } from "../thunks/createDepartmentThunk";

const createDepartmentSlice = createSlice({
  name: "department",
  initialState: {
    status: "idle", // 'idle' | 'loading' | 'success' | 'error'
    error: null,
    department: null,
  },
  reducers: {
    resetCreateDepartmentStatus: (state) => {
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDepartment.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createDepartment.fulfilled, (state, action) => {
        state.department = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(createDepartment.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      });
  },
});

export const { resetCreateDepartmentStatus } = createDepartmentSlice.actions;
export default createDepartmentSlice.reducer;
