// src/redux/globalSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialGlobalState";

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setSelectedDriverId: (state, action) => {
      state.selectedDriverId = action.payload;
    },
    toggleDashboard: (state) => {
      state.isDashboardClicked = true;
      state.isDeliveriesClicked = false;
      state.isDriversClicked = false;
      state.isOrderInfoClicked = false;
      state.isAddressesClicked = false;
      state.isActiveSidebarBtn = "dashboard";
    },
    toggleDeliveries: (state) => {
      state.isDashboardClicked = false;
      state.isDeliveriesClicked = true;
      state.isDriversClicked = false;
      state.isOrderInfoClicked = false;
      state.isAddressesClicked = false;
      state.isActiveSidebarBtn = "deliveries";
    },
    toggleDrivers: (state) => {
      state.isDashboardClicked = false;
      state.isDeliveriesClicked = false;
      state.isDriversClicked = true;
      state.isOrderInfoClicked = false;
      state.isAddressesClicked = false;
      state.isActiveSidebarBtn = "drivers";
    },
    toggleAddresses: (state) => {
      state.isDashboardClicked = false;
      state.isDeliveriesClicked = false;
      state.isDriversClicked = false;
      state.isOrderInfoClicked = false;
      state.isAddressesClicked = true;
      state.isActiveSidebarBtn = "addresses";
    },
    toggleOrderInfo: (state) => {
      state.isOrderInfoClicked = true;
    },
    toggleThreeDotsModal: (state, action) => {
      state.modalPosition.x = action.payload.x;
      state.modalPosition.y = action.payload.y;
      state.showThreeDotsModal = !state.showThreeDotsModal;
      state.selectedDataForEdit = action.payload.result;
    },

    setModalPosition: (state, action) => {
      state.modalPosition = action.payload;
    },
    toggleEditMode: (state, action) => {
      state.isEditMode = action.payload;
    },
    setIsWithHistory: (state, action) => {
      state.isWithHistory = action.payload;
    },
    setIsDeleteModalShown: (state, action) => {
      state.isDeleteModalShown = action.payload;
    },
    setIsAddNewDriverShown: (state, action) => {
      state.isAddNewDriverShown = action.payload;
    },
    setIsDriverLocationShown: (state, action) => {
      state.isDriverLocationShown = action.payload;
    },
    setIsDriverInfoShown: (state, action) => {
      state.isDriverInfoShown = action.payload;
    },
    setIsDriverRoutes: (state, action) => {
      state.isDriverRoutes = action.payload;
    },
    setIsEditModalShown: (state) => {
      state.isEditModalShown = !state.isEditModalShown;
    },
    setIsDeleteConfirmShown: (state, action) => {
      state.isDeleteConfirmShown = action.payload;
    },
    setIsDeleteCustomerAddress: (state, action) => {
      state.isDeleteCustomerAddress = action.payload;
    },
    setIsAddNewCustomerAddress: (state, action) => {
      state.isAddNewCustomerAddress = action.payload;
    },
    setSelectedDataForEdit: (state, action) => {
      state.selectedDataForEdit = action.payload;
    },
    setIsCustomerActive: (state, action) => {
      state.isCustomerActive = action.payload;
    },
    setSelectedDelivery: (state, action) => {
      state.selectedDelivery = action.payload;
    },
    setIsAddLaundry: (state, action) => {
      state.isAddLaundry = action.payload;
    },
    setIsErrorMsg: (state, action) => {
      state.isErrorMsg = action.payload;
    },
    setErrorMsg: (state, action) => {
      state.errorMsg = action.payload;
    },
    setSuccessMsg: (state, action) => {
      state.successMsg = action.payload;
    },

    onEdit: (state) => {
      state.isModalsBgShown = !state.isModalsBgShown;
      state.isEditModalShown = !state.isEditModalShown;
      state.showThreeDotsModal = false;
    },
    setIsEditMode: (state, action) => {
      state.isEditMode = action.payload;
    },
    setShowThreeDotsModal: (state, action) => {
      state.showThreeDotsModal = action.payload;
    },
    onDelete: (state) => {
      state.isModalsBgShown = !state.isModalsBgShown;
      state.isDeleteModalShown = !state.isDeleteModalShown;
      state.showThreeDotsModal = false;
    },
    toggleAddNewDriverModal: (state) => {
      state.isModalsBgShown = !state.isModalsBgShown;
      state.isAddNewDriverShown = !state.isAddNewDriverShown;
      state.showThreeDotsModal = false;
    },
    toggleDriverInfoModal: (state) => {
      state.isModalsBgShown = !state.isModalsBgShown;
      state.isDriverInfoShown = !state.isDriverInfoShown;
      state.showThreeDotsModal = false;
    },
    onDeleteConfirm: (state) => {
      state.isModalsBgShown = true;
      state.isDeleteConfirmShown = true;
      state.isDeleteModalShown = false;
    },
    onErrorMessage: (state, action) => {
      state.isModalsBgShown = true;
      state.isErrorMsg = true;
    },
    setIsModalsBgShown: (state, action) => {
      state.isModalsBgShown = action.payload;
    },
    toggleDriverLocationModal: (state) => {
      state.isDriverLocationShown = !state.isDriverLocationShown;
      state.isModalsBgShown = !state.isModalsBgShown;
    },
    toggleAddLaundry: (state, action) => {
      state.isAddLaundry = !state.isAddLaundry;
      state.isModalsBgShown = state.isAddLaundry;
      state.selectedDataForEdit = action.payload;
    },
    toggleAddNewCustomerAddress: (state, action) => {
      state.isAddNewCustomerAddress = !state.isAddNewCustomerAddress;
      state.isModalsBgShown = !state.isModalsBgShown;
      state.selectedDataForEdit = action.payload;
    },
    toggleDeleteCustomerAddressModal: (state, action) => {
      state.isDeleteCustomerAddress = !state.isDeleteCustomerAddress;
      state.selectedDataForEdit = action.payload;
      state.isModalsBgShown = !state.isModalsBgShown;
    },
    setIsMapShown: (state, action) => {
      state.isMapShown = action.payload;
    },
    setAddressData: (state, action) => {
      state.addressData = action.payload;
    },
    setSelectedDriverInfo: (state, action) => {
      state.selectedDriverInfo = action.payload;
    },
    setIsDriverChanged: (state, action) => {
      state.isDriverChanged = action.payload;
    },
    setIsDownloadAppShown: (state, action) => {
      state.isDownloadAppShown = action.payload;
    },
  },
});

export const {
  setIsDownloadAppShown,
  setIsDriverChanged,
  setSelectedDriverInfo,
  setAddressData,
  setIsMapShown,
  toggleDeleteCustomerAddressModal,
  toggleAddNewCustomerAddress,
  toggleAddLaundry,
  toggleDriverLocationModal,
  setIsModalsBgShown,
  onDeleteConfirm,
  onErrorMessage,
  setSelectedDriverId,
  toggleDashboard,
  toggleDeliveries,
  toggleDrivers,
  toggleAddresses,
  toggleOrderInfo,
  toggleThreeDotsModal,
  setModalPosition,
  toggleEditMode,
  setIsWithHistory,
  setIsDeleteModalShown,
  setIsAddNewDriverShown,
  setIsDriverLocationShown,
  setIsDriverInfoShown,
  setIsDriverRoutes,
  setIsEditModalShown,
  setIsDeleteConfirmShown,
  setIsDeleteCustomerAddress,
  setIsAddNewCustomerAddress,
  setSelectedDataForEdit,
  setIsCustomerActive,
  setSelectedDelivery,
  setIsAddLaundry,
  setIsErrorMsg,
  setErrorMsg,
  setSuccessMsg,
  onEdit,
  setIsEditMode,
  setShowThreeDotsModal,
  onDelete,
  toggleAddNewDriverModal,
  toggleDriverInfoModal,
} = globalSlice.actions;

export default globalSlice.reducer;
