import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./DeleteModal.css";
import CloseIcon from "../../images/deliveries/deliveriesModals/xIcon.svg";
import { cancelPackage } from "../../redux/package/thunks/cancelPackageThunk";
import { deleteDriver } from "../../redux/driver/thunks/deleteDriverThunk";
import { resetCancelPackageStatus } from "../../redux/package/slices/cancelPackageSlice";
import { resetDriverDeleteStatus } from "../../redux/driver/slices/deleteDriverSlice";
import { useEscapeKeyClose } from "../../helperFunctions/customHooks/useEscapeKeyClose";
import { useClickOutsideClose } from "../../helperFunctions/customHooks/useClickOutsideClose";
import {
  onDelete,
  onDeleteConfirm,
  setErrorMsg,
  onErrorMessage,
  setSuccessMsg,
  setIsDeleteConfirmShown,
  setIsModalsBgShown,
  setIsErrorMsg,
} from "../../redux/globalFunctions/globalFnSlice";
import translations from "../../translations.json";

export function DeleteModal() {
  const { isWithHistory, selectedDataForEdit } = useSelector(
    (state) => state.global
  );
  const cancelDeliveryState = useSelector((state) => state.cancelDelivery);
  const deleteDriverState = useSelector((state) => state.deleteDriver);

  const dispatch = useDispatch();

  const handleButtonClick = () => {
    if (!isWithHistory) {
      dispatch(cancelPackage({ package_id: selectedDataForEdit.package_id }));
    } else {
      dispatch(deleteDriver({ u_id: selectedDataForEdit.u_id }));
    }
  };

  // If the enter key on the keyboard is pressed submit form.
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        handleButtonClick();
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleButtonClick]);

  // Handler for cancelDelivery statuses
  useEffect(() => {
    if (cancelDeliveryState.status === "success") {
      dispatch(onDelete());
      dispatch(onDeleteConfirm());
      dispatch(setSuccessMsg(cancelDeliveryState.package.msg));
      setTimeout(() => {
        dispatch(setIsDeleteConfirmShown(false));
        dispatch(setIsModalsBgShown(false));
      }, 1500);
    } else if (cancelDeliveryState.status === "error") {
      dispatch(onDelete());
      dispatch(setIsErrorMsg(true));
      dispatch(setErrorMsg(cancelDeliveryState.error));
      dispatch(onErrorMessage());
      setTimeout(() => {
        dispatch(setIsDeleteConfirmShown(false));
        dispatch(setIsModalsBgShown(false));
        dispatch(setIsErrorMsg(false));
        dispatch(setErrorMsg(""));
      }, 1500);
    }
    setTimeout(() => {
      dispatch(resetCancelPackageStatus());
    }, 500);
  }, [cancelDeliveryState.status, dispatch]);

  // Handler for deleteDriver statuses
  useEffect(() => {
    if (deleteDriverState.status === "success") {
      dispatch(onDelete());
      dispatch(onDeleteConfirm());
      dispatch(setSuccessMsg(deleteDriverState.message));
      setTimeout(() => {
        dispatch(setIsDeleteConfirmShown(false));
        dispatch(setIsModalsBgShown(false));
      }, 1500);
      setTimeout(() => {
        dispatch(setIsDeleteConfirmShown(false));
        dispatch(setIsModalsBgShown(false));
      }, 1500);
    } else if (deleteDriverState.status === "error") {
      dispatch(onDelete());
      dispatch(setIsErrorMsg(true));
      dispatch(setErrorMsg(deleteDriverState.error));
      dispatch(onErrorMessage());
      setTimeout(() => {
        dispatch(setIsDeleteConfirmShown(false));
        dispatch(setIsModalsBgShown(false));
        dispatch(setIsErrorMsg(false));
        dispatch(setErrorMsg(""));
      }, 1500);
    }

    setTimeout(() => {
      dispatch(resetDriverDeleteStatus());
    }, 500);
  }, [deleteDriverState.status, dispatch]);

  const isStatusLoading =
    cancelDeliveryState.status === "loading" ||
    deleteDriverState.status === "loading";

  const modalRef = useRef(null);

  // Functions for closing the modal when pressing Escape button on keyboard or clicking outside the modal.
  useEscapeKeyClose(() => dispatch(onDelete()));
  useClickOutsideClose(modalRef, () => dispatch(onDelete()));

  const modalText = translations.deleteModal;

  const headerText = isWithHistory
    ? modalText.deleteDriver.headerText
    : modalText.cancelDelivery.headerText;

  const confirmationText = isWithHistory
    ? modalText.deleteConfirmationText + " "
    : modalText.cancelDelivery.cancelConfirmationText + " ";

  const warningText = isWithHistory
    ? modalText.deleteDriver.warningText
    : modalText.cancelDelivery.warningText;

  return (
    <div className="deleteDeliveryModal" ref={modalRef}>
      <div className="topDiv">
        <h6>{headerText}</h6>
        <span className="closeDeleteBtn">
          <img
            src={CloseIcon}
            alt={modalText.closeIconAlt}
            onClick={() => dispatch(onDelete())}
          />
        </span>
      </div>
      <div className="confirmOrderNumber">
        {confirmationText}
        <span className="idSpan">
          {isWithHistory
            ? selectedDataForEdit.u_firstname
            : selectedDataForEdit.package_id}
          ?
        </span>
      </div>
      <p>{warningText}</p>
      <div className="deleteDeliveryBtns">
        <button
          className="cancelDeleteBtn"
          onClick={() => dispatch(onDelete())}
        >
          {modalText.cancel}
        </button>
        <button
          className="confirmDeleteBtn"
          onClick={handleButtonClick}
          disabled={isStatusLoading}
        >
          {isStatusLoading ? (
            <span className="loader"></span>
          ) : (
            modalText.confirm
          )}
        </button>
      </div>
    </div>
  );
}
