import { useRef } from "react";
import { getStatusClass } from "../../helperFunctions/getStatusClass";
import "./DeliveriesTable.css";
import { toggleThreeDotsModal, setIsWithHistory, setIsEditMode } from "../../redux/globalFunctions/globalFnSlice";
import { useDispatch } from "react-redux";

export function DeliveriesTableElements({
  order_number,
  customer,
  pickup_address,
  dropoff_address,
  date,
  time,
  driver_name,
  status,
  data,
}) {
  const buttonRef = useRef(null);
  const dispatch = useDispatch();

  const handleClick = () => {
    const scrollY = window.scrollY || window.pageYOffset; // Get the current scroll position
    const buttonPosition = buttonRef.current.getBoundingClientRect();
    const payload = {
      x: buttonPosition.left + window.scrollX - 28, // Get the absolute X position
      y: buttonPosition.top + scrollY - 50, // Get the absolute Y position
      result: data,
    };

    // Dispatch the action with the new payload
    dispatch(toggleThreeDotsModal(payload));
    dispatch(setIsWithHistory(false));
    dispatch(setIsEditMode(true));
  };

  return (
    <div className="deliveryTable">
      <div style={{ width: "50px" }}>{order_number}</div>
      <div style={{ width: "120px", overflowWrap: "break-word" }}>{customer}</div>
      <div style={{ width: "110px", overflowWrap: "break-word" }}>{pickup_address}</div>
      <div style={{ width: "120px", overflowWrap: "break-word" }}>{dropoff_address}</div>
      <div style={{ width: "100px" }}>{date}</div>
      <div style={{ width: "100px" }}>{time}</div>
      <div style={{ width: "100px", overflowWrap: "break-word" }}>{driver_name}</div>
      <div className={getStatusClass(status)} style={{ width: "80px" }}>
        {status}
      </div>
      <button className="threeDotsBtn" ref={buttonRef} onClick={handleClick}>
        ...
      </button>
    </div>
  );
}
