import React, { useEffect, useRef, useState } from "react";
import "./AddCustomerAddress.css";
import CloseIcon from "../../images/deliveries/deliveriesModals/xIcon.svg";
import MapboxComponent from "../mapboxComponent/MapboxComponent";
import translations from "../../translations.json";
import useEnterKey from "../../helperFunctions/customHooks/useEnterKeySubmit";
import { useDispatch, useSelector } from "react-redux";
import { createDepartment } from "../../redux/department/thunks/createDepartmentThunk";
import { resetCreateDepartmentStatus } from "../../redux/department/slices/createDepartmentSlice";
import { updateDepartment } from "../../redux/department/thunks/updateDepartmentThunk";
import { resetUpdateDepartmentStatus } from "../../redux/department/slices/updateDepartmentSlice";
import { useLocation } from "react-router-dom";
import { useEscapeKeyClose } from "../../helperFunctions/customHooks/useEscapeKeyClose";
import { useClickOutsideClose } from "../../helperFunctions/customHooks/useClickOutsideClose";
import {
  toggleAddLaundry,
  onDeleteConfirm,
  setErrorMsg,
  onErrorMessage,
  setIsEditMode,
  setIsEditModalShown,
  setIsModalsBgShown,
  setSuccessMsg,
  setIsDeleteConfirmShown,
  setIsErrorMsg,
  setAddressData,
  setSelectedAddress,
  setIsFetchCurrentAddress,
} from "../../redux/globalFunctions/globalFnSlice";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export function AddLaundry() {
  const {
    isEditMode,
    selectedDataForEdit,
    addressData,
    isFetchCurrentAddress,
  } = useSelector((state) => state.global);
  const createDepartmentState = useSelector((state) => state.createDepartment);
  const updateDepartmentState = useSelector((state) => state.updateDepartment);

  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const hasMounted = useRef(false);

  const [suggestions, setSuggestions] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [areSuggestionsOpen, setAreSuggestionsOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [countryCode, setCountryCode] = useState("us");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [formAddressData, setFormAddressData] = useState({
    d_country: "",
    d_city: "",
    d_postalCode: "",
  });

  const [formData, setFormData] = useState({
    d_name: "",
    d_phonenumber: "",
    d_address: "",
    d_latitude: "",
    d_longitude: "",
  });

  useEffect(() => {
    hasMounted.current = true;
  }, []);

  // Update address and coordinates when map is used
  useEffect(() => {
    if (addressData?.length !== 0) {
      // Filter addresses based on relevance
      const validAddresses = addressData.filter(
        (item) => item?.relevance >= 0.5
      );

      // Only show suggestions if there are valid addresses
      if (validAddresses.length > 0) {
        const suggestedAddresses = validAddresses.map(
          (item) => item.place_name
        );
        setSuggestions(suggestedAddresses);
      } else {
        setSuggestions([]);
      }

      const firstAddress = addressData[0];
      const geometry = firstAddress?.center;

      const latitude = geometry[1];
      const longitude = geometry[0];

      setFormData((prevData) => ({
        ...prevData,
        d_latitude: latitude,
        d_longitude: longitude,
      }));
    } else {
      setSuggestions([]);
    }
  }, [addressData]);

  const changeAddress = (newAddress, latitude, longitude) => {
    setFormData((prevData) => ({
      ...prevData,
      d_address: newAddress,
      d_latitude: latitude || prevData.d_latitude,
      d_longitude: longitude || prevData.d_longitude,
    }));
  };

  useEffect(() => {
    if (formData.d_address !== "" && addressData.length !== 0) {
      const context =
        addressData[isEditMode ? 0 : selectedIndex]?.context || [];
      const foundCity =
        context.find((entry) => entry.id.startsWith("place"))?.text || "";
      const foundCountry =
        context.find((entry) => entry.id.startsWith("country"))?.text || "";
      const postalCode =
        context.find((entry) => entry.id.startsWith("postcode"))?.text || "";

      setFormAddressData((prev) => ({
        ...prev,
        d_city: foundCity,
        d_country: foundCountry,
        d_postalCode: postalCode,
      }));
    }
  }, [formData.d_address, addressData]);

  useEffect(() => {
    if (formData.d_address === "") {
      setSuggestions([]);
      setAreSuggestionsOpen(false);
    } else {
      setTimeout(() => {
        setHasSearched(true);
      }, 600);
    }
  }, [formData.d_address]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    // For other fields, just update the state
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isEditModeFields) {
      return;
    } else if (isEditMode) {
      dispatch(
        updateDepartment({ d_id: selectedDataForEdit.d_id, ...formData })
      );
    } else {
      dispatch(createDepartment(formData));
    }
  };

  useEffect(() => {
    if (isEditMode && selectedDataForEdit) {
      dispatch(setSelectedAddress(selectedDataForEdit.d_address));
      setFormData({
        d_name: selectedDataForEdit.d_name || "",
        d_address: selectedDataForEdit.d_address || "",
        d_latitude: selectedDataForEdit.d_latitude || "",
        d_longitude: selectedDataForEdit.d_longitude || "",
      });
      setTimeout(() => {
        setFormData((prev) => ({
          ...prev,
          d_phonenumber: selectedDataForEdit.d_phonenumber || "",
        }));
      }, 1000);
    }
  }, [isEditMode, selectedDataForEdit]);

  // Handler for createDepartment status
  useEffect(() => {
    if (hasMounted.current) {
      if (createDepartmentState.status === "success") {
        dispatch(setSuccessMsg(createDepartmentState.department.msg));
        dispatch(setAddressData(""));
        setFormData("");
        dispatch(toggleAddLaundry());
        dispatch(onDeleteConfirm());
        setTimeout(() => {
          dispatch(setIsDeleteConfirmShown(false));
          dispatch(setIsModalsBgShown(false));
          dispatch(resetCreateDepartmentStatus());
          dispatch(setSuccessMsg(""));
          dispatch(setSelectedAddress(null));
        }, 1500);
      } else if (createDepartmentState.status === "error") {
        dispatch(toggleAddLaundry());
        dispatch(setIsErrorMsg(true));
        dispatch(setErrorMsg(createDepartmentState.error));
        dispatch(onErrorMessage());
        setTimeout(() => {
          dispatch(setIsDeleteConfirmShown(false));
          dispatch(setIsModalsBgShown(false));
          dispatch(setIsErrorMsg(false));
          dispatch(setErrorMsg(""));
          dispatch(resetCreateDepartmentStatus());
          dispatch(setSelectedAddress(null));
        }, 1500);
      }
    }
  }, [createDepartmentState.status, dispatch]);

  // Handler for updateDepartment status
  useEffect(() => {
    if (hasMounted.current) {
      if (updateDepartmentState.status === "success") {
        dispatch(toggleAddLaundry());
        dispatch(setSuccessMsg(updateDepartmentState.department.msg));
        dispatch(setAddressData(""));
        setFormData("");
        dispatch(onDeleteConfirm());
        setTimeout(() => {
          dispatch(setIsDeleteConfirmShown(false));
          dispatch(setIsModalsBgShown(false));
          dispatch(resetUpdateDepartmentStatus());
          dispatch(setSuccessMsg(""));
          dispatch(setSelectedAddress(null));
        }, 1500);
      } else if (updateDepartmentState.status === "error") {
        dispatch(toggleAddLaundry());
        dispatch(setErrorMsg(updateDepartmentState.error));
        dispatch(onErrorMessage());
        setTimeout(() => {
          dispatch(setIsDeleteConfirmShown(false));
          dispatch(setIsModalsBgShown(false));
          dispatch(setIsErrorMsg(false));
          dispatch(setErrorMsg(""));
          dispatch(resetUpdateDepartmentStatus());
          dispatch(setSelectedAddress(null));
        }, 1500);
      }
    }
  }, [updateDepartmentState.status, dispatch]);

  const isStatusLoading =
    createDepartmentState.status === "loading" ||
    updateDepartmentState.status === "loading";

  const isEditModeFields =
    formData.d_name === "" ||
    formData.d_phonenumber === "" ||
    formData.d_address === "" ||
    formData.d_phonenumber?.length < 7;

  function handleCloseModal() {
    if (
      location.pathname === "/deliveries" ||
      location.pathname === "/dashboard"
    ) {
      dispatch(toggleAddLaundry());
      dispatch(setIsModalsBgShown(true));
      dispatch(setIsEditModalShown(true));
      dispatch(setIsEditMode(false));
      setFormData("");
    } else {
      dispatch(toggleAddLaundry());
      setFormData("");
    }
    dispatch(setSelectedAddress(null));
    dispatch(setAddressData(""));
  }

  useEffect(() => {
    if (
      location.pathname === "/deliveries" &&
      createDepartmentState.status === "success"
    ) {
      setTimeout(() => {
        dispatch(setIsModalsBgShown(true));
        dispatch(setIsEditModalShown(true));
        dispatch(setIsEditMode(false));
      }, 1500);
    }
  }, [createDepartmentState.status]);

  // Functions for closing the modal when pressing Escape button on keyboard or clicking outside the modal.
  useEscapeKeyClose(handleCloseModal);
  useClickOutsideClose(modalRef, handleCloseModal);
  useEnterKey(handleSubmit);

  const modalText = translations.addLaundryModal;
  const headerText = isEditMode ? modalText.editText : modalText.addText;

  useEffect(() => {
    if (suggestions.length !== 0 && hasSearched) {
      setAreSuggestionsOpen(true);
    } else {
      setAreSuggestionsOpen(false);
    }
  }, [suggestions, hasSearched]);

  useEffect(() => {
    if (formData.d_address === "" && suggestions.length !== 0) {
      dispatch(setAddressData([]));
    }
  }, [formData.d_address, suggestions]);

  function onAddressClick(item, index) {
    setSelectedIndex(index);
    dispatch(setSelectedAddress(item));
    setFormData((prev) => ({ ...prev, d_address: item }));
    setAreSuggestionsOpen(false);
  }

  useEffect(() => {
    if (formData.d_address && addressData.length !== 0) {
      if (addressData[0].properties.short_code) {
        const code = addressData[0]?.properties?.short_code;
        const countryCode = code.split("-")[0];
        setCountryCode(countryCode);
      } else if (addressData[0].context) {
        const countryContext = addressData[0]?.context.find((entry) =>
          entry.id.startsWith("country")
        );
        if (countryContext) {
          const shortCode = countryContext.short_code;
          setCountryCode(shortCode);
        } else {
          setCountryCode("us");
        }
      } else {
        setCountryCode("us");
      }
    } else {
      return;
    }
  }, [formData.d_address, addressData]);

  useEffect(() => {
    if (
      hasSearched &&
      suggestions.length === 0 &&
      formData.d_address !== "" &&
      !isEditMode
    ) {
      setIsErrorShown(true);
    } else {
      setIsErrorShown(false);
    }
  }, [hasSearched, suggestions, formData.d_address]);

  function handleCloseDropdowns(e) {
    if (areSuggestionsOpen) {
      setAreSuggestionsOpen(false);
    } else if (isErrorShown) {
      setIsErrorShown(false);
    }
  }

  function handleFocusAddress() {
    if (formData.d_address !== "" && suggestions.length !== 0) {
      dispatch(setIsFetchCurrentAddress(false));
      setAreSuggestionsOpen(true);
    }
  }

  function handleAddressOnChange(e) {
    dispatch(setIsFetchCurrentAddress(false));
    setFormData((prev) => ({ ...prev, d_address: e.target.value }));
    if (formData.d_address === "") {
      setHasSearched(false);
    } else {
      dispatch(setSelectedAddress(null));
      setHasSearched(true);
    }
  }

  return (
    <div className="addCustomerAddress" ref={modalRef}>
      <div className="addCustomerAddressHeading">
        <h6>{headerText}</h6>
        <span className="closeBtn" onClick={handleCloseModal}>
          <img src={CloseIcon} alt={modalText.closeIconAlt} />
        </span>
      </div>
      <form className="addCustomerAddressForm">
        <div className="addLaundryElements">
          <div className="formElement">
            <label htmlFor="d_name">{modalText.form.laundry}</label>
            <input
              type="text"
              id="d_name"
              value={formData.d_name || ""}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <div className="addCustomerElements">
          <div className="formElementSmall">
            <label htmlFor="d_country">{modalText.form.country}</label>
            <input
              type="text"
              id="d_country"
              value={formAddressData.d_country || ""}
              readOnly
              disabled
            />
          </div>
          <div className="formElementSmall">
            <label htmlFor="d_city">{modalText.form.city}</label>
            <input
              type="text"
              id="d_city"
              value={formAddressData.d_city || ""}
              readOnly
              disabled
            />
          </div>
          <div className="formElementSmall">
            <label htmlFor="d_postalCode">{modalText.form.postalCode}</label>
            <input
              type="text"
              id="d_postalCode"
              value={formAddressData.d_postalCode || ""}
              readOnly
              disabled
            />
          </div>
        </div>
        <div className="addLaundryElements">
          <div className="formElementAddress">
            <label htmlFor="d_address">{modalText.form.address}</label>
            <input
              type="text"
              id="d_address"
              value={formData.d_address || ""}
              onFocus={handleFocusAddress}
              onChange={(e) => handleAddressOnChange(e)}
              required
            />
            {areSuggestionsOpen && !isFetchCurrentAddress && (
              <div className="suggestionsContainer">
                <div>
                  <button onClick={handleCloseDropdowns}>
                    <img src={CloseIcon} alt={modalText.closeIconAlt} />
                  </button>
                </div>
                {suggestions.map((item, index) => (
                  <span key={index} onClick={() => onAddressClick(item, index)}>
                    {item}
                  </span>
                ))}
              </div>
            )}
            {isErrorShown && (
              <span className="noResultsFound">{modalText.noResultsFound}</span>
            )}
          </div>
        </div>
        <div className="formElement">
          <label htmlFor="d_phonenumber">{modalText.form.phone}</label>
          <PhoneInput
            country={countryCode.toLocaleLowerCase() || "us"}
            value={formData.d_phonenumber || ""}
            onChange={(phone) =>
              setFormData((prev) => ({ ...prev, d_phonenumber: phone }))
            }
            inputProps={{
              name: "phone",
              required: true,
            }}
            containerClass="phone-input-container"
            inputClass="phone-input"
          />
        </div>
        <MapboxComponent
          address={formData.d_address || ""}
          country={formData.d_country || ""}
          city={formData.d_city || ""}
          updateAddress={changeAddress}
        />
      </form>
      <button
        className="saveBtn"
        onClick={handleSubmit}
        disabled={isEditModeFields || isStatusLoading}
      >
        {isStatusLoading ? <span className="loader"></span> : modalText.saveBtn}
      </button>
    </div>
  );
}
