import Icon from "../../images/orderInformation/Icon.svg";
import ArrowBlueLeft from "../../images/orderInformation/arrowBlueLeft.svg";
import ArrowBlueRight from "../../images/orderInformation/arrowBlueRight.svg";
import ArrowGrayLeft from "../../images/orderInformation/arrowGrayLeft.svg";
import ArrowGrayRight from "../../images/orderInformation/arrowGrayRight.svg";
import Map from "../../images/orderInformation/map.png";
import CircleWithDotBlue from "../../images/orderInformation/circleWithDotIconBlue.svg";
import CircleWithDotGray from "../../images/orderInformation/circleWithDotIconGray.svg";
import AddressIconBlue from "../../images/orderInformation/addressIconBlue.svg";
import AddressIconGray from "../../images/orderInformation/addressIconGray.svg";
import AddressIconRed from "../../images/orderInformation/addressIconRed.svg";
import "./OrderInformation.css";
import translations from "../../translations.json";

export function OrderInformation({
  driver_name,
  order_number,
  date,
  time,
  address,
  shipping_status,
  selectedIndex,
  deliveries = [],
  onClose,
  onPrev,
  onNext,
}) {
  const isFirst = selectedIndex === 0;
  const isLast = selectedIndex === deliveries.length - 1;

  const getStatusStyles = () => {
    switch (shipping_status) {
      case "Waiting":
        return {
          icon1: CircleWithDotGray,
          icon2: CircleWithDotGray,
          icon3: AddressIconGray,
          lineClass: "gray",
          statusText1: "Waiting",
          statusText2: "",
          statusText3: "",
          smallText1: "Waiting",
          smallText3: "Delivered",
        };
      case "Shipped":
        return {
          icon1: CircleWithDotBlue,
          icon2: CircleWithDotGray,
          icon3: AddressIconGray,
          lineClass: "blue",
          statusText1: "Shipped",
          statusText2: "",
          statusText3: "",
          smallText1: "Shipped",
          smallText3: "Delivered",
        };
      case "On the way":
        return {
          icon1: CircleWithDotBlue,
          icon2: CircleWithDotBlue,
          icon3: AddressIconGray,
          lineClass: "blue",
          statusText1: "Done",
          statusText2: `${date} ${time}`,
          statusText3: "",
          smallText1: "Shipped",
          smallText3: "Delivered",
        };
      case "Delivered":
        return {
          icon1: CircleWithDotBlue,
          icon2: CircleWithDotBlue,
          icon3: AddressIconBlue,
          lineClass: "blue",
          statusText1: "Done",
          statusText2: `Done`,
          statusText3: `${date} ${time}`,
          smallText1: "Shipped",
          smallText3: "Delivered",
        };
      case "Cancelled":
        return {
          icon1: CircleWithDotBlue,
          icon2: CircleWithDotBlue,
          icon3: AddressIconRed,
          lineClass: "blue",
          statusText1: "Done",
          statusText2: `${date} ${time}`,
          statusText3: "Cancelled",
          smallText1: "Shipped",
          smallText3: "Cancelled",
        };
      default:
        return {
          icon1: CircleWithDotGray,
          icon2: CircleWithDotGray,
          icon3: AddressIconGray,
          lineClass: "gray",
          statusText1: "",
          statusText2: "",
          statusText3: "",
          smallText1: "Shipped",
          smallText3: "Delivered",
        };
    }
  };

  const statusStyles = getStatusStyles();

  return (
    <div className="orderInfoContainer">
      <div className="firstDiv">
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <div
            style={{
              padding: "12px",
              backgroundColor: "white",
              borderRadius: "10px",
            }}
          >
            <img src={Icon} alt="Icon" />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <h6>
              {translations.orderInformation.driver} {driver_name}
            </h6>
            <p>
              {translations.orderInformation.order} {order_number}
            </p>
          </div>
        </div>
        <div style={{ display: "flex", gap: "4px" }}>
          <button
            onClick={onPrev}
            disabled={isFirst}
            className="prevBtnDashboard"
          >
            <img
              src={isFirst ? ArrowGrayLeft : ArrowBlueLeft}
              alt={translations.orderInformation.iconAlts.arrowLeftAlt}
            />
          </button>
          <button
            onClick={onNext}
            disabled={isLast}
            className="nextBtnDashboard"
          >
            <img
              src={isLast ? ArrowGrayRight : ArrowBlueRight}
              alt={translations.orderInformation.iconAlts.arrowRightAlt}
            />
          </button>
        </div>
      </div>
      <div className="secondDiv">
        <div style={{ position: "relative" }}>
          <div style={{ width: "100%", height: "300px" }}>
            <img
              src={Map}
              alt={translations.orderInformation.iconAlts.mapIconAlt}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </div>
          <div className="orderInformationDiv">
            <div>
              <h6>{translations.orderInformation.headerText}</h6>
            </div>
            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <p>{translations.orderInformation.date}</p>
              <span>{date}</span>
            </div>
            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <p>{translations.orderInformation.time}</p>
              <span>{time}</span>
            </div>
            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <p>{translations.orderInformation.address}</p>
              <span>{address}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="thirdDiv">
        <div className="shippingDetails">
          <div className="shipped">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <img
                src={statusStyles.icon1}
                alt={translations.orderInformation.iconAlts.circleWithDotAlt}
              />
              <div className={`line ${statusStyles.lineClass}`}></div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              <small>{statusStyles.smallText1}</small>
              <p>{statusStyles.statusText1}</p>
            </div>
          </div>
          <div className="onTheWay">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <img
                src={statusStyles.icon2}
                alt={translations.orderInformation.iconAlts.circleWithDotAlt}
              />
              <div className={`line ${statusStyles.lineClass}`}></div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              <small>{translations.orderInformation.onTheWay}</small>
              <p>{statusStyles.statusText2}</p>
            </div>
          </div>
          <div className="delivered">
            <img
              src={statusStyles.icon3}
              alt={translations.orderInformation.iconAlts.addressIconAlt}
            />
            <div
              style={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              <small>{statusStyles.smallText3}</small>
              <p>{statusStyles.statusText3}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="fourthDiv">
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
}
