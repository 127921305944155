import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../redux/auth/slices/userSlice";
import { fetchAvatar } from "../../redux/auth/thunks/userThunk";
import { setSearchQuery } from "../../redux/misc/slices/searchSlice";
import Logo from "../../images/sidebar/Logo.svg";
import DashboardIcon from "../../images/sidebar/dashboardIcon.svg";
import DeliveriesIcon from "../../images/sidebar/deliveriesIcon.svg";
import DriversIcon from "../../images/sidebar/driversIcon.svg";
import AddressesIcon from "../../images/sidebar/addressesIcon.svg";
import Avatar from "../../images/sidebar/avatar.png";
import LogOutIcon from "../../images/sidebar/logOutIcon.svg";
import "./Sidebar.css";
import {
  toggleDashboard,
  toggleAddresses,
  toggleDeliveries,
  toggleDrivers,
  setShowThreeDotsModal,
} from "../../redux/globalFunctions/globalFnSlice";
import translations from "../../translations.json";

export function Sidebar() {
  const { isActiveSidebarBtn } = useSelector((state) => state.global);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const firstname = useSelector((state) => state.user.firstname);
  const fetchedAvatar = useSelector((state) => state.user.avatarBlob);
  const tokenExpiryTime = useSelector((state) => state.user.tokenExpiryTime);

  useEffect(() => {
    if (tokenExpiryTime) {
      const currentTime = new Date().getTime();
      const timeLeft = tokenExpiryTime - currentTime;

      const timer = setTimeout(() => {
        dispatch(logoutUser());
        navigate("/login");
      }, timeLeft);

      return () => clearTimeout(timer);
    }
  }, [tokenExpiryTime, dispatch, navigate]);

  useEffect(() => {
    dispatch(fetchAvatar());
  }, [dispatch]);

  function logOut() {
    dispatch(logoutUser());
    dispatch(setSearchQuery(""));
    setHeaderSearchQuery("");
    if (window.location.pathname !== "/login") {
      navigate("/login");
    }
  }

  function handleNavigation(path) {
    dispatch(setSearchQuery(""));
    setHeaderSearchQuery("");
    navigate(path);
  }

  function setHeaderSearchQuery(query) {
    const searchInput = document.getElementById("search");
    if (searchInput) {
      searchInput.value = query;
    }
  }

  const sidebarText = translations.sidebarMenu;

  return (
    <section className="sidebar">
      <div className="logo">
        <img src={Logo} alt={sidebarText.logoAlt} />
      </div>
      <div className="menu">
        <div className="buttons">
          <button
            className={
              isActiveSidebarBtn === "dashboard" ? "activeBtn" : "dashboardBtn"
            }
            onClick={() => {
              dispatch(toggleDashboard());
              handleNavigation("/dashboard");
              dispatch(setShowThreeDotsModal(false));
            }}
          >
            <img src={DashboardIcon} alt={sidebarText.dashboardIconAlt} />
            {sidebarText.dashboard}
          </button>
          <button
            className={
              isActiveSidebarBtn === "deliveries"
                ? "activeBtn"
                : "deliveriesBtn"
            }
            onClick={() => {
              dispatch(toggleDeliveries("deliveries"));
              handleNavigation("/deliveries");
              dispatch(setShowThreeDotsModal(false));
            }}
          >
            <img src={DeliveriesIcon} alt={sidebarText.deliveriesIconAlt} />
            {sidebarText.deliveries}
          </button>
          <button
            className={
              isActiveSidebarBtn === "drivers" ? "activeBtn" : "driversBtn"
            }
            onClick={() => {
              dispatch(toggleDrivers("drivers"));
              handleNavigation("/drivers");
              dispatch(setShowThreeDotsModal(false));
            }}
          >
            <img src={DriversIcon} alt={sidebarText.driversIconAlt} />
            {sidebarText.drivers}
          </button>
          <button
            className={
              isActiveSidebarBtn === "addresses" ? "activeBtn" : "addressesBtn"
            }
            onClick={() => {
              dispatch(toggleAddresses("addresses"));
              handleNavigation("/addresses");
              dispatch(setShowThreeDotsModal(false));
            }}
          >
            <img src={AddressesIcon} alt={sidebarText.addressesIconAlt} />
            {sidebarText.addresses}
          </button>
        </div>
      </div>
      <div className="username">
        <div className="avatar">
          <div className="avatarImgDiv">
            <img
              src={fetchedAvatar || Avatar} // Use fetched avatar or fallback to static avatar
              alt={sidebarText.avatarIconAlt}
            />
            <span>{firstname}</span>
          </div>
          <img
            className="logOutIcon"
            src={LogOutIcon}
            alt={sidebarText.logOutIconAlt}
            onClick={logOut}
          />
        </div>
      </div>
    </section>
  );
}
