import React, { useEffect, useRef, useState } from "react";
import Map, { Marker, Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useDispatch, useSelector } from "react-redux";
import classes from "./AllDriversMap.module.css";
import CloseIcon from "../../../images/deliveries/deliveriesModals/xIcon.svg";
import { useClickOutsideClose } from "../../../helperFunctions/customHooks/useClickOutsideClose";
import { useEscapeKeyClose } from "../../../helperFunctions/customHooks/useEscapeKeyClose";
import {
  setIsDriverChanged,
  setIsEditModalShown,
  setIsMapShown,
  setIsModalsBgShown,
  setSelectedDriverInfo,
} from "../../../redux/globalFunctions/globalFnSlice";
import { useLocation } from "react-router-dom";
import { fetchDriverLocation } from "../../../redux/driver/thunks/getDriverLocation";
import translations from "../../../translations.json";

export function AllDriversMap() {
  const [coordinatesList, setCoordinatesList] = useState([]);
  const [viewState, setViewState] = useState({
    zoom: 7.4,
    latitude: 51.505, // Default latitude (e.g., London)
    longitude: -0.09, // Default longitude (e.g., London)
  });

  const dispatch = useDispatch();
  const { isMapShow } = useSelector((state) => state.global);
  const driverLocations = useSelector(
    (state) => state.driverLocation.locations.data
  );
  const modalRef = useRef();
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchDriverLocation({}));
  }, [isMapShow]);

  const notActiveDrivers =
    driverLocations &&
    driverLocations.filter((driver) => driver.dr_current_location === null);

  function handleCloseModal() {
    if (
      location.pathname === "/deliveries" ||
      location.pathname === "/dashboard"
    ) {
      dispatch(setIsMapShown(false));
      dispatch(setIsModalsBgShown(true));
      dispatch(setIsEditModalShown(true));
    } else {
      dispatch(setIsMapShown(false));
      dispatch(setIsModalsBgShown(false));
    }
  }

  const mapboxToken =
    "pk.eyJ1IjoiYm96aWRhcmszNSIsImEiOiJjbTFxNTdzMWUwOWZwMmpzYjN4eHF5Zmt5In0.fVeom9WzttFClBuhzNSjKg";

  const geocodeAddress = async (address) => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          address
        )}.json?access_token=${mapboxToken}`
      );
      const data = await response.json();
      const [lng, lat] = data.features[0].center;
      return { lat, lng };
    } catch (error) {
      console.error("Error fetching the coordinates:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchCoordinates = async () => {
      if (driverLocations && driverLocations.length > 0) {
        const coordsPromises = driverLocations.map(async (item) => {
          const coords = await geocodeAddress(item.dr_current_location);
          return {
            ...coords,
            driverName: item.u_firstname + " " + item.u_lastname,
            item,
          };
        });
        const coords = await Promise.all(coordsPromises);
        const validCoords = coords.filter(Boolean);

        // Group by coordinates (latitude and longitude)
        const groupedCoords = validCoords.reduce((acc, coord) => {
          const key = `${coord.lat}-${coord.lng}`;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(coord);
          return acc;
        }, {});

        // Adjust coordinates for overlapping markers
        const adjustedCoords = Object.values(groupedCoords).flatMap((group) => {
          if (group.length > 1) {
            return group.map((coord, index) => ({
              ...coord,
              lat: coord.lat + index * 0.0001, // Adjust latitude slightly
              lng: coord.lng + index * 0.0001, // Adjust longitude slightly
            }));
          }
          return group;
        });

        setCoordinatesList(adjustedCoords);

        if (adjustedCoords.length > 0) {
          setViewState((prevState) => ({
            ...prevState,
            latitude: adjustedCoords[0].lat + 0.5,
            longitude: adjustedCoords[0].lng,
          }));
        }
      }
    };

    fetchCoordinates();
  }, [driverLocations]);

  // Functions for closing the modal when pressing Escape button on keyboard or clicking outside the modal.
  useEscapeKeyClose(handleCloseModal);
  useClickOutsideClose(modalRef, handleCloseModal);

  const isDriverUrlRoute = location.pathname === "/drivers";

  return (
    <div className={classes.container} ref={modalRef}>
      <div className={classes.header}>
        <h3>{translations.allDriversLocationsMapModal.headerText}</h3>
        <span onClick={handleCloseModal}>
          <img
            src={CloseIcon}
            alt={translations.allDriversLocationsMapModal.closeIconAlt}
          />
        </span>
      </div>
      {notActiveDrivers && !isDriverUrlRoute && (
        <div className={classes.notActiveDrivers}>
          <p>{translations.allDriversLocationsMapModal.selectDriver}</p>
          {notActiveDrivers.map((driver) => (
            <div
              key={driver.dr_uid}
              onClick={() => {
                dispatch(setSelectedDriverInfo(driver));
                dispatch(setIsDriverChanged(true));
                handleCloseModal();
              }}
            >
              {driver.u_firstname + " " + driver.u_lastname}
            </div>
          ))}
        </div>
      )}
      <div className={classes.mapContainer}>
        <Map
          {...viewState}
          onMove={(evt) => setViewState(evt.viewState)}
          mapStyle="mapbox://styles/mapbox/streets-v11"
          mapboxAccessToken={mapboxToken}
          className={classes.map}
        >
          {coordinatesList.map((coordinates, index) => (
            <div key={index}>
              <Marker
                key={index}
                latitude={coordinates.lat}
                longitude={coordinates.lng}
                onClick={
                  !isDriverUrlRoute
                    ? () => {
                        dispatch(setSelectedDriverInfo(coordinates.item));
                        dispatch(setIsDriverChanged(true));
                        handleCloseModal();
                      }
                    : undefined
                }
              />
              <Popup
                longitude={coordinates.lng}
                latitude={coordinates.lat}
                closeButton={true}
                closeOnClick={false}
                offset={35}
              >
                <p>{coordinates.driverName}</p>
              </Popup>
            </div>
          ))}
        </Map>
      </div>
    </div>
  );
}
