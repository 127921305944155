import classes from "./ResetPassword.module.css";
import Logo from "../../images/Logo.svg";
import Background from "../../images/BG.svg";
import NorwayFlag from "../../images/NorwayFlag.svg";
import EnglishFlag from "../../images/EnglishFlag.svg";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  resetPassword,
  resetPasswordState,
} from "../../redux/resetPass/resetPass";
import { LoadingSpinner } from "../../components/loadingSpinner/LoadingSpinner";
import translations from "../../translations.json";
import whiteLogo from "../../images/sidebar/Logo.svg";

export function ResetPassword() {
  const [isEnglishLanguage, setIsEnglishLanguage] = useState(true);
  const location = useLocation();
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [token, setToken] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const resetPassState = useSelector((state) => state.resetPassword);

  // Function for validating the token
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tokenParam = params.get("token");

    if (tokenParam) {
      setToken(tokenParam);
    } else {
      navigate("/login");
    }
  }, [location, dispatch, navigate]);

  function onPassReset(e) {
    e.preventDefault();
    dispatch(
      resetPassword({
        new_password: newPass,
        confirm_password: confirmPass,
        token: token,
      })
    );
  }

  useEffect(() => {
    if (resetPassState.status === "error") {
      setTimeout(() => {
        dispatch(resetPasswordState());
      }, 2500);
    }
  }, [resetPassState.status, dispatch]);

  const isStatusLoading = resetPassState.status === "loading";
  const isBtnDisabled = isStatusLoading || !newPass || !confirmPass;

  const resetPassPage = translations.resetPassPage;

  return (
    <section className={classes.resetPassContainer}>
      <div className={classes.logo}>
        <img src={Logo} alt={resetPassPage.iconAlts.logoAlt} />
      </div>
      <div className={classes.formBg}>
        <img
          className={classes.background}
          src={Background}
          alt={resetPassPage.iconAlts.formBgAlt}
        />
        <div className={classes.flags}>
          <div className={!isEnglishLanguage ? classes.selectedFlag : ""}>
            <img
              src={NorwayFlag}
              alt={resetPassPage.iconAlts.norwayFlagAlt}
              onClick={() => setIsEnglishLanguage(false)}
            />
          </div>
          <div className={isEnglishLanguage ? classes.selectedFlag : ""}>
            <img
              src={EnglishFlag}
              alt={resetPassPage.iconAlts.englishFlagAlt}
              onClick={() => setIsEnglishLanguage(true)}
            />
          </div>
        </div>
        {resetPassState.status === "success" ? (
          <div className={classes.passChanged}>
            <img
              src={whiteLogo}
              alt="White Company logo"
              className={classes.mobileLogo}
            />
            <h2>{resetPassPage.passChanged.heading}</h2>
            <p>{resetPassPage.passChanged.message}</p>
            <Link to={"/login"} className={classes.backToLoginBtn}>
              {resetPassPage.passChanged.backToLoginBtn}
            </Link>
          </div>
        ) : (
          <form className={classes.resetPassForm} onSubmit={onPassReset}>
            <img
              src={whiteLogo}
              alt="White Company logo"
              className={classes.mobileLogo}
            />
            <h2>{resetPassPage.resetPassForm.heading}</h2>
            <p>{resetPassPage.resetPassForm.instruction}</p>
            <div className={classes.inputs}>
              <div className={classes.input}>
                <label htmlFor="newPass">
                  {resetPassPage.resetPassForm.newPasswordLabel}
                </label>
                <input
                  type="password"
                  id="newPass"
                  required
                  value={newPass}
                  onChange={(e) => setNewPass(e.target.value)}
                />
              </div>
              <div className={classes.input}>
                <label htmlFor="confirmNewPass">
                  {resetPassPage.resetPassForm.confirmPasswordLabel}
                </label>
                <input
                  type="password"
                  id="confirmNewPass"
                  required
                  value={confirmPass}
                  onChange={(e) => setConfirmPass(e.target.value)}
                />
              </div>
              <button
                className={classes.createPassBtn}
                type="submit"
                disabled={isBtnDisabled}
              >
                {isStatusLoading ? (
                  <LoadingSpinner black={true} />
                ) : (
                  resetPassPage.resetPassForm.createPasswordButton
                )}
              </button>
            </div>

            {resetPassState.status === "error" && (
              <div className={classes.errorNotification}>
                {resetPassState.error}
              </div>
            )}
          </form>
        )}
      </div>
    </section>
  );
}
