import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./AddCustomerAddress.css";
import CloseIcon from "../../images/deliveries/deliveriesModals/xIcon.svg";
import { createCustomer } from "../../redux/customer/thunks/createCustomerThunk";
import { resetCreateCustomerStatus } from "../../redux/customer/slices/createCustomerSlice";
import { updateCustomer } from "../../redux/customer/thunks/updateCustomerThunk";
import { resetUpdateCustomerStatus } from "../../redux/customer/slices/updateCustomerSlice";
import MapboxComponent from "../mapboxComponent/MapboxComponent";
import { useLocation } from "react-router-dom";
import { useEscapeKeyClose } from "../../helperFunctions/customHooks/useEscapeKeyClose";
import { useClickOutsideClose } from "../../helperFunctions/customHooks/useClickOutsideClose";
import {
  toggleAddNewCustomerAddress,
  setErrorMsg,
  setSuccessMsg,
  onDeleteConfirm,
  onErrorMessage,
  setIsEditMode,
  setIsEditModalShown,
  setIsModalsBgShown,
  setIsDeleteConfirmShown,
  setIsErrorMsg,
  setAddressData,
  setSelectedAddress,
  setIsFetchCurrentAddress,
} from "../../redux/globalFunctions/globalFnSlice";
import translations from "../../translations.json";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import useEnterKey from "../../helperFunctions/customHooks/useEnterKeySubmit";

export function AddCustomerAddress() {
  const {
    isEditMode,
    selectedDataForEdit,
    addressData,
    isFetchCurrentAddress,
  } = useSelector((state) => state.global);
  const createCustomerState = useSelector((state) => state.createCustomer);
  const updateCustomerState = useSelector((state) => state.updateCustomer);

  const hasMounted = useRef(false);
  const location = useLocation();
  const modalRef = useRef(null);
  const dispatch = useDispatch();

  const [suggestions, setSuggestions] = useState([]);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [hasSearched, setHasSearched] = useState(false);
  const [areSuggestionsOpen, setAreSuggestionsOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [countryCode, setCountryCode] = useState("us");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [formAddressData, setFormAddressData] = useState({
    c_country: "",
    c_city: "",
    c_postalCode: "",
  });

  // State for form inputs
  const [formData, setFormData] = useState({
    c_name: "",
    c_email: "",
    c_phonenumber: "",
    c_address: "",
    c_latitude: "",
    c_longitude: "",
  });

  useEffect(() => {
    hasMounted.current = true;
  }, []);

  function handleCloseModal() {
    if (
      location.pathname === "/deliveries" ||
      location.pathname === "/dashboard"
    ) {
      dispatch(toggleAddNewCustomerAddress());
      dispatch(setIsModalsBgShown(true));
      dispatch(setIsEditModalShown(true));
      dispatch(setIsEditMode(false));
      setFormData("");
      dispatch(setAddressData(""));
    } else {
      dispatch(toggleAddNewCustomerAddress());
      setFormData("");
      dispatch(setAddressData(""));
    }
  }

  // Update address and coordinates when map is used
  useEffect(() => {
    if (addressData?.length !== 0) {
      // Filter addresses based on relevance
      const validAddresses = addressData.filter(
        (item) => item?.relevance >= 0.5
      );

      // Only show suggestions if there are valid addresses
      if (validAddresses.length > 0) {
        const suggestedAddresses = validAddresses.map(
          (item) => item.place_name
        );
        setSuggestions(suggestedAddresses);
      } else {
        setSuggestions([]);
      }

      const firstAddress = addressData[0];
      const geometry = firstAddress?.center;

      const latitude = geometry[1];
      const longitude = geometry[0];

      setFormData((prevData) => ({
        ...prevData,
        c_latitude: latitude,
        c_longitude: longitude,
      }));
    } else {
      setSuggestions([]);
    }
  }, [addressData]);

  useEffect(() => {
    if (formData.c_address !== "" && addressData.length !== 0) {
      const context =
        addressData[isEditMode ? 0 : selectedIndex]?.context || [];
      const foundCity =
        context.find((entry) => entry.id.startsWith("place"))?.text || "";
      const foundCountry =
        context.find((entry) => entry.id.startsWith("country"))?.text || "";
      const postalCode =
        context.find((entry) => entry.id.startsWith("postcode"))?.text || "";

      setFormAddressData((prev) => ({
        ...prev,
        c_city: foundCity,
        c_country: foundCountry,
        c_postalCode: postalCode,
      }));
    }
  }, [formData.c_address, addressData]);

  useEffect(() => {
    if (formData.c_address === "") {
      setSuggestions([]);
      setAreSuggestionsOpen(false);
    } else {
      setTimeout(() => {
        setHasSearched(true);
      }, 600);
    }
  }, [formData.c_address]);

  const changeAddress = (newAddress, latitude, longitude) => {
    setFormData((prevData) => ({
      ...prevData,
      c_address: newAddress,
      c_latitude: latitude || prevData.c_latitude,
      c_longitude: longitude || prevData.c_longitude,
    }));
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "c_email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setIsEmailValid(emailRegex.test(value));
      setFormData((prevData) => ({
        ...prevData,
        c_email: value,
      }));
    } else {
      // For other fields, just update the state
      setFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };

  // Handle form submission
  const handleSubmit = () => {
    if (isEditModeFields) {
      return;
    } else if (isEditMode) {
      dispatch(
        updateCustomer({
          c_id: selectedDataForEdit.c_id,
          ...formData,
        })
      );
    } else {
      dispatch(createCustomer(formData));
    }
  };

  // Handler for createCustomer status
  useEffect(() => {
    if (createCustomerState.status === "success") {
      dispatch(setSuccessMsg(createCustomerState.customer.msg));
      dispatch(toggleAddNewCustomerAddress());
      dispatch(setAddressData(""));
      setFormData("");
      dispatch(onDeleteConfirm());
      setTimeout(() => {
        dispatch(setIsDeleteConfirmShown(false));
        dispatch(setIsModalsBgShown(false));
        dispatch(resetCreateCustomerStatus());
        dispatch(setSuccessMsg(""));
      }, 1500);
    } else if (createCustomerState.status === "error") {
      dispatch(setErrorMsg(createCustomerState.error));
      dispatch(toggleAddNewCustomerAddress());
      dispatch(onErrorMessage());
      setTimeout(() => {
        dispatch(setIsDeleteConfirmShown(false));
        dispatch(setIsModalsBgShown(false));
        dispatch(setIsErrorMsg(false));
        dispatch(setErrorMsg(""));
        dispatch(resetCreateCustomerStatus());
      }, 1500);
    }
  }, [createCustomerState.status, dispatch]);

  // Handler for updateCustomer status
  useEffect(() => {
    if (updateCustomerState.status === "success") {
      dispatch(toggleAddNewCustomerAddress());
      dispatch(setSuccessMsg(updateCustomerState.customer.msg));
      dispatch(setAddressData(""));
      setFormData("");
      dispatch(onDeleteConfirm());
      setTimeout(() => {
        dispatch(setIsDeleteConfirmShown(false));
        dispatch(setIsModalsBgShown(false));
        dispatch(resetUpdateCustomerStatus());
        dispatch(setSuccessMsg(""));
      }, 1500);
    } else if (updateCustomerState.status === "error") {
      dispatch(setErrorMsg(updateCustomerState.error));
      dispatch(toggleAddNewCustomerAddress());
      dispatch(onErrorMessage());
      setTimeout(() => {
        dispatch(setIsDeleteConfirmShown(false));
        dispatch(setIsModalsBgShown(false));
        dispatch(setIsErrorMsg(false));
        dispatch(setErrorMsg(""));
        dispatch(resetUpdateCustomerStatus());
      }, 1500);
    }
  }, [updateCustomerState.status, dispatch]);

  // If isEditMode is true then populate the fields with the data from selectedDataForEdit.
  useEffect(() => {
    if (isEditMode && selectedDataForEdit) {
      dispatch(setSelectedAddress(selectedDataForEdit.c_address));
      setFormData({
        c_name: selectedDataForEdit.c_name || "",
        c_email: selectedDataForEdit.c_email || "",
        c_address: selectedDataForEdit.c_address || "",
        c_latitude: selectedDataForEdit.c_latitude || "",
        c_longitude: selectedDataForEdit.c_longitude || "",
      });
      setTimeout(() => {
        setFormData((prev) => ({
          ...prev,
          c_phonenumber: selectedDataForEdit.c_phonenumber || "",
        }));
      }, 1000);
    }
  }, [isEditMode, selectedDataForEdit]);

  const isStatusLoading =
    createCustomerState.status === "loading" ||
    updateCustomerState.status === "loading";

  const isEditModeFields =
    formData.c_name === "" ||
    formData.c_email === "" ||
    formData.c_phonenumber === "" ||
    formData.c_address === "" ||
    formData.c_phonenumber?.length < 7;

  useEffect(() => {
    if (
      location.pathname === "/deliveries" &&
      createCustomerState.status === "success"
    ) {
      setTimeout(() => {
        dispatch(setIsModalsBgShown(true));
        dispatch(setIsEditModalShown(true));
        dispatch(setIsEditMode(false));
      }, 1500);
    }
  }, [createCustomerState.status]);

  const modalText = translations.addCustomerModal;
  const headerText = isEditMode ? modalText.editText : modalText.addText;

  // Prevent space from being added.
  const handleKeyPress = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (suggestions.length !== 0 && hasSearched) {
      setAreSuggestionsOpen(true);
    } else {
      setAreSuggestionsOpen(false);
    }
  }, [suggestions, hasSearched]);

  useEffect(() => {
    if (formData.c_address === "" && suggestions.length !== 0) {
      dispatch(setAddressData([]));
    }
  }, [formData.c_address, suggestions]);

  function onAddressClick(item, index) {
    setSelectedIndex(index);
    dispatch(setSelectedAddress(item));
    setFormData((prev) => ({ ...prev, c_address: item }));
    setAreSuggestionsOpen(false);
  }

  useEscapeKeyClose(handleCloseModal);
  useClickOutsideClose(modalRef, handleCloseModal);
  useEnterKey(handleSubmit);

  // Set country code for the phone input field.
  useEffect(() => {
    if (formData.c_address && addressData.length !== 0) {
      if (addressData[0].properties.short_code) {
        const code = addressData[0]?.properties?.short_code;
        const countryCode = code.split("-")[0];
        setCountryCode(countryCode);
      } else if (addressData[0].context) {
        const countryContext = addressData[0]?.context.find((entry) =>
          entry.id.startsWith("country")
        );
        if (countryContext) {
          const shortCode = countryContext.short_code;
          setCountryCode(shortCode);
        } else {
          setCountryCode("us");
        }
      } else {
        setCountryCode("us");
      }
    } else {
      return;
    }
  }, [formData.c_address, addressData]);

  useEffect(() => {
    if (
      hasSearched &&
      suggestions.length === 0 &&
      formData.c_address !== "" &&
      !isEditMode
    ) {
      setIsErrorShown(true);
    } else {
      setIsErrorShown(false);
    }
  }, [hasSearched, suggestions, formData.c_address]);

  function handleCloseDropdowns(e) {
    if (areSuggestionsOpen) {
      setAreSuggestionsOpen(false);
    } else if (isErrorShown) {
      setIsErrorShown(false);
    }
  }

  function handleFocusAddress() {
    if (formData.c_address !== "" && suggestions.length !== 0) {
      dispatch(setIsFetchCurrentAddress(false));
      setAreSuggestionsOpen(true);
    }
  }

  function handleAddressOnChange(e) {
    dispatch(setIsFetchCurrentAddress(false));
    setFormData((prev) => ({ ...prev, c_address: e.target.value }));
    if (formData.c_address === "") {
      setHasSearched(false);
    } else {
      dispatch(setSelectedAddress(null));
      setHasSearched(true);
    }
  }

  return (
    <div
      className="addCustomerAddress"
      ref={modalRef}
      onClick={() => setIsErrorShown(false)}
    >
      <div className="addCustomerAddressHeading">
        <h6>{headerText}</h6>
        <span className="closeBtn" onClick={handleCloseModal}>
          <img src={CloseIcon} alt={modalText.closeIconAlt} />
        </span>
      </div>
      <form className="addCustomerAddressForm">
        <div className="addCustomerElements">
          <div className="formElement">
            <label htmlFor="c_name">{modalText.form.customer}</label>
            <input
              type="text"
              id="c_name"
              value={formData.c_name || ""}
              onChange={handleInputChange}
              required
            />
          </div>

          <div
            className={`formElement ${
              !isEmailValid && formData.c_email ? "invalid" : ""
            }`}
          >
            <label htmlFor="c_email">{modalText.form.email}</label>
            <input
              type="email"
              id="c_email"
              value={formData.c_email || ""}
              onChange={handleInputChange}
              onKeyDown={handleKeyPress}
              required
            />
          </div>
        </div>
        <div className="addCustomerElements">
          <div className="formElementSmall">
            <label htmlFor="c_country">{modalText.form.country}</label>
            <input
              type="text"
              id="c_country"
              value={formAddressData.c_country || ""}
              readOnly
              disabled
            />
          </div>
          <div className="formElementSmall">
            <label htmlFor="c_city">{modalText.form.city}</label>
            <input
              type="text"
              id="c_city"
              value={formAddressData.c_city || ""}
              readOnly
              disabled
            />
          </div>
          <div className="formElementSmall">
            <label htmlFor="c_postalCode">{modalText.form.postalCode}</label>
            <input
              type="text"
              id="c_postalCode"
              value={formAddressData.c_postalCode || ""}
              readOnly
              disabled
            />
          </div>
        </div>
        <div className="formElementAddress">
          <label htmlFor="c_address">{modalText.form.address}</label>
          <input
            type="text"
            id="c_address"
            value={formData.c_address || ""}
            onFocus={handleFocusAddress}
            onChange={(e) => handleAddressOnChange(e)}
            required
          />
          {areSuggestionsOpen && !isFetchCurrentAddress && (
            <div className="suggestionsContainer">
              <div>
                <button onClick={handleCloseDropdowns}>
                  <img src={CloseIcon} alt={modalText.closeIconAlt} />
                </button>
              </div>
              {suggestions.map((item, index) => (
                <span key={index} onClick={() => onAddressClick(item, index)}>
                  {item}
                </span>
              ))}
            </div>
          )}
          {isErrorShown && (
            <span className="noResultsFound">{modalText.noResultsFound}</span>
          )}
        </div>
        <div className="addCustomerElements">
          <div className="formElement">
            <label htmlFor="c_phonenumber">{modalText.form.phone}</label>
            <PhoneInput
              country={countryCode.toLocaleLowerCase() || "us"}
              value={formData.c_phonenumber || ""}
              onChange={(phone) =>
                setFormData((prev) => ({ ...prev, c_phonenumber: phone }))
              }
              inputProps={{
                name: "phone",
                required: true,
              }}
              containerClass="phone-input-container"
              inputClass="phone-input"
            />
          </div>
        </div>
        <MapboxComponent
          address={formData.c_address || ""}
          city={formData.c_address || ""}
          country={formData.c_address || ""}
          updateAddress={changeAddress}
        />
      </form>
      <button
        className="saveBtn"
        onClick={handleSubmit}
        disabled={isStatusLoading || isEditModeFields || !isEmailValid}
      >
        {isStatusLoading ? <span className="loader"></span> : modalText.saveBtn}
      </button>
    </div>
  );
}
