import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./EditDelivery.css";
import CloseIcon from "../../images/deliveries/deliveriesModals/xIcon.svg";
import CalendarIcon from "../../images/deliveries/deliveriesModals/calendarIcon.svg";
import { createDelivery } from "../../redux/package/thunks/createDeliveryThunk";
import { updatePackage } from "../../redux/package/thunks/updatePackageThunk";
import { fetchCustomers } from "../../redux/customer/thunks/getCustomerThunk";
import { fetchDepartments } from "../../redux/department/thunks/getDepartmentThunk";
import { resetCreatePackageStatus } from "../../redux/package/slices/createDeliverySlice";
import { resetUpdatePackageStatus } from "../../redux/package/slices/updatePackageSlice";
import { cancelPackage } from "../../redux/package/thunks/cancelPackageThunk";
import { resetCancelPackageStatus } from "../../redux/package/slices/cancelPackageSlice";
import SearchableDropdown from "../searchableDropdown/SearchableDropdown";
import { getCurrentDate } from "../../helperFunctions/getCurrentDate";
import { getCurrentTime } from "../../helperFunctions/getCurrentTime";
import { fetchCustomerById } from "../../redux/customer/thunks/getCustomerByIdThunk";
import WhitePlusIcon from "../../images/deliveries/whitePlusIcon.svg";
import { resetFormData, setFormData } from "../../redux/formData/formDataSlice";
import { useEscapeKeyClose } from "../../helperFunctions/customHooks/useEscapeKeyClose";
import { useClickOutsideClose } from "../../helperFunctions/customHooks/useClickOutsideClose";
import {
  onEdit,
  setErrorMsg,
  setSuccessMsg,
  onDeleteConfirm,
  onErrorMessage,
  setIsAddNewCustomerAddress,
  setIsEditModalShown,
  setIsAddLaundry,
  setIsDeleteConfirmShown,
  setIsModalsBgShown,
  setIsErrorMsg,
  setIsMapShown,
  setSelectedDriverInfo,
  setIsDriverChanged,
} from "../../redux/globalFunctions/globalFnSlice";
import { resetCustomersState } from "../../redux/customer/slices/getCustomerSlice";
import translations from "../../translations.json";

export function EditDelivery() {
  // Variables for redux state.
  const {
    isEditMode,
    selectedDataForEdit,
    selectedDriverInfo,
    isDriverChanged,
  } = useSelector((state) => state.global);
  const departments = useSelector((state) => state.departments);
  const createDeliveryState = useSelector((state) => state.createDelivery);
  const updatePackageState = useSelector((state) => state.updatePackage);
  const cancelPackageState = useSelector((state) => state.cancelDelivery);
  const formData = useSelector((state) => state.formData);
  const customersState = useSelector((state) => state.customers);

  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const hasMounted = useRef(false);
  const [dropoffName, setDropoffName] = useState("");
  const [driverName, setDriverName] = useState("");
  const [pickupName, setPickupName] = useState("");

  // Fetch results if customer or departments length is 0.
  useEffect(() => {
    if (
      customersState.length === 0 ||
      departments.department_names.length === 0
    ) {
      dispatch(fetchCustomers({ per_page: 1 }));
      dispatch(fetchDepartments({ per_page: 1 }));
    }
  }, [customersState, departments.department_names]);

  // Refetch data if customer id is changed.
  useEffect(() => {
    if (hasMounted.current) {
      dispatch(fetchCustomerById({ customer_id: id }));
    }
  }, [id]);

  // Combine firstname and lastname in one variable.
  const selectedDriverName =
    selectedDriverInfo &&
    selectedDriverInfo.u_firstname + " " + selectedDriverInfo.u_lastname;

  // Prefill form if in edit mode
  useEffect(() => {
    if (isEditMode && selectedDataForEdit) {
      const customer = customersState.customer_names.find(
        (customer) => customer.c_name === selectedDataForEdit.customer.name
      );
      const prefilledDate = selectedDataForEdit.order_date || "";
      const prefilledTime = selectedDataForEdit.order_time.slice(0, 5) || "";
      const selectedDate = new Date(prefilledDate);
      const today = new Date();
      let minTime = "00:00";

      if (
        selectedDate.getFullYear() === today.getFullYear() &&
        selectedDate.getMonth() === today.getMonth() &&
        selectedDate.getDate() === today.getDate()
      ) {
        minTime = getCurrentTime();
        if (prefilledTime < minTime) {
          dispatch(setFormData({ time: minTime }));
        }
      }

      setDropoffName(selectedDataForEdit.dropoff_address_name);
      dispatch(setFormData({ dropoff: selectedDataForEdit.dropoff_address }));
      setDriverName(selectedDataForEdit.driver.name);
      setPickupName(selectedDataForEdit.pickup_address_name);

      dispatch(
        setFormData({
          addCustomer: customer ? customer.c_id : "",
          driver: selectedDataForEdit.driver.id || "",
          pickup: selectedDataForEdit.pickup_address || "",
          dropoff: selectedDataForEdit.dropoff_address || "",
          date: prefilledDate,
          time: prefilledTime < minTime ? minTime : prefilledTime,
        })
      );

      setMinTime(minTime);
    }
  }, [isEditMode, selectedDataForEdit]);

  // Function for changing the driver name.
  useEffect(() => {
    if (isDriverChanged === true) {
      setDriverName(selectedDriverName);
      dispatch(
        setFormData({ driver: selectedDriverInfo && selectedDriverInfo.dr_uid })
      );
    }
  }, [isDriverChanged]);

  // Handle input changes
  const handleInputChange = (e, id) => {
    const { name, value } = e.target;
    dispatch(setFormData({ [name]: value }));

    if (name === "addCustomer") {
      setId(id);
    }

    // Check if the date has changed and adjust minTime accordingly
    if (name === "date") {
      const selectedDate = new Date(value);
      const today = new Date();

      // Reset the time if the selected date is not today
      if (
        selectedDate.getFullYear() !== today.getFullYear() ||
        selectedDate.getMonth() !== today.getMonth() ||
        selectedDate.getDate() !== today.getDate()
      ) {
        setMinTime("00:00"); // Allow any hour for future dates
      } else {
        setMinTime(getCurrentTime()); // Set minTime to current time for today
      }
    }
  };

  // Function for canceling a package.
  function handleCancelPackage() {
    dispatch(cancelPackage({ package_id: selectedDataForEdit.package_id }));
  }

  // Check if the input fields are empty to disable the submit button
  const requiredFields = [
    "addCustomer",
    "driver",
    "pickup",
    "dropoff",
    "date",
    "time",
  ];
  const areFieldsEmpty = requiredFields.some((field) => !formData[field]);

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const newDeliveryData = {
      driverID: +formData.driver,
      customerID: +formData.addCustomer,
      dropoff_address: formData.dropoff,
      order_date: formData.date,
      order_time: formData.time + ":00",
      pickup_address: formData.pickup,
    };

    if (isEditMode) {
      dispatch(
        updatePackage({
          ...newDeliveryData,
          package_id: selectedDataForEdit.package_id,
          status: 2,
        })
      );
    } else {
      dispatch(createDelivery({ ...newDeliveryData, status: 8 }));
    }
  };

  // If the enter key on the keyboard is pressed submit form.
  useEffect(() => {
    const handleEnterPress = (event) => {
      if (event.key === "Enter") {
        handleSubmit({ preventDefault: () => {} });
      }
    };

    window.addEventListener("keydown", handleEnterPress);

    return () => {
      window.removeEventListener("keydown", handleEnterPress);
    };
  }, [handleSubmit]);

  // Handler for cancelPackageState statuses
  useEffect(() => {
    if (hasMounted.current) {
      if (cancelPackageState.status === "success") {
        dispatch(onEdit());
        dispatch(setSuccessMsg(cancelPackageState.package.msg));
        dispatch(onDeleteConfirm());
        setTimeout(() => {
          dispatch(setIsDeleteConfirmShown(false));
          dispatch(setIsModalsBgShown(false));
        }, 1500);
      } else if (cancelPackageState.status === "error") {
        dispatch(onEdit());
        dispatch(setErrorMsg(cancelPackageState.error));
        dispatch(onErrorMessage());
        setTimeout(() => {
          dispatch(setIsErrorMsg(false));
          dispatch(setErrorMsg(""));
          dispatch(setIsModalsBgShown(false));
        }, 1500);
      }
      setTimeout(() => {
        dispatch(resetCancelPackageStatus());
      }, 500);
    }
  }, [cancelPackageState.status, dispatch]);

  // Handler for updatePackageState statuses
  useEffect(() => {
    if (hasMounted.current) {
      if (updatePackageState.status === "success") {
        handleCloseModal();
        dispatch(resetCustomersState());
        dispatch(setSuccessMsg(updatePackageState.package.msg));
        dispatch(onDeleteConfirm());
        dispatch(resetFormData());
        setTimeout(() => {
          dispatch(setIsDeleteConfirmShown(false));
          dispatch(setIsModalsBgShown(false));
        }, 1500);
      } else if (updatePackageState.status === "error") {
        dispatch(onEdit());
        dispatch(setErrorMsg(updatePackageState.error));
        dispatch(onErrorMessage());
        setTimeout(() => {
          dispatch(setIsErrorMsg(false));
          dispatch(setErrorMsg(""));
          dispatch(setIsModalsBgShown(false));
        }, 1500);
      }
      setTimeout(() => {
        dispatch(resetUpdatePackageStatus());
      }, 500);
    }
  }, [updatePackageState.status, dispatch]);

  // Handler for createDeliveryState statuses
  useEffect(() => {
    if (hasMounted.current) {
      if (createDeliveryState.status === "success") {
        handleCloseModal();
        dispatch(resetCustomersState());
        dispatch(setSuccessMsg(createDeliveryState.delivery.msg));
        dispatch(onDeleteConfirm());
        dispatch(resetFormData());
        dispatch(setSelectedDriverInfo(null));
        setTimeout(() => {
          dispatch(setIsDeleteConfirmShown(false));
          dispatch(setIsModalsBgShown(false));
        }, 1500);
      } else if (createDeliveryState.status === "error") {
        dispatch(onEdit());
        dispatch(setErrorMsg(createDeliveryState.error));
        dispatch(onErrorMessage());
        setTimeout(() => {
          dispatch(setIsDeleteConfirmShown(false));
          dispatch(setIsModalsBgShown(false));
          dispatch(setIsErrorMsg(false));
          dispatch(setErrorMsg(""));
        }, 1500);
      }

      setTimeout(() => {
        dispatch(resetCreatePackageStatus());
      }, 500);
    }
  }, [createDeliveryState.status, dispatch]);

  // Set hasMounted to true after the first render
  useEffect(() => {
    hasMounted.current = true;
  }, []);

  const isBtnDisabled =
    createDeliveryState.status === "loading" ||
    updatePackageState.status === "loading";

  const [minTime, setMinTime] = useState(getCurrentTime());

  // Update dropoff address when a customer is fetched
  useEffect(() => {
    if (customersState.customer && customersState.customer.c_address) {
      setDropoffName(customersState.customer.c_address);
      dispatch(setFormData({ dropoff: customersState.customer.c_lid }));
    }
  }, [customersState.customer]);

  const modalRef = useRef(null);

  function handleCloseModal() {
    if (isEditMode) {
      dispatch(resetFormData());
      dispatch(setSelectedDriverInfo(""));
      setDropoffName("");
      dispatch(resetCustomersState());
    }
    dispatch(onEdit());
    dispatch(setIsDriverChanged(false));
  }

  // Functions for closing the modal when pressing Escape button on keyboard or clicking outside the modal.
  useEscapeKeyClose(handleCloseModal);
  useClickOutsideClose(modalRef, handleCloseModal);

  const modalText = translations.addDeliveryModal;

  const headerText = isEditMode ? modalText.editText : modalText.addText;

  return (
    <div className="addNewPackageModal" ref={modalRef}>
      <div className="topDiv">
        <h6 className="heading">{headerText}</h6>
        <span className="closeBtn" onClick={handleCloseModal}>
          <img src={CloseIcon} alt={modalText.closeIconAlt} />
        </span>
      </div>
      <form className="selects" onSubmit={handleSubmit}>
        <div style={{ display: "flex", gap: "24px" }}>
          <div className="selectContainer">
            <SearchableDropdown
              idForLabel={modalText.form.customer}
              options={customersState.customer_names
                .slice() // Create a shallow copy to avoid mutating the state directly
                .sort((a, b) => b.number_orders - a.number_orders) // Sort by number_orders in descending order
                .map((customer) => ({
                  value: customer.c_id,
                  label: customer.c_name,
                }))}
              placeholder="Select a name"
              onSelect={(value) =>
                handleInputChange(
                  { target: { name: "addCustomer", value } },
                  value
                )
              }
              value={formData.addCustomer}
            />
          </div>

          <div className="selectDriverContainer">
            <div>
              <label>{modalText.form.driver}</label>
              <p>{driverName}</p>
            </div>
            <button
              onClick={() => {
                dispatch(setIsMapShown(true));
                dispatch(setIsEditModalShown(false));
              }}
              className="addDriverBtn"
            >
              {modalText.form.selectDriver}
            </button>
          </div>
        </div>
        <div style={{ display: "flex", gap: "24px" }}>
          <div className="selectContainer">
            <div className="pickupAddressDiv">
              <SearchableDropdown
                idForLabel={modalText.form.pickup}
                options={departments.department_names.map((department) => ({
                  value: department.d_lid, // Use d_lid as value
                  label: department.d_address, // Use d_address as label
                }))}
                placeholder="Select a name"
                onSelect={(value) =>
                  handleInputChange({ target: { name: "pickup", value } })
                }
                value={formData.pickup} // Ensure formData.pickup contains d_lid
              />
              <button
                className="newAddressBtn"
                type="button"
                onClick={() => {
                  dispatch(setIsAddLaundry(true));
                  dispatch(setIsEditModalShown(false));
                }}
              >
                <span>
                  <img src={WhitePlusIcon} alt={modalText.whitePlusIconAlt} />
                </span>
                {modalText.form.newBtn}
              </button>
            </div>
          </div>
          <div className="selectContainer">
            <label htmlFor="dropoff">{modalText.form.dropoff}</label>
            <div className="dropoffAddressDiv">
              <select
                name="dropoff"
                id="dropoff"
                required
                value={formData.dropoff}
                disabled
                onChange={handleInputChange}
              >
                <option value={formData.dropoff}>{dropoffName}</option>
              </select>
              <button
                className="newAddressBtn"
                type="button"
                onClick={() => {
                  dispatch(setIsAddNewCustomerAddress(true));
                  dispatch(setIsEditModalShown(false));
                }}
              >
                <span>
                  <img src={WhitePlusIcon} alt={modalText.whitePlusIconAlt} />
                </span>
                {modalText.form.newBtn}
              </button>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", gap: "24px" }}>
          <div className="inputContainer">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <label htmlFor="date">{modalText.form.date}</label>
              <input
                type="date"
                id="date"
                name="date"
                required
                min={getCurrentDate()}
                value={formData.date}
                onChange={handleInputChange}
              />
            </div>
            <span>
              <img src={CalendarIcon} alt={modalText.calendarIconAlt} />
            </span>
          </div>
          <div className="inputContainer">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <label htmlFor="time">{modalText.form.time}</label>
              <input
                type="time"
                id="time"
                name="time"
                required
                min={minTime}
                value={formData.time}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>

        <div className="newDeliveryBtns">
          {isEditMode && (
            <button
              className="cancelBtn"
              type="button"
              onClick={() => handleCancelPackage()}
              disabled={cancelPackageState.status === "loading"}
            >
              {cancelPackageState.status === "loading" ? (
                <span className="blackLoader"></span>
              ) : (
                modalText.cancel
              )}
            </button>
          )}
          <button
            className="saveBtn"
            type="submit"
            disabled={isBtnDisabled || areFieldsEmpty}
          >
            {createDeliveryState.status === "loading" ||
            updatePackageState.status === "loading" ? (
              <span className="loader"></span>
            ) : (
              modalText.save
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
