import React, { useEffect, useState, useRef, useCallback } from "react";
import Map, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Icon from "../../images/orderInformation/Icon.svg";
import ArrowBlueLeft from "../../images/orderInformation/arrowBlueLeft.svg";
import ArrowBlueRight from "../../images/orderInformation/arrowBlueRight.svg";
import ArrowGrayLeft from "../../images/orderInformation/arrowGrayLeft.svg";
import ArrowGrayRight from "../../images/orderInformation/arrowGrayRight.svg";
import CircleWithDotBlue from "../../images/orderInformation/circleWithDotIconBlue.svg";
import CircleWithDotGray from "../../images/orderInformation/circleWithDotIconGray.svg";
import AddressIconBlue from "../../images/orderInformation/addressIconBlue.svg";
import AddressIconGray from "../../images/orderInformation/addressIconGray.svg";
import AddressIconRed from "../../images/orderInformation/addressIconRed.svg";
import "./OrderInformation.css";
import translations from "../../translations.json";
import { useDispatch, useSelector } from "react-redux";
import { fetchDriverLocation } from "../../redux/driver/thunks/getDriverLocation";

// Cache for geocoding results
const geocodeCache = {};

export function OrderInformation({
  driver_name,
  driver_id,
  order_number,
  date,
  time,
  address,
  shipping_status,
  selectedIndex,
  deliveries = [],
  onClose,
  onPrev,
  onNext,
}) {
  const dispatch = useDispatch();
  const [coordinates, setCoordinates] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isMapInitialized, setIsMapInitialized] = useState(false);
  const mapRef = useRef(null);
  const geocodingTimeoutRef = useRef(null);
  const retryCountRef = useRef(0);
  const MAX_RETRIES = 3;

  const isFirst = selectedIndex === 0;
  const isLast = selectedIndex === deliveries.length - 1;
  const hasOnlyOneDelivery = deliveries.length === 1;

  const driverLocations = useSelector(
    (state) => state.driverLocation.locations?.data || []
  );

  const mapboxToken = "pk.eyJ1IjoiYm96aWRhcmszNSIsImEiOiJjbTFxNTdzMWUwOWZwMmpzYjN4eHF5Zmt5In0.fVeom9WzttFClBuhzNSjKg";

  // Geocode a location and cache the result - memoized with useCallback
  const geocodeLocation = useCallback(async (location) => {
    if (!location) return null;
    
    // Check cache first
    if (geocodeCache[location]) {
      console.log("Using cached coordinates for:", location);
      return geocodeCache[location];
    }

    try {
      console.log("Geocoding location:", location);
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          location
        )}.json?access_token=${mapboxToken}`
      );
      
      if (!response.ok) {
        throw new Error(`Geocoding API error: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.features && data.features.length > 0) {
        const [longitude, latitude] = data.features[0].center;
        const result = { latitude, longitude };
        
        // Store in cache
        geocodeCache[location] = result;
        console.log("Successfully geocoded:", location, result);
        return result;
      } else {
        console.warn("No features found for location:", location);
      }
    } catch (error) {
      console.error("Error geocoding location:", location, error);
    }
    return null;
  }, [mapboxToken]);

  // Fetch driver locations once on mount
  useEffect(() => {
    dispatch(fetchDriverLocation({}));
    
    // Cleanup timeout on unmount
    return () => {
      if (geocodingTimeoutRef.current) {
        clearTimeout(geocodingTimeoutRef.current);
      }
    };
  }, [dispatch]);

  // Function to update map location - extracted for reuse
  const updateMapLocation = useCallback(async () => {
    setIsLoading(true);
    
    // If we've retried too many times, stop
    if (retryCountRef.current >= MAX_RETRIES) {
      console.warn("Max retries reached for geocoding");
      setIsLoading(false);
      return;
    }
    
    let driverCoordinates = null;
    let addressCoordinates = null;

    // Try to get driver coordinates first if we have a driver ID
    if (driver_id && driverLocations.length > 0) {
      const driver = driverLocations.find(driver => String(driver.dr_uid) === String(driver_id));
      if (driver?.dr_current_location) {
        driverCoordinates = await geocodeLocation(driver.dr_current_location);
      }
    }
    
    // If we couldn't get driver coordinates, try address
    if (!driverCoordinates && address) {
      addressCoordinates = await geocodeLocation(address);
    }
    
    // Use whichever coordinates we got
    const newCoordinates = driverCoordinates || addressCoordinates;
    
    if (newCoordinates) {
      console.log("Setting coordinates:", newCoordinates);
      setCoordinates(newCoordinates);
      setIsLoading(false);
      
      // If map is ready, fly to location
      if (mapRef.current && isMapInitialized) {
        try {
          mapRef.current.flyTo({
            center: [newCoordinates.longitude, newCoordinates.latitude],
            zoom: 14,
            duration: 1000
          });
        } catch (error) {
          console.error("Error flying to location:", error);
        }
      }
    } else {
      // If we still don't have coordinates, retry after a delay (only if we have data to try)
      if ((driver_id && driverLocations.length > 0) || address) {
        console.log("Retrying geocoding, attempt:", retryCountRef.current + 1);
        retryCountRef.current += 1;
        
        // Exponential backoff for retries
        const delay = Math.min(1000 * Math.pow(2, retryCountRef.current), 10000);
        geocodingTimeoutRef.current = setTimeout(updateMapLocation, delay);
      } else {
        setIsLoading(false);
      }
    }
  }, [driver_id, address, driverLocations, geocodeLocation, isMapInitialized]);

  // Update the map when selectedIndex changes (but don't remount the map)
  useEffect(() => {
    // Only reset retry count and clear pending timeouts
    retryCountRef.current = 0;
    
    if (geocodingTimeoutRef.current) {
      clearTimeout(geocodingTimeoutRef.current);
    }
    
    // Update location if map is already initialized
    if (isMapInitialized) {
      updateMapLocation();
    }
  }, [selectedIndex, isMapInitialized, updateMapLocation]);

  // Handle initial map load and location update
  const handleMapLoad = useCallback(() => {
    setIsMapInitialized(true);
    
    // If we already have coordinates when the map loads, fly to them
    if (coordinates && mapRef.current) {
      try {
        mapRef.current.flyTo({
          center: [coordinates.longitude, coordinates.latitude],
          zoom: 14,
          duration: 1000
        });
      } catch (error) {
        console.error("Error flying to location on map load:", error);
      }
    } else {
      // First time loading - try to update location
      updateMapLocation();
    }
  }, [coordinates, updateMapLocation]);

  const getStatusStyles = () => {
    let firstStatus = "New";
    let secondStatus = "Picked";
    let thirdStatus = "Delivered";
    let firstIcon = CircleWithDotBlue;
    let secondIcon = CircleWithDotGray;
    let thirdIcon = AddressIconGray;
    let firstLineClass = "gray";
    let secondLineClass = "gray";

    switch (shipping_status) {
      case "Accepted":
        firstStatus = "Accepted";
        firstIcon = CircleWithDotBlue;
        secondIcon = CircleWithDotGray;
        break;

      case "Picked":
        firstStatus = "Accepted";
        secondIcon = CircleWithDotBlue;
        secondLineClass = "blue";
        break;

      case "Delivered":
        firstStatus = "Accepted";
        secondIcon = CircleWithDotBlue;
        thirdIcon = AddressIconBlue;
        firstLineClass = "blue";
        secondLineClass = "blue";
        break;

      case "Cancelled":
        firstStatus = "Accepted";
        secondIcon = CircleWithDotBlue;
        thirdStatus = "Cancelled";
        thirdIcon = AddressIconRed;
        firstLineClass = "blue";
        secondLineClass = "blue";
        break;

      default:
        break;
    }

    return {
      icon1: firstIcon,
      icon2: secondIcon,
      icon3: thirdIcon,
      lineClass1: firstLineClass,
      lineClass2: secondLineClass,
      statusText1: firstStatus,
      statusText2: secondStatus,
      statusText3: thirdStatus,
      showDate1: shipping_status === "New" || shipping_status === "Accepted",
      showDate2: shipping_status === "Picked",
      showDate3: shipping_status === "Delivered" || shipping_status === "Cancelled",
    };
  };

  const statusStyles = getStatusStyles();

  // Initial view state is only set once when the map is first created
  // This prevents the map from re-initializing when state changes
  const initialViewState = {
    latitude: 51.505,  // Default central view
    longitude: -0.09,
    zoom: 3
  };

  return (
    <div className="orderInfoContainer">
      <div className="flex flex-col">
        <div className="firstDiv">
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <div
              style={{
                padding: "12px",
                backgroundColor: "white",
                borderRadius: "10px",
              }}
            >
              <img src={Icon} alt="Icon" />
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
              <h6>
                {translations.orderInformation.driver} {driver_name}
              </h6>
              <p>
                {translations.orderInformation.order} {order_number}
              </p>
            </div>
          </div>
          <div style={{ display: "flex", gap: "4px" }}>
            <button
              onClick={onPrev}
              disabled={isFirst || hasOnlyOneDelivery}
              className="prevBtnDashboard"
            >
              <img
                src={(isFirst || hasOnlyOneDelivery) ? ArrowGrayLeft : ArrowBlueLeft}
                alt={translations.orderInformation.iconAlts.arrowLeftAlt}
              />
            </button>
            <button
              onClick={onNext}
              disabled={isLast || hasOnlyOneDelivery}
              className="nextBtnDashboard"
            >
              <img
                src={(isLast || hasOnlyOneDelivery) ? ArrowGrayRight : ArrowBlueRight}
                alt={translations.orderInformation.iconAlts.arrowRightAlt}
              />
            </button>
          </div>
        </div>

        <div className="lowerCintainer flex flex-col w-full">
          <div className="secondDiv">
            <div style={{ 
              position: "relative", 
              borderRadius: "20px", 
              overflow: "hidden"
            }}>
              <div style={{ width: "100%", height: "300px", position: "relative" }}>
                {isLoading && (
                  <div style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    zIndex: 10
                  }}>
                    <div style={{ 
                      width: "40px",
                      height: "40px",
                      border: "4px solid #f3f3f3",
                      borderTop: "4px solid #1E88E5",
                      borderRadius: "50%",
                      animation: "spin 1s linear infinite"
                    }} />
                  </div>
                )}
                
                {/* Map is only created once and maintained throughout component lifecycle */}
                <Map
                  ref={mapRef}
                  initialViewState={initialViewState}
                  mapStyle="mapbox://styles/mapbox/streets-v11"
                  mapboxAccessToken={mapboxToken}
                  style={{ width: "100%", height: "100%" }}
                  scrollZoom={false}
                  dragRotate={false}
                  attributionControl={false}
                  onLoad={handleMapLoad}
                  reuseMaps
                >
                  {coordinates && (
                    <Marker
                      key={`marker-${address || driver_id}-${selectedIndex}`}
                      latitude={coordinates.latitude}
                      longitude={coordinates.longitude}
                      color="#1E88E5"
                    />
                  )}
                </Map>
              </div>
            </div>
          </div>
          
          <div className="flex flex-col items-center justify-center w-full">
            <div className="orderInformationDiv">
              <div>
                <h6>{translations.orderInformation.headerText}</h6>
              </div>
              <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
                <p>{translations.orderInformation.date}</p>
                <span>{date}</span>
              </div>
              <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
                <p>{translations.orderInformation.time}</p>
                <span>{time}</span>
              </div>
              <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
                <p>{translations.orderInformation.address}</p>
                <span>{address}</span>
              </div>
            </div>
            
            <div className="thirdDiv">
              <div className="shippingDetails">
                {/* First Step */}
                <div className="shipped">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <img
                      src={statusStyles.icon1}
                      alt={translations.orderInformation.iconAlts.circleWithDotAlt}
                    />
                    <div className={`line ${statusStyles.lineClass1}`}></div>
                  </div>
                  <div
                    style={{ display: "flex", flexDirection: "column", gap: "8px" }}
                  >
                    <small>{statusStyles.statusText1}</small>
                    {statusStyles.showDate1 && <p>{date} {time}</p>}
                  </div>
                </div>

                {/* Second Step */}
                <div className="onTheWay">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <img
                      src={statusStyles.icon2}
                      alt={translations.orderInformation.iconAlts.circleWithDotAlt}
                    />
                    <div className={`line ${statusStyles.lineClass2}`}></div>
                  </div>
                  <div
                    style={{ display: "flex", flexDirection: "column", gap: "8px" }}
                  >
                    <small>{statusStyles.statusText2}</small>
                    {statusStyles.showDate2 && <p>{date} {time}</p>}
                  </div>
                </div>

                {/* Third Step */}
                <div className="delivered">
                  <img
                    src={statusStyles.icon3}
                    alt={translations.orderInformation.iconAlts.addressIconAlt}
                  />
                  <div
                    style={{ display: "flex", flexDirection: "column", gap: "8px" }}
                  >
                    <small>{statusStyles.statusText3}</small>
                    {statusStyles.showDate3 && <p>{date} {time}</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fourthDiv">
          <button onClick={onClose}>Close</button>
        </div>
      </div>
      
      {/* Add CSS for loading spinner animation */}
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
}