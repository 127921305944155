import "./AddressesTable.css";
import EditBtn from "../../images/deliveries/deliveriesModals/editIcon.svg";
import DeleteBtn from "../../images/deliveries/deliveriesModals/deleteIcon.svg";
import {
  setIsEditMode,
  toggleAddNewCustomerAddress,
  toggleDeleteCustomerAddressModal,
  toggleAddLaundry,
} from "../../redux/globalFunctions/globalFnSlice";
import { useDispatch, useSelector } from "react-redux";
import translations from "../../translations.json";

export function AddressesTable({ name, address, phone, result }) {
  const { isCustomerActive } = useSelector((state) => state.global);

  const dispatch = useDispatch();

  function handleClick() {
    dispatch(setIsEditMode(true));
    if (isCustomerActive) {
      dispatch(toggleAddNewCustomerAddress(result));
    } else {
      dispatch(toggleAddLaundry(result));
    }
  }

  return (
    <div className="address">
      <div>{name}</div>
      <div>{address}</div>
      <div className="phoneDiv">{phone}</div>
      <div className="iconDiv">
        <img
          src={EditBtn}
          alt={translations.addresses.table.iconAlts.editIconAlt}
          onClick={handleClick}
        />
      </div>
      <div className="iconDiv">
        <img
          src={DeleteBtn}
          alt={translations.addresses.table.iconAlts.deleteIconAlt}
          onClick={() => dispatch(toggleDeleteCustomerAddressModal(result))}
        />
      </div>
    </div>
  );
}
