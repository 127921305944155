import "./Pagination.css";

export function Pagination({ currentPage, setCurrentPage, totalPages, filteredPages, isFiltered }) {
  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalDisplayedPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const totalDisplayedPages = isFiltered ? filteredPages : totalPages;

  const renderPageButton = (page) => (
    <button
      key={page}
      className={currentPage === page ? "activePage" : "firstPage"}
      onClick={() => handlePageClick(page)}
    >
      {page}
    </button>
  );

  const renderPages = () => {
    if (totalDisplayedPages <= 4) {
      // Function for displaying all pages if 4 or fewer.
      return Array.from({ length: totalDisplayedPages }, (_, i) => renderPageButton(i + 1));
    } else {
      // Function for displaying the first 4 pages and the last page with three dots before the last page.
      return (
        <>
          {Array.from({ length: 4 }, (_, i) => renderPageButton(i + 1))}
          {currentPage > 4 && currentPage != totalDisplayedPages && (
            <button className="activePage">{currentPage}</button>
          )}
          {currentPage < totalDisplayedPages - 1 && <p>...</p>}
          {currentPage === totalDisplayedPages && totalDisplayedPages > 6 && <p>...</p>}
          {currentPage === totalDisplayedPages && renderPageButton(currentPage - 1)}
          {renderPageButton(totalDisplayedPages)}
        </>
      );
    }
  };

  return (
    <div className="pagination">
      <button onClick={handlePrevPage} disabled={currentPage === 1} className="prevBtn">
        {"<"}
      </button>
      {renderPages()}
      <button onClick={handleNextPage} disabled={currentPage === totalDisplayedPages} className="nextBtn">
        {">"}
      </button>
    </div>
  );
}
