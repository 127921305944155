// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DownloadApp_container__M0i6x {
  background-color: white;
  padding: 32px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  z-index: 100;
}

.DownloadApp_apps__R9p\\+0 {
  display: flex;
  gap: 1rem;
}

.DownloadApp_container__M0i6x h3 {
  color: black;
  margin: 0;
  font-size: 1.5rem;
  line-height: 2rem;
}

.DownloadApp_container__M0i6x header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DownloadApp_container__M0i6x header span {
  cursor: pointer;
}

.DownloadApp_android__N0yL4,
.DownloadApp_ios__PhUwX {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.DownloadApp_android__N0yL4 img {
  width: 200px;
  height: 200px;
  cursor: pointer;
}

.DownloadApp_ios__PhUwX img {
  width: 300px;
  cursor: pointer;
}

.DownloadApp_apps__R9p\\+0 span {
  font-size: 1.2rem;
  font-weight: 500;
}

@media (max-width: 450px) {
  .DownloadApp_container__M0i6x {
    width: 60%;
    gap: 1rem;
  }

  .DownloadApp_container__M0i6x h3 {
    font-size: 1rem;
  }

  .DownloadApp_android__N0yL4,
  .DownloadApp_ios__PhUwX {
    flex: 1 1;
  }

  .DownloadApp_android__N0yL4 img {
    width: 100px;
    height: 100px;
  }

  .DownloadApp_ios__PhUwX img {
    width: 100px;
    margin-top: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/downloadApp/DownloadApp.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,YAAY;AACd;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,SAAS;EACT,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;;EAEE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE;IACE,UAAU;IACV,SAAS;EACX;;EAEA;IACE,eAAe;EACjB;;EAEA;;IAEE,SAAO;EACT;;EAEA;IACE,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,YAAY;IACZ,gBAAgB;EAClB;AACF","sourcesContent":[".container {\r\n  background-color: white;\r\n  padding: 32px;\r\n  border-radius: 8px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 32px;\r\n  z-index: 100;\r\n}\r\n\r\n.apps {\r\n  display: flex;\r\n  gap: 1rem;\r\n}\r\n\r\n.container h3 {\r\n  color: black;\r\n  margin: 0;\r\n  font-size: 1.5rem;\r\n  line-height: 2rem;\r\n}\r\n\r\n.container header {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n}\r\n\r\n.container header span {\r\n  cursor: pointer;\r\n}\r\n\r\n.android,\r\n.ios {\r\n  display: flex;\r\n  flex-direction: column;\r\n  text-align: center;\r\n}\r\n\r\n.android img {\r\n  width: 200px;\r\n  height: 200px;\r\n  cursor: pointer;\r\n}\r\n\r\n.ios img {\r\n  width: 300px;\r\n  cursor: pointer;\r\n}\r\n\r\n.apps span {\r\n  font-size: 1.2rem;\r\n  font-weight: 500;\r\n}\r\n\r\n@media (max-width: 450px) {\r\n  .container {\r\n    width: 60%;\r\n    gap: 1rem;\r\n  }\r\n\r\n  .container h3 {\r\n    font-size: 1rem;\r\n  }\r\n\r\n  .android,\r\n  .ios {\r\n    flex: 1;\r\n  }\r\n\r\n  .android img {\r\n    width: 100px;\r\n    height: 100px;\r\n  }\r\n\r\n  .ios img {\r\n    width: 100px;\r\n    margin-top: 10px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `DownloadApp_container__M0i6x`,
	"apps": `DownloadApp_apps__R9p+0`,
	"android": `DownloadApp_android__N0yL4`,
	"ios": `DownloadApp_ios__PhUwX`
};
export default ___CSS_LOADER_EXPORT___;
