import React, { useState, useEffect, useRef } from "react";
import "./SearchableDropdown.css";
import translations from "../../translations.json";

const SearchableDropdown = ({
  options,
  placeholder,
  onSelect,
  value,
  idForLabel,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);

  const dropdownRef = useRef();

  useEffect(() => {
    setFilteredOptions(
      options.filter((option) =>
        option.label
          ?.toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, options]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (option) => {
    onSelect(option.value);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setSearchTerm("");
    }
  };

  return (
    <div className="dropdown" ref={dropdownRef}>
      <label htmlFor={idForLabel}>{idForLabel}</label>
      <div className="dropdown-header" onClick={toggleDropdown}>
        {value
          ? options.find((option) => option.value === value)?.label ||
            placeholder
          : placeholder}
      </div>
      {isOpen && (
        <div className="dropdown-body">
          <input
            id={idForLabel}
            type="text"
            placeholder={translations.searchableDropdown.searchPlaceholder}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            autoFocus
          />
          <ul className="dropdown-list">
            {filteredOptions.map((option) => (
              <li
                key={option.value}
                onClick={() => handleSelect(option)}
                className="dropdown-item"
              >
                {option.label}
              </li>
            ))}
            {filteredOptions.length === 0 && (
              <li className="dropdown-item">
                {translations.searchableDropdown.noNameFound}
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;
