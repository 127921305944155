import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./AddNewDriver.css";
import CloseIcon from "../../images/deliveries/deliveriesModals/xIcon.svg";
import { inviteDriver } from "../../redux/driver/thunks/inviteDriverThunk";
import { resetDriverInviteStatus } from "../../redux/driver/slices/inviteDriverSlice";
import { updateDriver } from "../../redux/driver/thunks/updateDriverThunk";
import { resetUpdateDriverStatus } from "../../redux/driver/slices/updateDriverSlice";
import { fetchVehicles } from "../../redux/vehicle/thunks/getVehicleThunk";
import { useEscapeKeyClose } from "../../helperFunctions/customHooks/useEscapeKeyClose";
import { useClickOutsideClose } from "../../helperFunctions/customHooks/useClickOutsideClose";
import {
  toggleAddNewDriverModal,
  setErrorMsg,
  setSuccessMsg,
  onDeleteConfirm,
  onErrorMessage,
  setIsErrorMsg,
  setIsModalsBgShown,
  setIsDeleteConfirmShown,
  setIsEditMode,
  setSelectedDataForEdit,
} from "../../redux/globalFunctions/globalFnSlice";
import translations from "../../translations.json";

const FormInput = ({
  id,
  label,
  value,
  onChange,
  type,
  onKeyDown,
  onBlur,
  isValid,
}) => (
  <div
    className={`formElement ${
      !isValid && (type === "email" || type === "tel") && value ? "invalid" : ""
    }`}
  >
    <label htmlFor={id}>{label}</label>
    <input
      type={type}
      id={id}
      required
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
    />
  </div>
);

const FormDropdown = ({
  id,
  label,
  value,
  onChange,
  options,
  showDefaultOption,
}) => (
  <div className="formElement">
    <label htmlFor={id}>{label}</label>
    <select id={id} value={value} onChange={onChange}>
      {showDefaultOption && (
        <option value="">
          {translations.addDriverModal.form.vehicleSelect}
        </option>
      )}{" "}
      {/* Conditionally render */}
      {options.vehicles &&
        options.vehicles.length > 0 &&
        options.vehicles.map((option) => (
          <option key={option.v_id} value={option.v_id}>
            {option.v_name}
          </option>
        ))}
    </select>
  </div>
);

export function AddNewDriver() {
  const { isEditMode, selectedDataForEdit } = useSelector(
    (state) => state.global
  );

  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const modalRef = useRef(null);
  const hasMounted = useRef(false);

  const updateDriverState = useSelector((state) => state.updateDriver);
  const inviteDriverState = useSelector((state) => state.inviteDriver);
  const { vehicles } = useSelector((state) => state.vehicles);

  useEffect(() => {
    dispatch(fetchVehicles());
  }, [dispatch]);

  useEffect(() => {
    hasMounted.current = true;
  }, []);

  // Populate the fields if is in edit mode, if its not then fields are empty.
  useEffect(() => {
    if (isEditMode && selectedDataForEdit) {
      setFirstName(selectedDataForEdit.u_firstname || "");
      setLastName(selectedDataForEdit.u_lastname || "");
      setPhone(selectedDataForEdit.u_phonenumber || "");
      setSelectedVehicle(selectedDataForEdit.u_vehicle_id || "");
    } else {
      setFirstName("");
      setLastName("");
      setPhone("");
      setSelectedVehicle("");
    }
  }, [isEditMode, selectedDataForEdit]);

  // Function for showing notifications according to the update driver state status.
  useEffect(() => {
    if (hasMounted.current) {
      if (updateDriverState.status === "success") {
        dispatch(toggleAddNewDriverModal());
        dispatch(setSuccessMsg(updateDriverState.driver.msg));
        dispatch(onDeleteConfirm());
        dispatch(setIsEditMode(false));
        setTimeout(() => {
          dispatch(setIsDeleteConfirmShown(false));
          dispatch(setIsModalsBgShown(false));
          dispatch(resetUpdateDriverStatus());
        }, 1500);
      } else if (updateDriverState.status === "error") {
        dispatch(toggleAddNewDriverModal());
        dispatch(setErrorMsg(updateDriverState.error));
        dispatch(onErrorMessage());
        dispatch(setIsEditMode(false));
        setTimeout(() => {
          dispatch(setIsDeleteConfirmShown(false));
          dispatch(setIsModalsBgShown(false));
          dispatch(setIsErrorMsg(false));
          dispatch(setErrorMsg(""));
          dispatch(resetUpdateDriverStatus());
        }, 1500);
      }
    }
  }, [updateDriverState.status, dispatch]);

  // Function for showing notifications according to the invite driver state status.
  useEffect(() => {
    if (hasMounted.current) {
      if (inviteDriverState.status === "success") {
        dispatch(toggleAddNewDriverModal());
        dispatch(setSuccessMsg(inviteDriverState.driver.msg));
        dispatch(onDeleteConfirm());
        dispatch(setIsEditMode(false));
        setTimeout(() => {
          dispatch(setIsDeleteConfirmShown(false));
          dispatch(setIsModalsBgShown(false));
          dispatch(resetDriverInviteStatus());
        }, 1500);
      } else if (inviteDriverState.status === "error") {
        dispatch(toggleAddNewDriverModal());
        dispatch(setIsErrorMsg(true));
        dispatch(setErrorMsg(inviteDriverState.error));
        dispatch(onErrorMessage());
        setTimeout(() => {
          dispatch(setIsErrorMsg(false));
          dispatch(setErrorMsg(""));
          dispatch(setIsModalsBgShown(false));
          dispatch(resetDriverInviteStatus());
        }, 1500);
      }
    }
  }, [inviteDriverState.status, dispatch]);

  const handleClick = () => {
    const driverData = {
      phonenumber: phone,
      email: email,
    };

    if (
      (!isEditMode && email === "") ||
      phone === "" ||
      (!isEditMode && !isEmailValid) ||
      !isPhoneValid
    ) {
      return;
    } else if (isEditMode) {
      dispatch(
        updateDriver({
          u_id: selectedDataForEdit.u_id,
          u_firstname: firstName,
          u_lastname: lastName,
          u_vehicle_id: selectedVehicle,
          u_phonenumber: phone,
        })
      );
    } else {
      dispatch(inviteDriver(driverData));
    }
  };

  // If the enter key on the keyboard is pressed submit form.
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        handleClick();
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleClick]);

  const handlePhoneChange = (value) => {
    // Allow only digits and a single '+' at the beginning
    const formattedValue = value.replace(/[^0-9+]/g, "");

    // Make sure '+' appears only at the start and validate input length (7-15 digits)
    if (formattedValue === "+" || /^\+?\d*$/.test(formattedValue)) {
      setPhone(formattedValue);

      // Check if the formatted value meets the length and validation criteria
      const phoneRegex = /^\+?[0-9]{7,15}$/;
      setIsPhoneValid(phoneRegex.test(formattedValue));
    }
  };

  const handleEmailChange = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    setEmail(value);
    setIsEmailValid(emailRegex.test(value));
  };

  const isStatusLoading =
    inviteDriverState.status === "loading" ||
    updateDriverState.status === "loading";

  function handleCloseModal() {
    dispatch(toggleAddNewDriverModal());
    dispatch(setSelectedDataForEdit(""));
  }

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  // Functions for closing the modal when pressing Escape button on keyboard or clicking outside the modal.
  useEscapeKeyClose(handleCloseModal);
  useClickOutsideClose(modalRef, handleCloseModal);

  const modalText = translations.addDriverModal;

  const headerText = isEditMode ? modalText.editText : modalText.addText;

  // Prevent space from being added.
  const handleKeyPress = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  return (
    <div className="addDriverModal" ref={modalRef}>
      <div className="addDriverHeading">
        <h6>{headerText}</h6>
        <span className="closeAddDriverBtn" onClick={handleCloseModal}>
          <img src={CloseIcon} alt={modalText.closeIconAlt} />
        </span>
      </div>
      <form className="addDriverForm">
        {isEditMode && (
          <>
            <FormInput
              id="firstname"
              type={"text"}
              label={modalText.form.firstname}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <FormInput
              id="lastname"
              type={"text"}
              label={modalText.form.lastname}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <FormDropdown
              id="vehicle"
              label={modalText.form.vehicle}
              value={selectedVehicle}
              onChange={(e) => setSelectedVehicle(e.target.value)}
              options={vehicles}
              showDefaultOption={!selectedVehicle} // Show default if no vehicle is selected
            />
          </>
        )}
        <FormInput
          id="phone"
          label={modalText.form.phone}
          value={phone}
          onChange={(e) => handlePhoneChange(e.target.value)}
          isValid={isPhoneValid}
          type="tel"
        />
        {!isEditMode && (
          <FormInput
            id="email"
            type={"email"}
            label={modalText.form.email}
            value={email}
            onChange={(e) => handleEmailChange(e.target.value)}
            onKeyDown={handleKeyPress}
            isValid={isEmailValid}
            required
          />
        )}

        <button
          className="saveDriverBtn"
          onClick={handleClick}
          type="button"
          disabled={
            isStatusLoading ||
            (!isEditMode && email === "") ||
            phone === "" ||
            (!isEditMode && !isEmailValid) ||
            !isPhoneValid
          }
        >
          {isStatusLoading ? (
            <span className="loader"></span>
          ) : (
            modalText.saveBtn
          )}
        </button>
      </form>
    </div>
  );
}
