// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.threeDotsModal {
  padding: 12px 16px 12px 16px;
  background-color: #f5fafa;
  border-radius: 10px;
  box-shadow: 0px 2px 4px 0px #00000014;
  box-shadow: 0px 6px 20px 0px #00000014;
  position: relative;
  z-index: 2;
}

.threeDotsModal img {
  cursor: pointer;
}

.btns {
  display: flex;
  gap: 8px;
}

.squareDiv {
  background-color: #f5fafa;
  height: 30px;
  width: 30px;
  position: absolute;
  bottom: -5px;
  left: 30px;
  transform: rotate(45deg);
  z-index: -1;
}

.squareDivHistory {
  background-color: #f5fafa;
  height: 30px;
  width: 30px;
  position: absolute;
  bottom: -5px;
  left: 50px;
  transform: rotate(45deg);
  z-index: -1;
}

.modals {
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: #00000070;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/ThreeDotsModal.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,yBAAyB;EACzB,mBAAmB;EACnB,qCAAqC;EACrC,sCAAsC;EACtC,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,QAAQ;AACV;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,UAAU;EACV,wBAAwB;EACxB,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,UAAU;EACV,wBAAwB;EACxB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,aAAa;EACb,WAAW;EACX,2BAA2B;EAC3B,MAAM;EACN,OAAO;EACP,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".threeDotsModal {\n  padding: 12px 16px 12px 16px;\n  background-color: #f5fafa;\n  border-radius: 10px;\n  box-shadow: 0px 2px 4px 0px #00000014;\n  box-shadow: 0px 6px 20px 0px #00000014;\n  position: relative;\n  z-index: 2;\n}\n\n.threeDotsModal img {\n  cursor: pointer;\n}\n\n.btns {\n  display: flex;\n  gap: 8px;\n}\n\n.squareDiv {\n  background-color: #f5fafa;\n  height: 30px;\n  width: 30px;\n  position: absolute;\n  bottom: -5px;\n  left: 30px;\n  transform: rotate(45deg);\n  z-index: -1;\n}\n\n.squareDivHistory {\n  background-color: #f5fafa;\n  height: 30px;\n  width: 30px;\n  position: absolute;\n  bottom: -5px;\n  left: 50px;\n  transform: rotate(45deg);\n  z-index: -1;\n}\n\n.modals {\n  position: fixed;\n  height: 100vh;\n  width: 100%;\n  background-color: #00000070;\n  top: 0;\n  left: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
