import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../../constants";

export const createDelivery = createAsyncThunk("delivery/createDelivery", async (data, thunkAPI) => {
  const state = thunkAPI.getState();
  const token = state.user.token;

  const deliveryData = {
    driverID: data.driverID,
    status: data.status,
    customerID: data.customerID,
    dropoff_address: data.dropoff_address,
    order_date: data.order_date,
    order_time: data.order_time,
    pickup_address: data.pickup_address,
  };

  console.log(deliveryData);

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(deliveryData),
  };

  try {
    const response = await fetch(`${apiUrl}/create_delivery`, requestOptions);

    if (!response.ok) {
      let errorMessage = "Network response was not ok";
      try {
        const error = await response.json();
        errorMessage = error.msg || errorMessage;
      } catch (e) {
        errorMessage = await response.text();
      }
      throw new Error(errorMessage || "Unknown error occurred");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Delivery creation failed:", error);
    return thunkAPI.rejectWithValue(error.message);
  }
});
