// userThunk.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../../constants";

export const fetchAvatar = createAsyncThunk(
  "user/fetchAvatar",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.user.token;
    const avatar = state.user.avatar;

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await fetch(`${apiUrl}/avatar?avatar=${avatar}`, requestOptions);

      if (!response.ok) {
        let errorMessage = "Network response was not ok";
        try {
          const error = await response.json();
          errorMessage = error.msg || errorMessage;
        } catch (e) {
          errorMessage = await response.text();
        }
        throw new Error(errorMessage || "Unknown error occurred");
      }

      return await response.blob();  // Fetch the avatar as a blob
    } catch (error) {
      console.error("Fetching avatar failed:", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const login = createAsyncThunk(
  "user/login",
  async (data, thunkAPI) => {
    const formData = new FormData();
    formData.append("email", data.email);
    formData.append("password", data.password);

    const requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow",
    };

    try {
      const response = await fetch(`${apiUrl}/login_panel`, requestOptions);

      if (!response.ok) {
        let errorMessage = "Network response was not ok";
        try {
          const error = await response.json();
          errorMessage = error.msg || errorMessage;
        } catch (e) {
          errorMessage = await response.text();
        }
        throw new Error(errorMessage || "Unknown error occurred");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Login failed:", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
