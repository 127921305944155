import "./DriversTable.css";
import LocationIcon from "../../images/drivers/locationIcon.svg";
import RouteIcon from "../../images/drivers/routeIcon.svg";
import { useDispatch } from "react-redux";
import { fetchCurrentDeliveries } from "../../redux/package/thunks/getCurrentDeliveriesThunk";
import { resetCurrentDeliveriesState } from "../../redux/package/slices/getCurrentDeliveriesSlice";
import { resetDeliveryState } from "../../redux/package/slices/getDeliverySlice";
import {
  toggleDriverLocationModal,
  toggleDriverInfoModal,
  setIsWithHistory,
  setIsDriverRoutes,
  setShowThreeDotsModal,
  setSelectedDriverId,
  toggleThreeDotsModal,
  setIsEditMode,
} from "../../redux/globalFunctions/globalFnSlice";
import { useRef } from "react";
import translations from "../../translations.json";

export function DriversTable({
  u_id,
  u_firstname,
  u_lastname,
  u_phonenumber,
  u_vehicle_id,
  data,
  u_email,
  width,
}) {
  const buttonRef = useRef(null);
  const dispatch = useDispatch();

  // Combine first and last name
  const fullName = `${u_firstname} ${u_lastname}`;

  const handleRouteClick = () => {
    dispatch(fetchCurrentDeliveries({ driver_id: u_id }));
    dispatch(setSelectedDriverId(u_id));
    dispatch(toggleDriverInfoModal());
    dispatch(setIsDriverRoutes(true));
    dispatch(setShowThreeDotsModal(false));
  };

  function handleLocationClick() {
    dispatch(resetCurrentDeliveriesState());
    dispatch(resetDeliveryState());
    dispatch(fetchCurrentDeliveries({ driver_id: u_id }));
    dispatch(setSelectedDriverId(u_id));
    dispatch(toggleDriverLocationModal());
    dispatch(setShowThreeDotsModal(false));
    dispatch(setIsDriverRoutes(false));
  }

  const handleClick = () => {
    const scrollY = window.scrollY || window.pageYOffset; // Get the current scroll position
    const buttonPosition = buttonRef.current.getBoundingClientRect();
    const payload = {
      x: buttonPosition.left + window.scrollX - 52, // Get the absolute X position
      y: buttonPosition.top + scrollY - 50, // Get the absolute Y position
      result: data,
    };
    dispatch(toggleThreeDotsModal(payload));
    dispatch(setIsEditMode(true));
    dispatch(setIsWithHistory(true));
    dispatch(setSelectedDriverId(u_id));
  };

  return (
    <div className="driver" style={{ width: width ? width : "" }}>
      <div>{u_id}</div>
      <div style={{ padding: "0 10px 0 0" }}>{fullName}</div>
      <div>{u_email}</div>
      <div>{u_phonenumber}</div>
      <div>{u_vehicle_id}</div>
      <div>
        <img
          src={LocationIcon}
          alt={translations.drivers.iconAlts.locationIconAlt}
          onClick={handleLocationClick}
        />
      </div>
      <div>
        <img
          src={RouteIcon}
          alt={translations.drivers.iconAlts.routeIconAlt}
          onClick={handleRouteClick}
        />
      </div>
      <div>
        <button ref={buttonRef} onClick={handleClick}>
          ...
        </button>
      </div>
    </div>
  );
}
