import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../../constants";

export const validateToken = createAsyncThunk(
  "token/validate",
  async (token, thunkAPI) => {
    try {
      const response = await fetch(`${apiUrl}/validate_token?token=${token}`);
      if (!response.ok) {
        let errorMessage = "Network response was not ok";
        try {
          const error = await response.json();
          errorMessage = error.msg || errorMessage;
        } catch (e) {
          errorMessage = await response.text();
        }
        throw new Error(errorMessage || "Unknown error occurred");
      }
      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Token validation failed:", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
