import React, { useEffect, useRef, useState } from "react";
import Map, { Marker, Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useDispatch, useSelector } from "react-redux";
import classes from "./AllDriversMap.module.css";
import CloseIcon from "../../../images/deliveries/deliveriesModals/xIcon.svg";
import { useClickOutsideClose } from "../../../helperFunctions/customHooks/useClickOutsideClose";
import { useEscapeKeyClose } from "../../../helperFunctions/customHooks/useEscapeKeyClose";
import {
  setIsDriverChanged,
  setIsEditModalShown,
  setIsMapShown,
  setIsModalsBgShown,
  setSelectedDriverInfo,
} from "../../../redux/globalFunctions/globalFnSlice";
import { useLocation } from "react-router-dom";
import translations from "../../../translations.json";
import { fetchDriverLocation } from "../../../redux/driver/thunks/getDriverLocation";

export function AllDriversMap() {
  const [coordinatesList, setCoordinatesList] = useState([]);
  const [viewState, setViewState] = useState({
    zoom: 7.4,
    latitude: 51.505, // Default latitude (London)
    longitude: -0.09, // Default longitude (London)
  });

  const dispatch = useDispatch();
  const { isMapShow } = useSelector((state) => state.global);
  
  // Ensure driverLocations is always an array
  const driverLocations = useSelector(
    (state) => state.driverLocation.locations?.data || []
  );

  const modalRef = useRef();
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchDriverLocation({}));
    console.log("Fetching driver locations...");
  }, [isMapShow]);

  useEffect(() => {
    console.log("Driver locations updated:", driverLocations);
  }, [driverLocations]);

  useEffect(() => {
    const fetchCoordinates = async () => {
      if (!driverLocations.length) return; // Prevents running if empty

      try {
        const coordsPromises = driverLocations.map(async (item) => {
          if (!item.dr_current_location) return null; // Skip drivers without locations

          const response = await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
              item.dr_current_location
            )}.json?access_token=${mapboxToken}`
          );
          const data = await response.json();
          
          if (!data.features || data.features.length === 0) return null; // Skip invalid locations
          
          const [lng, lat] = data.features[0].center;
          return {
            lat,
            lng,
            driverName: `${item.u_firstname} ${item.u_lastname}`,
            item,
          };
        });

        const coords = await Promise.all(coordsPromises);
        const validCoords = coords.filter(Boolean); // Remove null values

        setCoordinatesList(validCoords);
        if (validCoords.length > 0) {
          setViewState((prevState) => ({
            ...prevState,
            latitude: validCoords[0].lat + 0.5,
            longitude: validCoords[0].lng,
          }));
        }
      } catch (error) {
        console.error("Error fetching driver coordinates:", error);
      }
    };

    fetchCoordinates();
  }, [driverLocations]);

  function handleCloseModal() {
    if (["/deliveries", "/dashboard"].includes(location.pathname)) {
      dispatch(setIsMapShown(false));
      dispatch(setIsModalsBgShown(true));
      dispatch(setIsEditModalShown(true));
    } else {
      dispatch(setIsMapShown(false));
      dispatch(setIsModalsBgShown(false));
    }
  }

  const mapboxToken =
    "pk.eyJ1IjoiYm96aWRhcmszNSIsImEiOiJjbTFxNTdzMWUwOWZwMmpzYjN4eHF5Zmt5In0.fVeom9WzttFClBuhzNSjKg";

  useEscapeKeyClose(handleCloseModal);
  useClickOutsideClose(modalRef, handleCloseModal);

  const isDriverUrlRoute = location.pathname === "/drivers";

  const handleDriverSelect = (driver) => {
    dispatch(setSelectedDriverInfo(driver));
    dispatch(setIsDriverChanged(true));
    handleCloseModal();
  };

  return (
    <div className={classes.container} ref={modalRef}>
      <div className={classes.header}>
        <h3>{translations.allDriversLocationsMapModal.headerText}</h3>
        <span onClick={handleCloseModal}>
          <img
            src={CloseIcon}
            alt={translations.allDriversLocationsMapModal.closeIconAlt}
          />
        </span>
      </div>

      {!isDriverUrlRoute && (
        <div className={classes.driverDropdown}>
          <p>{translations.allDriversLocationsMapModal.selectDriver}</p>
          <select
            defaultValue=""
            onChange={(e) => {
              const selectedDriver = driverLocations.find(
                (driver) => String(driver.dr_uid) === e.target.value
              );
              if (selectedDriver) {
                handleDriverSelect(selectedDriver);
              }
            }}
          >
            <option value="" disabled>
              {translations.allDriversLocationsMapModal.chooseDriver}
            </option>
            {driverLocations.length > 0 ? (
              driverLocations.map((driver) => (
                <option
                  key={driver.dr_uid}
                  value={driver.dr_uid}
                  className={
                    driver.dr_current_location
                      ? classes.inactiveDriver
                      : classes.activeDriver
                  }
                >
                  {driver.u_firstname + " " + driver.u_lastname}
                </option>
              ))
            ) : (
              <option disabled>Loading drivers...</option>
            )}
          </select>
        </div>
      )}

      <div className={classes.mapContainer}>
        <Map
          {...viewState}
          onMove={(evt) => setViewState(evt.viewState)}
          mapStyle="mapbox://styles/mapbox/streets-v11"
          mapboxAccessToken={mapboxToken}
          className={classes.map}
        >
          {coordinatesList.length > 0 ? (
            coordinatesList.map((coordinates, index) => (
              <div key={index}>
                <Marker
                  latitude={coordinates.lat}
                  longitude={coordinates.lng}
                  onClick={
                    !isDriverUrlRoute
                      ? () => handleDriverSelect(coordinates.item)
                      : undefined
                  }
                />
                <Popup
                  longitude={coordinates.lng}
                  latitude={coordinates.lat}
                  closeButton={true}
                  closeOnClick={false}
                  offset={35}
                >
                  <p>{coordinates.driverName}</p>
                </Popup>
              </div>
            ))
          ) : (
            <p>Loading driver locations...</p>
          )}
        </Map>
      </div>
    </div>
  );
}
