import { createSlice } from "@reduxjs/toolkit";
import { deleteCompany } from "../thunks/deleteCompanyThunk";

const deleteCompanySlice = createSlice({
  name: "companyDelete",
  initialState: {
    status: "idle", // 'idle' | 'loading' | 'success' | 'error'
    error: null,
    message: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteCompany.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteCompany.fulfilled, (state, action) => {
        state.message = action.payload.msg;
        state.status = "success";
        state.error = null;
      })
      .addCase(deleteCompany.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      });
  },
});

export default deleteCompanySlice.reducer;
