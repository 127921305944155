import "./SuccessNotification.css";
import CheckIcon from "../../images/deliveries/deliveriesModals/checkIcon.svg";
import translations from "../../translations.json";

export function SuccessNotificationModal({ msg }) {
  const modalText = translations.deleteConfirmationMessage;

  return (
    <div className="successNotification">
      <img src={CheckIcon} alt={modalText.checkIconAlt} />
      <div>
        <div className="headDiv">{modalText.headerText}</div>
        <div style={{ color: "#676c7e", lineHeight: "24px" }}>{msg}</div>
      </div>
    </div>
  );
}
