import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDrivers } from "../../redux/driver/thunks/getDriverThunk";
import { DriversTable } from "../UI/DriversTable";
import WhitePlusIcon from "../../images/deliveries/whitePlusIcon.svg";
import { Pagination } from "../UI/Pagination";
import "./Drivers.css";
import {
  toggleAddNewDriverModal,
  setIsEditMode,
  setIsMapShown,
  setIsModalsBgShown,
} from "../../redux/globalFunctions/globalFnSlice";
import { fetchDeliveries } from "../../redux/package/thunks/getDeliveryThunk";
import translations from "../../translations.json";

export function Drivers() {
  const dispatch = useDispatch();
  const driversState = useSelector((state) => state.drivers);
  const searchQuery = useSelector((state) => state.search.query);
  const updateDriverState = useSelector((state) => state.updateDriver);
  const inviteDriverState = useSelector((state) => state.inviteDriver);
  const deleteDriverState = useSelector((state) => state.deleteDriver);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [isFiltered, setIsFiltered] = useState(false);
  const [sortState, setSortState] = useState({ field: "", order: "ASC" });

  useEffect(() => {
    const filters = {};
    if (searchQuery) {
      filters.query = searchQuery;
      setCurrentPage(1);
      setIsFiltered(true);
    }

    dispatch(
      fetchDrivers({
        filters,
        sort_by: sortState.field,
        sort_order: sortState.order,
        page: currentPage,
        per_page: itemsPerPage,
      })
    );
  }, [dispatch, currentPage, itemsPerPage, sortState, searchQuery]);

  const handleSort = (field) => {
    setSortState((prevState) => {
      if (prevState.field === field) {
        return {
          ...prevState,
          order: prevState.order === "ASC" ? "DESC" : "ASC",
        };
      } else {
        return { field, order: "ASC" };
      }
    });
  };

  const totalPages = Math.ceil(driversState.total_count / 10);
  const totalFilteredPages = Math.ceil(driversState.filtered_count / 10);

  useEffect(() => {
    if (
      updateDriverState.status === "success" ||
      inviteDriverState.status === "success" ||
      deleteDriverState.status === "success"
    ) {
      dispatch(
        fetchDrivers({
          filters: { query: searchQuery },
          sort_by: sortState.field,
          sort_order: sortState.order,
          page: currentPage,
          per_page: itemsPerPage,
        })
      );
    }
  }, [
    inviteDriverState.status,
    dispatch,
    updateDriverState.status,
    deleteDriverState.status,
    searchQuery,
    sortState,
    currentPage,
    itemsPerPage,
  ]);

  const driversPage = translations.drivers;

  return (
    <section className="driversSection">
      <div className="firstDivChild">
        <h6>{driversPage.drivers}</h6>
        <div className="buttonsDiv">
          <button
            className="mapButton"
            onClick={() => {
              dispatch(setIsMapShown(true));
              dispatch(setIsModalsBgShown(true));
              dispatch(fetchDeliveries({}));
            }}
          >
            {driversPage.mapBtn}
          </button>

          <button
            className="addNewDriverBtn"
            onClick={() => {
              dispatch(toggleAddNewDriverModal());
              dispatch(setIsEditMode(false));
            }}
          >
            <img src={WhitePlusIcon} alt={driversPage.WhitePlusIcon} />
            {driversPage.btnText}
          </button>
        </div>
      </div>
      <br />
      <div className="driversContent">
        <div className="filtersDrivers">
          {driversPage.filters.filterBy}
          <button
            onClick={() => handleSort("u_firstname")}
            className={`sortBtn ${
              sortState.field === "u_firstname" ? "activeBtn" : ""
            }`}
          >
            {driversPage.filters.namesSelect +
              (sortState.field === "u_firstname" ? ` ${sortState.order}` : "")}
          </button>
          <button
            onClick={() => handleSort("u_vehicle_id")}
            className={`sortBtn ${
              sortState.field === "u_vehicle_id" ? "activeBtn" : ""
            }`}
          >
            {driversPage.filters.vehiclesSelect +
              (sortState.field === "u_vehicle_id" ? ` ${sortState.order}` : "")}
          </button>
          <button
            onClick={() => {
              setSortState({ field: "", order: "ASC" });
            }}
            className="resetBtn"
            disabled={sortState.field === "" && sortState.order === "ASC"}
          >
            {driversPage.filters.resetSelectsBtn}
          </button>
        </div>
        <div className="driversTableHeader">
          <div>{driversPage.table.id}</div>
          <div>{driversPage.table.name}</div>
          <div>{driversPage.table.email}</div>
          <div>{driversPage.table.phone}</div>
          <div>{driversPage.table.vehicle}</div>
          <div>{driversPage.table.location}</div>
          <div>{driversPage.table.routes}</div>
          <div>{driversPage.table.action}</div>
        </div>
        <div className="driversResults">
          {driversState.status === "loading" && (
            <div className="statusLoad">
              {driversPage.loading} <span className="blackLoader"></span>
            </div>
          )}
          {driversState.status === "error" && (
            <div className="statusError">{driversState.error}</div>
          )}
          {driversState.status === "succeeded" &&
            driversState.drivers.map((result, index) => (
              <DriversTable
                key={index}
                data={result}
                u_id={result.u_id}
                u_firstname={result.u_firstname}
                u_lastname={result.u_lastname}
                u_phonenumber={result.u_phonenumber}
                u_vehicle_id={result.u_vehicle_id}
                u_avatar={result.u_avatar}
                u_email={result.u_email}
              />
            ))}
        </div>
        {driversState.drivers.length !== 0 &&
          driversState.status === "succeeded" && (
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
              filteredPages={totalFilteredPages}
              isFiltered={isFiltered}
            />
          )}
      </div>
    </section>
  );
}
