// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoadingSpinner_spinner__au5x6 {
  width: 25px;
  height: 25px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: LoadingSpinner_rotation__CHHvr 1s linear infinite;
}

@keyframes LoadingSpinner_rotation__CHHvr {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.LoadingSpinner_blackSpinner__IUKRJ {
  width: 25px;
  height: 25px;
  border: 5px solid #000000;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: LoadingSpinner_rotation__CHHvr 1s linear infinite;
}
`, "",{"version":3,"sources":["webpack://./src/components/loadingSpinner/LoadingSpinner.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,gCAAgC;EAChC,kBAAkB;EAClB,qBAAqB;EACrB,sBAAsB;EACtB,4DAAsC;AACxC;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,gCAAgC;EAChC,kBAAkB;EAClB,qBAAqB;EACrB,sBAAsB;EACtB,4DAAsC;AACxC","sourcesContent":[".spinner {\r\n  width: 25px;\r\n  height: 25px;\r\n  border: 5px solid #fff;\r\n  border-bottom-color: transparent;\r\n  border-radius: 50%;\r\n  display: inline-block;\r\n  box-sizing: border-box;\r\n  animation: rotation 1s linear infinite;\r\n}\r\n\r\n@keyframes rotation {\r\n  0% {\r\n    transform: rotate(0deg);\r\n  }\r\n  100% {\r\n    transform: rotate(360deg);\r\n  }\r\n}\r\n\r\n.blackSpinner {\r\n  width: 25px;\r\n  height: 25px;\r\n  border: 5px solid #000000;\r\n  border-bottom-color: transparent;\r\n  border-radius: 50%;\r\n  display: inline-block;\r\n  box-sizing: border-box;\r\n  animation: rotation 1s linear infinite;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": `LoadingSpinner_spinner__au5x6`,
	"rotation": `LoadingSpinner_rotation__CHHvr`,
	"blackSpinner": `LoadingSpinner_blackSpinner__IUKRJ`
};
export default ___CSS_LOADER_EXPORT___;
