import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Deliveries.css";
import WhitePlusIcon from "../../images/deliveries/whitePlusIcon.svg";
import { DeliveriesTableElements } from "../UI/DeliveriesTable";
import { Pagination } from "../UI/Pagination.jsx";
import { fetchDeliveries } from "../../redux/package/thunks/getDeliveryThunk";
import { setSearchQuery } from "../../redux/misc/slices/searchSlice";
import { fetchDrivers } from "../../redux/driver/thunks/getDriverThunk.js";
import { fetchCustomers } from "../../redux/customer/thunks/getCustomerThunk.js";
import {
  onEdit,
  setIsEditMode,
} from "../../redux/globalFunctions/globalFnSlice.js";
import translations from "../../translations.json";

export function Deliveries() {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  // Variables for redux states.
  const createPackageState = useSelector((state) => state.createDelivery);
  const updatePackageState = useSelector((state) => state.updatePackage);
  const cancelPackageState = useSelector((state) => state.cancelDelivery);
  const deliveriesState = useSelector((state) => state.deliveries);
  const searchQuery = useSelector((state) => state.search.query); // Get search query from Redux state
  const driversState = useSelector((state) => state.drivers);
  const customersState = useSelector((state) => state.customers);

  //Function for checking if some of the filters are present
  const areFiltersActive = (filters) =>
    Object.values(filters).some((value) => value !== "");

  // Function to get the names for drivers and customers.
  useEffect(() => {
    dispatch(fetchDrivers({ per_page: 1 }));
    dispatch(fetchCustomers({ per_page: 1 }));
  }, [dispatch]);

  // State variables for dropdown options and filters
  const [customerOptions, setCustomerOptions] = useState([]);
  const [driverOptions, setDriverOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const initialFilters = {
    customer_name: "",
    driver_name: "",
    status: "",
    date: "",
  };

  const [filters, setFilters] = useState({ ...initialFilters });
  const [isFiltered, setIsFiltered] = useState(false);

  // Initial fetch and append data to page.
  useEffect(() => {
    dispatch(fetchDeliveries({ filters, page: currentPage }));
  }, [currentPage, filters]);

  // Functions for refreshing the results on the page
  useEffect(() => {
    if (createPackageState.status === "success") {
      if (areFiltersActive(filters)) {
        handleResetFilters();
      }
      dispatch(fetchDeliveries({ filters, page: 1 }));
      setCurrentPage(1);
    }
  }, [createPackageState.status]);

  useEffect(() => {
    if (areFiltersActive(filters)) {
      setCurrentPage(1);
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }
  }, [filters]);

  useEffect(() => {
    if (
      updatePackageState.status === "success" ||
      cancelPackageState.status === "success"
    ) {
      dispatch(fetchDeliveries({ filters, page: currentPage }));
      setIsFiltered(areFiltersActive(filters));
    }
  }, [dispatch, updatePackageState.status, cancelPackageState.status]);

  useEffect(() => {
    // Update filters with search query
    setFilters({
      ...filters,
      customer_name: searchQuery,
    });
    setCurrentPage(1);
    setIsFiltered(true);
  }, [searchQuery]);

  useEffect(() => {
    // Update dropdown options when deliveries data changes
    if (deliveriesState && deliveriesState.deliveries.length > 0) {
      // Extract unique customer names
      const uniqueCustomers = Array.from(
        new Set(
          customersState.customer_names.map((customer) => customer.c_name)
        )
      );
      setCustomerOptions(uniqueCustomers);

      // Extract unique driver names
      const uniqueDrivers = Array.from(
        new Set(driversState.driver_names.map((driver) => driver.d_name))
      );
      setDriverOptions(uniqueDrivers);

      setStatusOptions([
        "Shipped",
        "On the way",
        "Waiting",
        "Cancelled",
        "Delivered",
        "New",
        "Picked",
        "Accepted",
      ]);
    }
  }, [deliveriesState, driversState, customersState]);

  const currentDeliveries = deliveriesState.deliveries;

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleResetFilters = () => {
    setFilters({ ...initialFilters });
    dispatch(setSearchQuery("")); // Reset search query in Redux state
    setIsFiltered(false);
  };

  // Getting total pages count functions.
  const totalPages = Math.ceil(deliveriesState.total_count / 10);
  const totalFilteredPages = Math.ceil(deliveriesState.filtered_count / 10);

  const isStatusLoading = deliveriesState.status === "loading";

  const deliveriesPage = translations.deliveries;

  return (
    <section className="deliveriesContent">
      <div className="deliveriesTopDiv">
        <h6>{deliveriesPage.deliveries}</h6>
        <button
          className="addNewPackageBtn"
          onClick={() => {
            dispatch(onEdit());
            dispatch(setIsEditMode(false));
          }}
        >
          <img src={WhitePlusIcon} alt={deliveriesPage.whitePlusIconAlt} />
          {deliveriesPage.btnText}
        </button>
      </div>
      <div className="deliveriesContainer">
        <div className="filtersDeliveries">
          <p>{deliveriesPage.filters.filterBy}</p>
          <select
            name="customer_name"
            id="customerSelect"
            value={filters.customer_name}
            onChange={handleFilterChange}
          >
            <option value="">{deliveriesPage.filters.customerSelect}</option>
            {customerOptions.map((customer, index) => (
              <option key={index} value={customer}>
                {customer}
              </option>
            ))}
          </select>
          <input
            type="date"
            name="date"
            id="date"
            value={filters.date}
            onChange={handleFilterChange}
          />
          <select
            name="driver_name"
            id="drivers"
            value={filters.driver_name}
            onChange={handleFilterChange}
          >
            <option value="">{deliveriesPage.filters.driversSelect}</option>
            {driverOptions.map((driver, index) => (
              <option key={index} value={driver}>
                {driver}
              </option>
            ))}
          </select>
          <select
            name="status"
            id="statuses"
            value={filters.status}
            onChange={handleFilterChange}
          >
            <option value="">{deliveriesPage.filters.statusesSelect}</option>
            {statusOptions.map((status, index) => (
              <option key={index} value={status}>
                {status}
              </option>
            ))}
          </select>
          <button
            className="resetFiltersBtn"
            onClick={handleResetFilters}
            disabled={!areFiltersActive(filters) || isStatusLoading}
          >
            {deliveriesPage.filters.resetSelectsBtn}
          </button>
        </div>
        <div className="deliveriesTableHeader">
          <div style={{ width: "50px" }}>{deliveriesPage.table.order}</div>
          <div style={{ width: "120px" }}>{deliveriesPage.table.customer}</div>
          <div style={{ width: "120px" }}>
            {deliveriesPage.table.pickupAddress}
          </div>
          <div style={{ width: "120px" }}>
            {deliveriesPage.table.dropoffAddress}
          </div>
          <div style={{ width: "100px" }}>{deliveriesPage.table.date}</div>
          <div style={{ width: "100px" }}>{deliveriesPage.table.time}</div>
          <div style={{ width: "100px" }}>{deliveriesPage.table.driver}</div>
          <div style={{ width: "80px" }}>{deliveriesPage.table.status}</div>
          <div style={{ width: "60px", textAlign: "end" }}>
            {deliveriesPage.table.action}
          </div>
        </div>
        <div className="deliveriesResults">
          {isStatusLoading && (
            <p className="statusLoad">
              <span className="blackLoader"></span>
            </p>
          )}
          {deliveriesState.status === "error" && (
            <p className="statusError">{deliveriesPage.errorFetchingData}</p>
          )}

          {!isStatusLoading &&
            currentDeliveries.map((result, index) => (
              <DeliveriesTableElements
                key={index}
                data={result}
                order_number={result.package_id}
                customer={result.customer.name}
                pickup_address={result.pickup_address_name}
                dropoff_address={result.dropoff_address_name}
                date={result.order_date}
                time={result.order_time}
                driver_name={result.driver.name}
                status={result.status}
              />
            ))}
          {currentDeliveries.length === 0 &&
            deliveriesState.status !== "idle" &&
            deliveriesState.status !== "error" &&
            !isStatusLoading && (
              <p className="statusError">
                {deliveriesPage.noDeliveriesFound}
                {filters.customer_name ||
                filters.driver_name ||
                filters.status ||
                filters.date
                  ? ` for ${
                      filters.customer_name ||
                      filters.driver_name ||
                      filters.status ||
                      filters.date
                    }`
                  : ""}
              </p>
            )}
        </div>
        {currentDeliveries.length !== 0 && !isStatusLoading && (
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
            filteredPages={totalFilteredPages}
            isFiltered={isFiltered}
          />
        )}
      </div>
    </section>
  );
}
