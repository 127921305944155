import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../../constants";

export const fetchVehicles = createAsyncThunk(
  "vehicles/fetchVehicles",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.user.token; // Assuming you have user token stored in Redux

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await fetch(`${apiUrl}/get_vehicles`, requestOptions);

      if (!response.ok) {
        let errorMessage = "Network response was not ok";
        try {
          const error = await response.json();
          errorMessage = error.msg || errorMessage;
        } catch (e) {
          errorMessage = await response.text();
        }
        throw new Error(errorMessage || "Unknown error occurred");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Fetching vehicles failed:", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
