import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDepartments } from "../../redux/department/thunks/getDepartmentThunk";
import { fetchCustomers } from "../../redux/customer/thunks/getCustomerThunk";
import "./Addresses.css";
import WhitePlusIcon from "../../images/deliveries/whitePlusIcon.svg";
import { AddressesTable } from "../UI/AddressesTable";
import { Pagination } from "../UI/Pagination.jsx";
import { setSearchQuery } from "../../redux/misc/slices/searchSlice";
import {
  toggleAddNewCustomerAddress,
  setIsEditMode,
  setIsCustomerActive,
  toggleAddLaundry,
} from "../../redux/globalFunctions/globalFnSlice.js";
import translations from "../../translations.json";
import { resetCustomersError } from "../../redux/customer/slices/getCustomerSlice.js";
import { resetDepartmentsState } from "../../redux/department/slices/getDepartmentSlice.js";

export function Addresses() {
  const dispatch = useDispatch();
  const { isCustomerActive } = useSelector((state) => state.global);

  // Variables for redux states.
  const searchQuery = useSelector((state) => state.search.query);

  // Customer state variables.
  const customersState = useSelector((state) => state.customers);
  const updateCustomerState = useSelector((state) => state.updateCustomer);
  const createCustomerState = useSelector((state) => state.createCustomer);
  const deleteCustomerState = useSelector((state) => state.deleteCustomer);

  // Department state variables.
  const departmentsState = useSelector((state) => state.departments);
  const updateDepartmentState = useSelector((state) => state.updateDepartment);
  const createDepartmentState = useSelector((state) => state.createDepartment);
  const deleteDepartmentState = useSelector((state) => state.deleteDepartment);

  // State variables to store options for dropdowns and filters.
  const [customerOptions, setCustomerOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [customerFilters, setCustomerFilters] = useState({ customer: "" });
  const [departmentFilters, setDepartmentFilters] = useState({
    department_name: "",
  });

  const [isFiltered, setIsFiltered] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  // Getting total pages count functions.
  const totalCustomerPagesCount = Math.ceil(customersState.total_count / 10);
  const departmentTotalPagesCount = Math.ceil(
    departmentsState.total_count / 10
  );

  const filteredCustomerPages = Math.ceil(customersState.filtered_count / 10);
  const filteredDepartmentPages = Math.ceil(
    departmentsState.filtered_count / 10
  );

  const isStatusSuccess =
    updateCustomerState.status === "success" ||
    deleteCustomerState.status === "success" ||
    updateDepartmentState.status === "success" ||
    deleteDepartmentState.status === "success";

  // Initial fetch and append data to page.
  useEffect(() => {
    if (isCustomerActive) {
      dispatch(fetchCustomers({ filters: customerFilters, page: currentPage }));
    } else if (!isCustomerActive) {
      dispatch(
        fetchDepartments({ filters: departmentFilters, page: currentPage })
      );
    }
  }, [
    dispatch,
    isCustomerActive,
    customerFilters,
    departmentFilters,
    currentPage,
  ]);

  // Check customer-related statuses.
  useEffect(() => {
    if (isCustomerActive) {
      if (
        createCustomerState.status === "success" &&
        customersState.total_count % 10 === 0
      ) {
        setCurrentPage(totalCustomerPagesCount + 1);
        dispatch(
          fetchCustomers({
            filters: customerFilters,
            page: totalCustomerPagesCount + 1,
          })
        );
      } else if (createCustomerState.status === "success") {
        setCurrentPage(totalCustomerPagesCount);
        dispatch(
          fetchCustomers({
            filters: customerFilters,
            page: totalCustomerPagesCount,
          })
        );
      } else if (
        customersState.customers.length === 0 &&
        currentPage === 1 &&
        createCustomerState.status === "success"
      ) {
        dispatch(fetchCustomers({ filters: customerFilters, page: 1 }));
      }
    }
  }, [createCustomerState.status]);

  // If deleted the last result on page, refetch results one page back.
  useEffect(() => {
    if (customersState.status === "error" && currentPage !== 1) {
      setCurrentPage((prev) => prev - 1);
      dispatch(
        fetchCustomers({ filters: customerFilters, page: currentPage - 1 })
      );

      setTimeout(() => {
        dispatch(resetCustomersError());
      }, 100);
    }
  }, [customersState.status, currentPage, customerFilters, dispatch]);

  // If deleted the last result on page, refetch results one page back.
  useEffect(() => {
    if (
      !isCustomerActive &&
      departmentsState.status === "error" &&
      currentPage !== 1
    ) {
      setCurrentPage((prev) => prev - 1);
      dispatch(
        fetchDepartments({ filters: departmentFilters, page: currentPage - 1 })
      );

      setTimeout(() => {
        dispatch(resetDepartmentsState());
      }, 100);
    }
  }, [
    isCustomerActive,
    departmentsState.status,
    currentPage,
    departmentFilters,
    dispatch,
  ]);

  // Check department-related statuses.
  useEffect(() => {
    if (!isCustomerActive) {
      if (
        createDepartmentState.status === "success" &&
        departmentsState.total_count % 10 === 0
      ) {
        setCurrentPage(departmentTotalPagesCount + 1);
        dispatch(
          fetchDepartments({
            filters: departmentFilters,
            page: departmentTotalPagesCount + 1,
          })
        );
      } else if (createDepartmentState.status === "success") {
        setCurrentPage(departmentTotalPagesCount);
        dispatch(
          fetchDepartments({
            filters: departmentFilters,
            page: departmentTotalPagesCount,
          })
        );
      } else if (
        departmentsState.departments.length === 0 &&
        currentPage === 1 &&
        createDepartmentState.status === "success"
      ) {
        dispatch(fetchDepartments({ filters: departmentFilters, page: 1 }));
      }
    }
  }, [createDepartmentState.status]);

  // If an update or delete function is executed, refresh the results on the page.
  useEffect(() => {
    // Customer related statuses.
    if (isStatusSuccess && isCustomerActive) {
      dispatch(fetchCustomers({ filters: customerFilters, page: currentPage }));
    }

    // Department related statuses.
    if (isStatusSuccess && !isCustomerActive) {
      dispatch(
        fetchDepartments({ filters: departmentFilters, page: currentPage })
      );
    }
  }, [
    updateCustomerState.status,
    deleteCustomerState.status,
    updateDepartmentState.status,
    deleteDepartmentState.status,
    customerFilters,
    departmentFilters,
    isCustomerActive,
    currentPage,
  ]);

  // Update filters based on search query changes
  useEffect(() => {
    if (searchQuery !== "") {
      if (isCustomerActive) {
        setCustomerFilters({
          ...customerFilters,
          customer: searchQuery,
        });
        setCurrentPage(1);
        setDepartmentFilters({ department_name: "" });
        setIsFiltered(true);
      } else if (!isCustomerActive) {
        setDepartmentFilters({
          ...departmentFilters,
          department_name: searchQuery,
        });
        setCurrentPage(1);
        setCustomerFilters({ customer: "" });
        setIsFiltered(true);
      }
    } else {
      // If search query is empty, reset both filters
      setCustomerFilters({ customer: "" });
      setDepartmentFilters({ department_name: "" });
    }
  }, [searchQuery, isCustomerActive]);

  // Function for reseting the filters and states on tab change.
  useEffect(() => {
    dispatch(setSearchQuery(""));
    setCustomerFilters({ customer: "" });
    setDepartmentFilters({ department_name: "" });
    setCurrentPage(1);
  }, [isCustomerActive]);

  // Function for handling filter change.
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    if (isCustomerActive) {
      setCustomerFilters({ ...customerFilters, [name]: value });
    } else if (!isCustomerActive) {
      setDepartmentFilters({ ...departmentFilters, [name]: value });
    }
    setCurrentPage(1);
    setIsFiltered(true);
  };

  // Function for reseting the filters on button click.
  const handleResetFilters = () => {
    if (isCustomerActive) {
      setCustomerFilters({ customer: "" });
    } else {
      setDepartmentFilters({ department_name: "" });
    }
    dispatch(setSearchQuery(""));
    setIsFiltered(false);
  };

  // Function for appending the option values to the select dropdown.
  useEffect(() => {
    if (isCustomerActive) {
      const customerNames = customersState.customer_names.map(
        (customer) => customer.c_name
      );
      setCustomerOptions(customerNames);
    } else if (!isCustomerActive) {
      const departmentNames = departmentsState.department_names.map(
        (department) => department.d_name
      );
      setDepartmentOptions(departmentNames);
    }
  }, [
    isCustomerActive,
    customersState.customer_names,
    departmentsState.department_names,
  ]);

  const addressesPage = translations.addresses;

  // Function for rendering data based on the state.
  const renderData = () => {
    if (
      customersState.status === "loading" ||
      departmentsState.status === "loading"
    ) {
      return (
        <div className="statusLoad">
          <span className="blackLoader"></span>
        </div>
      );
    }

    if (
      (customersState.status === "error" && isCustomerActive) ||
      (departmentsState.status === "error" && !isCustomerActive)
    ) {
      return (
        <div className="statusError">{addressesPage.errorFetchingData}</div>
      );
    }

    const data = isCustomerActive
      ? customersState.customers
      : departmentsState.departments;

    return (
      <>
        <div className="addressesTableHeader">
          <div>
            {isCustomerActive
              ? addressesPage.table.customer.customer
              : addressesPage.table.department.department}
          </div>
          <div>{addressesPage.table.address}</div>
          <div>{addressesPage.table.phone}</div>
          <div>{addressesPage.table.edit}</div>
          <div>{addressesPage.table.delete}</div>
        </div>
        <div className="tableResults">
          {data.map((result, index) => (
            <AddressesTable
              result={result}
              key={index}
              name={isCustomerActive ? result.c_name : result.d_name}
              address={isCustomerActive ? result.c_address : result.d_address}
              phone={
                isCustomerActive ? result.c_phonenumber : result.d_phonenumber
              }
            />
          ))}
        </div>

        {data.length != 0 && (
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={
              isCustomerActive
                ? totalCustomerPagesCount
                : departmentTotalPagesCount
            }
            filteredPages={
              isCustomerActive ? filteredCustomerPages : filteredDepartmentPages
            }
            isFiltered={isFiltered}
          />
        )}
      </>
    );
  };

  function handleClick() {
    dispatch(setIsEditMode(false));
    if (isCustomerActive) {
      dispatch(toggleAddNewCustomerAddress());
    } else {
      dispatch(toggleAddLaundry());
    }
  }

  return (
    <section className="addresses">
      <div className="addressesTopDiv">
        <div className="topDivContent">
          <div className="toggleDataBtns">
            <button
              className={isCustomerActive ? "active" : "customerBtn"}
              onClick={() => dispatch(setIsCustomerActive(true))}
            >
              {addressesPage.customerBtn}
            </button>
            <button
              className={!isCustomerActive ? "active" : "laundryBtn"}
              onClick={() => dispatch(setIsCustomerActive(false))}
            >
              {addressesPage.laundryBtn}
            </button>
          </div>
          <button className="addNewAddressBtn" onClick={handleClick}>
            <img src={WhitePlusIcon} alt={addressesPage.whitePlusIconAlt} />
            {addressesPage.newAddressBtn}
          </button>
        </div>
      </div>
      <div className="addressesContent">
        <div className="addressFilters">
          {addressesPage.filters.filterBy}
          {isCustomerActive ? (
            <select
              name="customer"
              id="customerSelect"
              value={customerFilters.customer}
              onChange={handleFilterChange}
            >
              <option value="">
                {addressesPage.filters.customer.allCustomers}
              </option>
              {customerOptions.map((customer, index) => (
                <option key={index} value={customer}>
                  {customer}
                </option>
              ))}
            </select>
          ) : (
            <select
              name="department_name"
              id="departmentSelect"
              value={departmentFilters.department_name}
              onChange={handleFilterChange}
            >
              <option value="">
                {addressesPage.filters.department.allDepartments}
              </option>
              {departmentOptions.map((department, index) => (
                <option key={index} value={department}>
                  {department}
                </option>
              ))}
            </select>
          )}
          <button
            className="resetBtn"
            disabled={
              (isCustomerActive && !customerFilters.customer) ||
              (!isCustomerActive && !departmentFilters.department_name) ||
              customersState.status === "loading" ||
              departmentsState.status === "loading"
            }
            onClick={handleResetFilters}
          >
            {addressesPage.filters.resetSelectsBtn}
          </button>
        </div>
        {renderData()}
      </div>
    </section>
  );
}
