// thunks/driverThunk.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../../constants";

export const fetchDrivers = createAsyncThunk(
  "drivers/fetchDrivers",
  async (data, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.user.token;

    // Build the query parameters dynamically
    const queryParams = new URLSearchParams();
    if (data.id) queryParams.append("id", data.id);
    if (data.filters && data.filters.query) {
      const searchField = determineSearchField(data.filters.query);
      if (searchField) {
        queryParams.append(searchField, data.filters.query);
      } else {
        throw new Error("Invalid search criteria provided.");
      }
    }
    if (data.sort_by) queryParams.append("sort_by", data.sort_by);
    if (data.sort_order) queryParams.append("sort_order", data.sort_order);
    if (data.page) queryParams.append("page", data.page);
    if (data.per_page) queryParams.append("per_page", data.per_page);

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apiUrl}/get_drivers?${queryParams.toString()}`,
        requestOptions
      );

      if (!response.ok) {
        let errorMessage = "Network response was not ok";
        try {
          const errorText = await response.text();
          const errorObj = JSON.parse(errorText);
          errorMessage = errorObj.msg || errorMessage;
        } catch (e) {
          errorMessage = errorText || errorMessage;
        }
        throw new Error(errorMessage || "Unknown error occurred");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Fetching drivers failed:", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Helper function to determine search field
function determineSearchField(query) {
  // Determine if the query looks like it's for u_firstname or u_lastname
  // For simplicity, assume if it's a single word, it's u_firstname; otherwise, u_lastname
  if (query.trim().split("+").length === 1) {
    return "u_firstname";
  } else {
    return "u_lastname";
  }
}
