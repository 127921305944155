// userSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { login } from "../thunks/userThunk"; // Correct this import
import { fetchAvatar } from "../thunks/userThunk"; // Ensure fetchAvatar is imported
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const userSlice = createSlice({
  name: "user",
  initialState: {
    status: "idle",
    error: null,
    token: null,
    refreshToken: null,
    email: null,
    avatar: null,
    avatarBlob: null,
    firstname: null,
    lastname: null,
    tokenExpiryTime: null,
  },
  reducers: {
    logoutUser(state) {
      state.token = null;
      state.refreshToken = null;
      state.status = "idle";
      state.error = null;
      state.email = null;
      state.avatar = null;
      state.avatarBlob = null;
      state.firstname = null;
      state.lastname = null;
      state.tokenExpiryTime = null;
    },
    resetLoginState(state) {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        const currentTime = new Date().getTime();
        const tokenExpiryTime = currentTime + 24 * 60 * 60 * 1000; // 1 day

        state.token = action.payload.access_token;
        state.refreshToken = action.payload.refresh_token;
        state.email = action.payload.email;
        state.avatar = action.payload.avatar;
        state.firstname = action.payload.firstname;
        state.lastname = action.payload.lastname;
        state.status = "success";
        state.error = null;
        state.tokenExpiryTime = tokenExpiryTime;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      })
      .addCase(fetchAvatar.fulfilled, (state, action) => {
        state.avatarBlob = URL.createObjectURL(action.payload);
      })
      .addCase(fetchAvatar.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

const persistConfig = {
  key: "user",
  storage,
};

export const { logoutUser, resetLoginState } = userSlice.actions;
export default persistReducer(persistConfig, userSlice.reducer);
