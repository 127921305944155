// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 4px;
  width: 95%;
  height: 50px;
  padding: 10px 16px;
}

.nextBtn,
.prevBtn,
.firstPage {
  height: 32px;
  width: 32px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid rgba(149, 155, 164, 0.32);
  font-size: 14px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  &:hover {
    background-color: rgb(242, 242, 242);
  }
}

.pagination p {
  font-size: 20px;
  margin: 0;
  padding: 0;
}

.activePage {
  background-color: #3082f6;
  color: white;
  height: 32px;
  width: 32px;
  border-radius: 10px;
  border: 1px solid rgba(149, 155, 164, 0.32);
  font-size: 14px;
  cursor: default;
}

.prevBtn:disabled,
.nextBtn:disabled {
  cursor: not-allowed;
  background-color: rgb(233, 233, 233);
}
`, "",{"version":3,"sources":["webpack://./src/components/UI/Pagination.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,oBAAoB;EACpB,mBAAmB;EACnB,QAAQ;EACR,UAAU;EACV,YAAY;EACZ,kBAAkB;AACpB;;AAEA;;;EAGE,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,uBAAuB;EACvB,2CAA2C;EAC3C,eAAe;EACf,sCAAsC;EACtC,eAAe;EACf;IACE,oCAAoC;EACtC;AACF;;AAEA;EACE,eAAe;EACf,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,2CAA2C;EAC3C,eAAe;EACf,eAAe;AACjB;;AAEA;;EAEE,mBAAmB;EACnB,oCAAoC;AACtC","sourcesContent":[".pagination {\r\n  display: flex;\r\n  justify-content: end;\r\n  align-items: center;\r\n  gap: 4px;\r\n  width: 95%;\r\n  height: 50px;\r\n  padding: 10px 16px;\r\n}\r\n\r\n.nextBtn,\r\n.prevBtn,\r\n.firstPage {\r\n  height: 32px;\r\n  width: 32px;\r\n  border-radius: 10px;\r\n  background-color: white;\r\n  border: 1px solid rgba(149, 155, 164, 0.32);\r\n  font-size: 14px;\r\n  transition: background-color 0.3s ease;\r\n  cursor: pointer;\r\n  &:hover {\r\n    background-color: rgb(242, 242, 242);\r\n  }\r\n}\r\n\r\n.pagination p {\r\n  font-size: 20px;\r\n  margin: 0;\r\n  padding: 0;\r\n}\r\n\r\n.activePage {\r\n  background-color: #3082f6;\r\n  color: white;\r\n  height: 32px;\r\n  width: 32px;\r\n  border-radius: 10px;\r\n  border: 1px solid rgba(149, 155, 164, 0.32);\r\n  font-size: 14px;\r\n  cursor: default;\r\n}\r\n\r\n.prevBtn:disabled,\r\n.nextBtn:disabled {\r\n  cursor: not-allowed;\r\n  background-color: rgb(233, 233, 233);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
