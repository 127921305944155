import React, { useEffect, useState, useCallback, useRef } from "react";
import Map, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddressData,
  setIsFetchCurrentAddress,
  setSelectedAddress,
} from "../../redux/globalFunctions/globalFnSlice";

const MapboxComponent = ({ address, country, city, updateAddress }) => {
  const [coordinates, setCoordinates] = useState({
    lat: 37.7749,
    lng: -122.4194,
  });
  const [viewState, setViewState] = useState({
    latitude: 37.78,
    longitude: -122.4194,
    zoom: 10,
  });

  // Create refs for debounce timeouts
  const debounceTimeout = useRef(null);

  const { selectedAddress, isEditMode } = useSelector((state) => state.global);

  const mapboxToken =
    "pk.eyJ1IjoiYm96aWRhcmszNSIsImEiOiJjbTFxNTdzMWUwOWZwMmpzYjN4eHF5Zmt5In0.fVeom9WzttFClBuhzNSjKg";
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditMode) {
      dispatch(setIsFetchCurrentAddress(true));
      return;
    }
    dispatch(setIsFetchCurrentAddress(true));
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCoordinates({ lat: latitude, lng: longitude });
        setViewState((prev) => ({
          ...prev,
          latitude,
          longitude,
          zoom: 14,
        }));

        // Perform reverse geocoding to get the address from coordinates
        reverseGeocodeCoordinates(latitude, longitude);
      },
      (error) => {
        console.error("Error getting user's location:", error);
      },
      {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0,
      }
    );
  }, []);

  const geocodeLocation = useCallback(
    async (searchTerm, type = "address") => {
      if (!searchTerm) return;

      try {
        let searchParams = "";
        if (type === "country") {
          searchParams = "&types=country";
        } else if (type === "city") {
          searchParams = "&types=place";
        }

        const response = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
            searchTerm
          )}.json?access_token=${mapboxToken}${searchParams}`
        );

        const data = await response.json();
        const features = data.features;

        if (!selectedAddress || (selectedAddress && isEditMode)) {
          dispatch(setAddressData(features));
        }

        const [lng, lat] = features[0].center;
        const zoom = 17;

        setCoordinates({ lat, lng });

        setViewState({
          latitude: lat,
          longitude: lng,
          zoom,
        });

        if (type === "address") {
          const context = features[0].context || [];
          const foundCity =
            context.find((entry) => entry.id.startsWith("place"))?.text || "";
          const foundCountry =
            context.find((entry) => entry.id.startsWith("country"))?.text || "";
          const postalCode =
            context.find((entry) => entry.id.startsWith("postcode"))?.text ||
            "";

          updateAddress(searchTerm, lat, lng, {
            city: foundCity,
            country: foundCountry,
            postalCode,
          });
        }
      } catch (error) {
        console.error("Error fetching the coordinates:", error);
      }
    },
    [dispatch, mapboxToken, updateAddress]
  );

  const debouncedGeocodeLocation = useCallback(
    (searchTerm, type) => {
      // Clear any existing timeout
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      // Set new timeout
      debounceTimeout.current = setTimeout(() => {
        geocodeLocation(searchTerm, type);
      }, 500);
    },
    [geocodeLocation]
  );

  const reverseGeocodeCoordinates = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${mapboxToken}`
      );
      const data = await response.json();

      if (!data.features || data.features.length === 0) return;

      dispatch(setSelectedAddress(null));

      dispatch(setAddressData(data.features));

      const newAddress = data.features[0].place_name;
      const context = data.features[0].context || [];

      const foundCity =
        context.find((entry) => entry.id.startsWith("place"))?.text || "";
      const foundCountry =
        context.find((entry) => entry.id.startsWith("country"))?.text || "";
      const postalCode =
        context.find((entry) => entry.id.startsWith("postcode"))?.text || "";

      updateAddress(newAddress, lat, lng, {
        city: foundCity,
        country: foundCountry,
        postalCode,
      });
    } catch (error) {
      console.error("Error fetching the address:", error);
    }
  };

  // Debounced effects for address, country, and city
  useEffect(() => {
    if (!address && !city && !country) return;

    // Determine the most specific search term available
    let searchTerm = address || city || country;
    let type = address ? "address" : city ? "city" : "country";

    debouncedGeocodeLocation(searchTerm, type);
  }, [address, city, country]); // Only runs when these values change

  // Cleanup timeouts on unmount
  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  const handleMarkerDragEnd = (event) => {
    const lat = event.lngLat.lat;
    const lng = event.lngLat.lng;

    reverseGeocodeCoordinates(lat, lng).then(() => {
      setCoordinates({ lat, lng });
    });
  };

  return (
    <div>
      <Map
        {...viewState}
        onMove={(evt) => setViewState(evt.viewState)}
        style={{ width: "100%", height: "280px" }}
        mapStyle="mapbox://styles/mapbox/streets-v12"
        mapboxAccessToken={mapboxToken}
      >
        <Marker
          latitude={coordinates.lat}
          longitude={coordinates.lng}
          draggable={true}
          onDragEnd={handleMarkerDragEnd}
        />
      </Map>
    </div>
  );
};

export default MapboxComponent;
