import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../../constants";

export const fetchDriverLocation = createAsyncThunk("driverLocation/fetchDriverLocation", async (data, thunkAPI) => {
  const state = thunkAPI.getState();
  const token = state.user.token;

  // Build the query parameters dynamically
  const queryParams = new URLSearchParams();
  if (data.id) queryParams.append("driver_id", data.id);

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await fetch(`${apiUrl}/get_driver_location?${queryParams.toString()}`, requestOptions);

    if (!response.ok) {
      let errorMessage = "Network response was not ok";
      try {
        const errorText = await response.text();
        const errorObj = JSON.parse(errorText);
        errorMessage = errorObj.msg || errorMessage;
      } catch (e) {
        errorMessage = errorText || errorMessage;
      }
      throw new Error(errorMessage || "Unknown error occurred");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Fetching driver locations failed:", error);
    return thunkAPI.rejectWithValue(error.message);
  }
});
