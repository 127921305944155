// slices/getDeliveriesByDateSlice.js

import { createSlice } from "@reduxjs/toolkit";
import { fetchDeliveriesByDate } from "../thunks/getDeliveriesByDateThunk";

const getDeliveriesByDateSlice = createSlice({
  name: "deliveriesByDate",
  initialState: {
    deliveries: [],
    total_count: 0,
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'error'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeliveriesByDate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDeliveriesByDate.fulfilled, (state, action) => {
        state.deliveries = action.payload.deliveries; // Adjust according to your API response
        state.total_count = action.payload.total_count;
        state.status = "succeeded";
        state.error = null; // Reset error state upon success
      })
      .addCase(fetchDeliveriesByDate.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload; // Payload here will be the error message
      });
  },
});

export default getDeliveriesByDateSlice.reducer;
