// slices/searchSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { searchItems } from "../thunks/searchThunk"; // Assuming this is the correct import path for your thunk

const searchSlice = createSlice({
  name: "search",
  initialState: {
    query: "", // Initial search query state
    results: [],
    status: "idle",
    error: null,
  },
  reducers: {
    setSearchQuery: (state, action) => {
      state.query = action.payload;
    },
    resetSearchQuery: (state) => {
      state.query = "";
      state.results = [];
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchItems.pending, (state) => {
        state.status = "loading";
      })
      .addCase(searchItems.fulfilled, (state, action) => {
        state.results = action.payload.results;
        state.status = "succeeded";
        state.error = null;
      })
      .addCase(searchItems.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      })
      .addCase(setSearchQuery, (state, action) => {
        // When search query changes, trigger fetching deliveries
        state.query = action.payload;
        // You may optionally reset other parts of the state related to search results here
        state.results = [];
        state.status = "idle"; // Reset status to idle
        state.error = null; // Reset error to null
      });
  },
});

export const { setSearchQuery, resetSearchQuery } = searchSlice.actions;
export default searchSlice.reducer;
