import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { fetchDeliveriesByDate } from "../../redux/package/thunks/getDeliveriesByDateThunk";
import PlusSignIcon from "../../images/dashboard/plusSignIcon.svg";
import DeliveryVanIcon from "../../images/dashboard/deliveryVan.svg";
import CalendarIcon from "../../images/dashboard/calendarIcon.svg";
import "./Dashboard.css";
import { DashboardTable } from "../UI/DashboardTable";
import { OrderInformation } from "../UI/OrderInformation";
import { fetchDeliveries } from "../../redux/package/thunks/getDeliveryThunk";
import { formatDateString } from "../../helperFunctions/formatDate";
import {
  onEdit,
  setIsEditMode,
} from "../../redux/globalFunctions/globalFnSlice";
import translations from "../../translations.json";

export function Dashboard() {
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [lastPageDeliveries, setLastPageDeliveries] = useState([]);
  const [isPackageCreated, setIsPackageCreated] = useState(true);
  const today = new Date();

  // Variables for redux state.
  const deliveriesByDate = useSelector(
    (state) => state.deliveriesByDate.deliveries
  );
  const statusByDate = useSelector((state) => state.deliveriesByDate.status);
  const errorByDate = useSelector((state) => state.deliveriesByDate.error);
  const searchQuery = useSelector((state) => state.search.query);
  const deliveriesState = useSelector((state) => state.deliveries);
  const firstname = useSelector((state) => state.user.firstname);
  const createPackageState = useSelector((state) => state.createDelivery);

  const initialFilters = {
    customer_name: "",
    driver_name: "",
    status: "",
    date: "",
  };

  const [filters, setFilters] = useState({ ...initialFilters });

  // Fetch deliveries by selected date
  useEffect(() => {
    const formattedDate = format(selectedDate, "dd/MM/yyyy");
    dispatch(
      fetchDeliveriesByDate({ driver_name: searchQuery, formattedDate })
    );
  }, [dispatch, selectedDate, searchQuery]);


  useEffect(() => {
   console.log("Deliveries Data: ", deliveriesByDate)
   console.log("statusByDate Data: ", statusByDate)
   console.log("deliveriesState Data: ", deliveriesState)

  }, [deliveriesByDate, statusByDate, deliveriesState]);


  // Function for updating the filters from the searchQuery.
  useEffect(() => {
    if (searchQuery !== "") {
      setFilters({
        ...filters,
        driver_name: searchQuery,
      });
    } else {
      // Reset filters when search query is cleared and refetch deliveries.
      setFilters({ ...initialFilters });
    }
  }, [searchQuery]);

  // Fetch last page if deliveriesByDate fetch fails
  useEffect(() => {
    if (statusByDate === "error" && isPackageCreated === true) {
      dispatch(fetchDeliveries({ filters, page: 1 })).then((action) => {
        if (action.payload) {
          setLastPageDeliveries(action.payload.deliveries);
        }
      });
    }
  }, [statusByDate, dispatch, isPackageCreated, filters]);

  // Refetch deliveries when createPackageState.status is "success"
  useEffect(() => {
    if (createPackageState.status === "success") {
      setSelectedDate(today);
      const formattedDate = format(today, "dd/MM/yyyy");
      dispatch(fetchDeliveriesByDate({ formattedDate }));
      setIsPackageCreated(true);
    }
  }, [createPackageState.status]);

  // Function to handle click on a delivery row
  const handleDeliveryClick = (index) => {
    setSelectedIndex(index); // Set the selected delivery index
  };

  // Function to close the OrderInformation component
  const handleCloseOrderInformation = () => {
    setSelectedIndex(null); // Reset selectedIndex state
  };

  // Function to handle previous arrow click
  const handlePrevDelivery = () => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  // Function to handle next arrow click
  const handleNextDelivery = () => {
    if (selectedIndex < deliveriesResult.length - 1) {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const deliveriesResult =
    statusByDate === "error" ? lastPageDeliveries : deliveriesByDate;

  const dashboardPage = translations.dashboard;

  return (
    <section className="dashboard">
      <div className="dashboardTopDiv">
        <div className="bgDiv">
          <div className="contentLeft">
            <h4>{`${dashboardPage.hello} ${firstname}`}</h4>
            <p>{dashboardPage.welcomeText}</p>
            <button
              onClick={() => {
                dispatch(setIsEditMode(false));
                dispatch(onEdit());
              }}
            >
              <img src={PlusSignIcon} alt={dashboardPage.plusSignIconAlt} />
              {dashboardPage.btnText}
            </button>
          </div>
          <div className="contentRight">
            <img src={DeliveryVanIcon} alt={dashboardPage.deliveryVanIconAlt} />
          </div>
        </div>
      </div>
      <div className="deliveries-order-info">
        <div className="deliveries">
          <div className="deliveriesFirstDiv">
            <div className="firstDivContent">
              <h6>{dashboardPage.deliveries}</h6>
              <div className="calendarContainer">
                <img src={CalendarIcon} alt={dashboardPage.calendarIconAlt} />
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  dateFormat="dd/MM/yyyy"
                  className="datePicker"
                />
              </div>
            </div>
          </div>
          {statusByDate === "error" &&
            deliveriesResult.length !== 0 &&
            searchQuery === "" && (
              <div className="lastPageResults">
                {dashboardPage.noDeliveriesFound}{" "}
                {formatDateString(selectedDate)}.{" "}
                {dashboardPage.showingLastResults}
              </div>
            )}
          <div className="table">
            <div className="thead">
              <div className="tableHeaders">{dashboardPage.table.driver}</div>
              <div className="tableHeaders">{dashboardPage.table.order}</div>
              <div className="tableHeaders">{dashboardPage.table.date}</div>
              <div className="tableHeaders">{dashboardPage.table.address}</div>
              <div className="tableHeaders">{dashboardPage.table.status}</div>
            </div>
            <div className="dashboardResultsDiv">
              {deliveriesResult.length === 0 &&
                deliveriesState.status !== "loading" &&
                deliveriesState.status !== "idle" && (
                  <div className="noDeliveriesCreated">
                    {dashboardPage.noDeliveriesMsg}
                  </div>
                )}
              {(statusByDate === "loading" ||
                deliveriesState.status === "loading") && (
                <div className="statusLoad">
                  <span className="blackLoader"></span>
                </div>
              )}
              {statusByDate === "error" &&
                deliveriesState.status === "error" && (
                  <div className="statusError">{errorByDate}</div>
                )}

              {(statusByDate === "succeeded" ||
                deliveriesState.status === "succeeded") &&
                deliveriesResult &&
                deliveriesResult.map((result, index) => (
                  <DashboardTable
                    key={index}
                    order_number={result.package_id || result.delivery_id}
                    customer={result.customer_name}
                    pickup_address={result.pickup_address_name}
                    dropoff_address={result.dropoff_address_name}
                    date={result.order_date}
                    time={result.order_time}
                    driver_name={result.driver_name || result.driver.name}
                    status={result.status}
                    onClick={() => handleDeliveryClick(index)}
                    isSelected={selectedIndex === index} // Add this line
                    width={"95%"}
                  />
                ))}
            </div>
          </div>
        </div>


        {selectedIndex !== null && deliveriesResult[selectedIndex] && (
          <OrderInformation
            driver_name={
              deliveriesResult[selectedIndex].driver_name ||
              deliveriesResult[selectedIndex].driver.name
            }
            driver_id={
              deliveriesResult[selectedIndex].driver?.id || 
              deliveriesResult[selectedIndex].driver_id // fallback if structure is different
            }
            order_number={
              deliveriesResult[selectedIndex].delivery_id ??
              deliveriesResult[selectedIndex].package_id
            }
            date={deliveriesResult[selectedIndex].order_date}
            time={deliveriesResult[selectedIndex].order_time}
            address={deliveriesResult[selectedIndex].dropoff_address_name}
            shipping_status={deliveriesResult[selectedIndex].status}
            selectedIndex={selectedIndex}
            deliveries={deliveriesResult}
            onClose={handleCloseOrderInformation}
            onPrev={handlePrevDelivery}
            onNext={handleNextDelivery}
          />
        )}
      </div>
    </section>
  );
}
