import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../../constants";

export const inviteDriver = createAsyncThunk(
  "driver/inviteDriver",
  async (data, thunkAPI) => {
    // Retrieve the token from localStorage
    const state = thunkAPI.getState();
    const token = state.user.token;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data), // Send the entire data object as JSON
    };

    try {
      const response = await fetch(`${apiUrl}/invite_driver`, requestOptions);

      if (!response.ok) {
        let errorMessage = "Network response was not ok";
        try {
          const error = await response.json();
          errorMessage = error.msg || errorMessage;
        } catch (e) {
          errorMessage = await response.text();
        }
        throw new Error(errorMessage || "Unknown error occurred");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Driver invitation failed:", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
