import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { registerUser } from "../../redux/driver/thunks/registerDriverThunk";
import { validateToken } from "../../redux/driver/thunks/validateDriverInvitationThunk";
import { LoadingSpinner } from "../../components/loadingSpinner/LoadingSpinner";
import Logo from "../../images/Logo.svg";
import Background from "../../images/BG.svg";
import NorwayFlag from "../../images/NorwayFlag.svg";
import EnglishFlag from "../../images/EnglishFlag.svg";
import { resetRegisterState } from "../../redux/driver/slices/registerDriverSlice";
import translations from "../../translations.json";
import { setIsDownloadAppShown } from "../../redux/globalFunctions/globalFnSlice";
import { DownloadApp } from "../../components/modals/downloadApp/DownloadApp";
import classes from "./Register.module.css";
import whiteLogo from "../../images/sidebar/Logo.svg";

export function Register() {
  const [isEnglishLanguage, setIsEnglishLanguage] = useState(true);
  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.register);
  const validateTokenSelector = useSelector((state) => state.validateToken);
  const { isDownloadAppShown } = useSelector((state) => state.global);
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    dispatch(resetRegisterState());
  }, []);

  useEffect(() => {
    if (status === "error") {
      setTimeout(() => {
        dispatch(resetRegisterState());
      }, 2000);
    }
  }, [status]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const emailParam = params.get("email");
    const phonenumberParam = params.get("phonenumber");
    const tokenParam = params.get("token");

    if (emailParam) setEmail(emailParam);
    if (phonenumberParam) setPhonenumber(phonenumberParam);
    if (tokenParam) {
      setToken(tokenParam);
      dispatch(validateToken(tokenParam));
    } else {
      navigate("/login");
    }
  }, [location, dispatch, navigate]);

  useEffect(() => {
    if (
      validateTokenSelector.status === "error" &&
      validateTokenSelector.isValid === false
    ) {
      navigate("/login");
    }
  }, [validateTokenSelector, navigate]);

  function handlePhoneInput(e) {
    e.target.value = e.target.value.replace(/(?!^)\D/g, ""); // Allow only digits after the first character
    if (e.target.value[0] !== "+") {
      e.target.value = e.target.value.replace(/\D/g, ""); // Ensure the first character is "+"
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append("token", token);
    dispatch(registerUser(Object.fromEntries(formData)));
  };

  function handleDownloadAppModal() {
    dispatch(setIsDownloadAppShown(true));
  }

  const registerPage = translations.registerPage;

  const isStatusLoading = status === "loading";

  const isBtnDisabled =
    isStatusLoading ||
    email === "" ||
    phonenumber === "" ||
    password === "" ||
    confirmPassword === "";

  return (
    <section className={classes.registerContainer}>
      <div className={classes.logo}>
        <img src={Logo} alt={registerPage.iconAlts.logoAlt} />
      </div>
      <div className={classes.formBg}>
        <img
          className={classes.background}
          src={Background}
          alt={registerPage.iconAlts.formBgAlt}
        />
        <div className={classes.flags}>
          <div className={!isEnglishLanguage ? classes.selectedFlag : ""}>
            <img
              src={NorwayFlag}
              alt={registerPage.iconAlts.norwayFlagAlt}
              onClick={() => setIsEnglishLanguage(false)}
            />
          </div>
          <div className={isEnglishLanguage ? classes.selectedFlag : ""}>
            <img
              src={EnglishFlag}
              alt={registerPage.iconAlts.englishFlagAlt}
              onClick={() => setIsEnglishLanguage(true)}
            />
          </div>
        </div>
        <form className={classes.registerForm} onSubmit={handleSubmit}>
          {status === "success" ? (
            <></>
          ) : (
            <div className={classes.formContent}>
              <img
                src={whiteLogo}
                alt="White Company logo"
                className={classes.mobileLogo}
              />
              <h2>{registerPage.headerText}</h2>
              <div className={classes.inputs}>
                <div className={classes.twoElements}>
                  <div className={classes.input}>
                    <label htmlFor="first_name">
                      {registerPage.form.firstname}
                    </label>
                    <input type="text" name="first_name" required />
                  </div>
                  <div className={classes.input}>
                    <label htmlFor="last_name">
                      {registerPage.form.lastname}
                    </label>
                    <input type="text" name="last_name" required />
                  </div>
                </div>
                <div className={classes.twoElements}>
                  <div className={classes.input}>
                    <label htmlFor="email">{registerPage.form.email}</label>
                    <input
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className={classes.input}>
                    <label htmlFor="phone_number">
                      {registerPage.form.phone}
                    </label>
                    <input
                      type="tel"
                      name="phone_number"
                      value={phonenumber}
                      onChange={(e) => setPhonenumber(e.target.value)}
                      required
                      onInput={handlePhoneInput}
                    />
                  </div>
                </div>
                <div className={classes.twoElements}>
                  <div className={classes.input}>
                    <label htmlFor="password">
                      {registerPage.form.password}
                    </label>
                    <input
                      type="password"
                      name="password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className={classes.input}>
                    <label htmlFor="confirm_password">
                      {registerPage.form.confirmPassword}
                    </label>
                    <input
                      type="password"
                      name="confirm_password"
                      required
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.registerBtnContainer}>
                <button
                  className={classes.registerBtn}
                  type="submit"
                  disabled={isBtnDisabled}
                >
                  {isStatusLoading ? (
                    <LoadingSpinner black={true} />
                  ) : (
                    registerPage.registerBtn
                  )}
                </button>
              </div>
              {status === "error" && (
                <div className={classes.errorMsg}>{error}</div>
              )}
            </div>
          )}
          {status === "success" && (
            <div className={classes.successMsgDiv}>
              <p>{registerPage.successMsg}</p>
              <button
                onClick={handleDownloadAppModal}
                type="button"
                className={classes.downloadAppBtn}
              >
                {registerPage.downloadApp}
              </button>
            </div>
          )}
        </form>
        {isDownloadAppShown && (
          <div className={classes.downloadModalBg}>
            <DownloadApp />
          </div>
        )}
      </div>
    </section>
  );
}
