import { createSlice } from "@reduxjs/toolkit";
import { fetchDepartments } from "../thunks/getDepartmentThunk";

const departmentsSlice = createSlice({
  name: "departments",
  initialState: {
    departments: [],
    total_count: 0,
    filtered_count: 0,
    department_names: [],
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'error'
    error: null,
  },
  reducers: {
    resetDepartmentsState(state) {
      state.error = null;
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDepartments.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchDepartments.fulfilled, (state, action) => {
        state.departments = action.payload.departments;
        state.total_count = action.payload.total_count;
        state.filtered_count = action.payload.filtered_count;
        state.department_names = action.payload.department_names;
        state.status = "succeeded";
        state.error = null;
      })
      .addCase(fetchDepartments.rejected, (state, action) => {
        state.departments = [];
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const { resetDepartmentsState } = departmentsSlice.actions;
export default departmentsSlice.reducer;
