// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.driverRoute {
  display: flex;
  justify-content: space-between;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 18.2px;
  color: #676c7e;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  &:hover {
    box-shadow: 0px 2px 4px 0px #00000014;
    box-shadow: 0px 6px 20px 0px #00000014;
    background-color: #f6f6f6;
  }
}

.driverAddress {
  width: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.driverRoute div {
  width: 80px;
}

.mapIconDiv {
  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/UI/DriverRoute.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,cAAc;EACd,4DAA4D;EAC5D;IACE,qCAAqC;IACrC,sCAAsC;IACtC,yBAAyB;EAC3B;AACF;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".driverRoute {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  border: 1px solid #f0f0f0;\r\n  border-radius: 8px;\r\n  padding: 12px 16px;\r\n  font-size: 14px;\r\n  line-height: 18.2px;\r\n  color: #676c7e;\r\n  transition: background-color 0.3s ease, box-shadow 0.3s ease;\r\n  &:hover {\r\n    box-shadow: 0px 2px 4px 0px #00000014;\r\n    box-shadow: 0px 6px 20px 0px #00000014;\r\n    background-color: #f6f6f6;\r\n  }\r\n}\r\n\r\n.driverAddress {\r\n  width: 110px;\r\n  white-space: nowrap;\r\n  overflow: hidden;\r\n  text-overflow: ellipsis;\r\n}\r\n\r\n.driverRoute div {\r\n  width: 80px;\r\n}\r\n\r\n.mapIconDiv {\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
