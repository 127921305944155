import React, { useState, useEffect } from "react";
import Logo from "../../images/Logo.svg";
import Background from "../../images/BG.svg";
import NorwayFlag from "../../images/NorwayFlag.svg";
import EnglishFlag from "../../images/EnglishFlag.svg";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../redux/auth/thunks/userThunk";
import { LoadingSpinner } from "../../components/loadingSpinner/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import {
  reqPassReset,
  resetPassReq,
} from "../../redux/requestPassReset/reqPassReset";
import { resetLoginState } from "../../redux/auth/slices/userSlice";
import translations from "../../translations.json";
import classes from "./Login.module.css";
import whiteLogo from "../../images/sidebar/Logo.svg";

export function Login() {
  const [isLoginForm, setIsLoginForm] = useState(true);
  const [isForgottenPass, setIsForgottenPass] = useState(false);
  const [isEnglishLanguage, setIsEnglishLanguage] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userSelector = useSelector((state) => state.user);
  const passResetState = useSelector((state) => state.resetPassReq);

  // Function for resetting password
  function handlePassReset(e) {
    e.preventDefault();
    dispatch(reqPassReset({ email }));
  }

  // Function for forgotten password form
  useEffect(() => {
    if (passResetState.status === "success") {
      setIsForgottenPass(false);
    } else if (passResetState.status === "error") {
      setTimeout(() => {
        dispatch(resetPassReq());
      }, 3000);
    }
  }, [passResetState.status]);

  // Function for login form
  useEffect(() => {
    if (userSelector.status === "success") {
      navigate("/dashboard");
    } else if (userSelector.status === "error") {
      setTimeout(() => {
        dispatch(resetLoginState());
      }, 2500);
    }
  }, [userSelector.status, navigate]);

  // Function for switching to forgotten password form
  const toggleForms = () => {
    setIsLoginForm(!isLoginForm);
    setIsForgottenPass(!isForgottenPass);
  };

  // Function for logging in
  const handleSubmit = (e) => {
    e.preventDefault();
    if (userSelector.status === "idle" || userSelector.status === "error") {
      dispatch(login({ email, password }));
    }
  };

  const isStatusLoading =
    userSelector.status === "loading" || passResetState.status === "loading";
  const isStatusError =
    passResetState.status === "error" || userSelector.status === "error";
  const isBtnDisabled = isStatusLoading || email === "" || password === "";
  const isForgotPassBtnDisabled = isStatusLoading || email === "";
  const loginPage = translations.loginPage;
  const forgottenPasswordPage = translations.forgottenPasswordPage;

  return (
    <section className={classes.loginContainer}>
      <div className={classes.logo}>
        <img src={Logo} alt={loginPage.iconAlts.logoAlt} />
      </div>
      <div className={classes.formBg}>
        <img
          className={classes.background}
          src={Background}
          alt={loginPage.iconAlts.formBgAlt}
        />
        <div className={classes.flags}>
          <div className={!isEnglishLanguage ? classes.selectedFlag : ""}>
            <img
              src={NorwayFlag}
              alt={loginPage.iconAlts.norwayFlagAlt}
              onClick={() => setIsEnglishLanguage(false)}
            />
          </div>
          <div className={isEnglishLanguage ? classes.selectedFlag : ""}>
            <img
              src={EnglishFlag}
              alt={loginPage.iconAlts.englishFlagAlt}
              onClick={() => setIsEnglishLanguage(true)}
            />
          </div>
        </div>

        {isLoginForm && (
          <form className={classes.loginForm} onSubmit={handleSubmit}>
            <img
              src={whiteLogo}
              alt="White Company logo"
              className={classes.mobileLogo}
            />
            <h2>{loginPage.headerText}</h2>
            <div className={classes.inputs}>
              <div className={classes.input}>
                <label htmlFor="email">{loginPage.form.email}</label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value.trim())}
                  required
                />
              </div>
              <div className={classes.input}>
                <label htmlFor="password">{loginPage.form.password}</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
            </div>
            <button
              className={classes.loginBtn}
              type="submit"
              disabled={isBtnDisabled}
            >
              {isStatusLoading ? (
                <LoadingSpinner black={true} />
              ) : (
                loginPage.loginBtn
              )}
            </button>
            <button className={classes.forgotPass} onClick={toggleForms}>
              {loginPage.forgotPassBtn}
            </button>
            {isStatusError && (
              <div className={classes.errorMsg}>{userSelector.error}</div>
            )}
          </form>
        )}

        {isForgottenPass && (
          <form className={classes.forgotPassForm} onSubmit={handlePassReset}>
            <img
              src={whiteLogo}
              alt="White Company logo"
              className={classes.mobileLogo}
            />
            <h2>{forgottenPasswordPage.headerText}</h2>
            <div className={classes.formContent}>
              <p>
                {forgottenPasswordPage.message}
                <br />
                {forgottenPasswordPage.instruction}
              </p>
              <div className={classes.input}>
                <label htmlFor="email">
                  {forgottenPasswordPage.form.email}
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value.trim())}
                  required
                />
              </div>
              <button
                className={classes.resetPassBtn}
                type="submit"
                disabled={isForgotPassBtnDisabled}
              >
                {isStatusLoading ? (
                  <LoadingSpinner black={true} />
                ) : (
                  forgottenPasswordPage.resetPassBtn
                )}
              </button>
            </div>
            <button className={classes.backToLogin} onClick={toggleForms}>
              {forgottenPasswordPage.backToLoginBtn}
            </button>
            {isStatusError && (
              <div className={classes.errorMsg}>{passResetState.error}</div>
            )}
          </form>
        )}

        {passResetState.status === "success" && (
          <div className={classes.checkEmail}>
            <h2>{translations.passwordReset.heading}</h2>
            <p>
              {translations.passwordReset.message + " "}
              <strong>{email}</strong>
            </p>
          </div>
        )}
      </div>
    </section>
  );
}
