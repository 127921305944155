import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../../constants";

export const createDepartment = createAsyncThunk(
  "department/createDepartment",
  async (data, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.user.token;

    const departmentData = {
      d_name: data.d_name,
      d_phonenumber: data.d_phonenumber,
      d_address: data.d_address,
      d_city:data.d_city,
      d_country:data.d_country,
      d_postalcode:data.d_postalcode,
      d_latitude: data.d_latitude,  // Include latitude
      d_longitude: data.d_longitude, // Include longitude
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(departmentData),
    };

    try {
      const response = await fetch(
        `${apiUrl}/create_department`,
        requestOptions
      );

      if (!response.ok) {
        let errorMessage = "Network response was not ok";
        try {
          const error = await response.json();
          errorMessage = error.msg || errorMessage;
        } catch (e) {
          errorMessage = await response.text();
        }
        throw new Error(errorMessage || "Unknown error occurred");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Department creation failed:", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
