// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 9px;
  cursor: pointer;
}

.dropdown button {
  color: #676c7e;
  width: 100%;
  display: flex;
  background-color: transparent;
  border: none;
  padding: 0;
  height: 21.55px;
  cursor: pointer;
  font-size: 1rem;
}

.dropdown-header {
  cursor: pointer;
  background-color: white;
  color: rgba(164, 165, 177, 1);
}

.dropdown-body {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  background-color: white;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-body input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
}

.dropdown-list {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 250px;
  overflow-y: auto;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}
`, "",{"version":3,"sources":["webpack://./src/components/searchableDropdown/SearchableDropdown.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,aAAa;EACb,6BAA6B;EAC7B,YAAY;EACZ,UAAU;EACV,eAAe;EACf,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,uBAAuB;EACvB,6BAA6B;AAC/B;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,QAAQ;EACR,sBAAsB;EACtB,uBAAuB;EACvB,WAAW;EACX,wCAAwC;AAC1C;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,6BAA6B;EAC7B,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,SAAS;EACT,UAAU;EACV,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".dropdown {\r\n  position: relative;\r\n  width: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 9px;\r\n  cursor: pointer;\r\n}\r\n\r\n.dropdown button {\r\n  color: #676c7e;\r\n  width: 100%;\r\n  display: flex;\r\n  background-color: transparent;\r\n  border: none;\r\n  padding: 0;\r\n  height: 21.55px;\r\n  cursor: pointer;\r\n  font-size: 1rem;\r\n}\r\n\r\n.dropdown-header {\r\n  cursor: pointer;\r\n  background-color: white;\r\n  color: rgba(164, 165, 177, 1);\r\n}\r\n\r\n.dropdown-body {\r\n  position: absolute;\r\n  top: 100%;\r\n  left: 0;\r\n  right: 0;\r\n  border: 1px solid #ccc;\r\n  background-color: white;\r\n  z-index: 10;\r\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.dropdown-body input {\r\n  width: 100%;\r\n  padding: 10px;\r\n  box-sizing: border-box;\r\n  border: none;\r\n  border-bottom: 1px solid #ccc;\r\n  outline: none;\r\n}\r\n\r\n.dropdown-list {\r\n  list-style: none;\r\n  margin: 0;\r\n  padding: 0;\r\n  max-height: 250px;\r\n  overflow-y: auto;\r\n}\r\n\r\n.dropdown-item {\r\n  padding: 10px;\r\n  cursor: pointer;\r\n  display: flex;\r\n  gap: 1rem;\r\n  align-items: center;\r\n}\r\n\r\n.dropdown-item:hover {\r\n  background-color: #f0f0f0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
