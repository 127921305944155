import "./DriverRoute.css";
import MapIcon from "../../images/drivers/mapIcon.svg";
import { getStatusClass } from "../../helperFunctions/getStatusClass";
import { useDispatch } from "react-redux";
import { fetchDeliveries } from "../../redux/package/thunks/getDeliveryThunk";
import {
  setIsModalsBgShown,
  toggleDriverInfoModal,
  setIsDriverRoutes,
  setIsDriverLocationShown,
} from "../../redux/globalFunctions/globalFnSlice";
import translations from "../../translations.json";

export function DriverRoute({ orderNumber, date, time, address, status }) {
  const dispatch = useDispatch();

  function handleClick() {
    dispatch(fetchDeliveries({ package_id: orderNumber }));
    dispatch(toggleDriverInfoModal());
    dispatch(setIsModalsBgShown(true));
    dispatch(setIsDriverLocationShown(true));
    dispatch(setIsDriverRoutes(true));
  }

  return (
    <div className="driverRoute">
      <div>{orderNumber}</div>
      <div>{date}</div>
      <div>{time}</div>
      <div className="driverAddress">{address}</div>
      <div className={getStatusClass(status)}>{status}</div>
      <div className="mapIconDiv" onClick={handleClick}>
        <img
          src={MapIcon}
          alt={translations.driverInfoModal.routes.mapIconAlt}
        />
      </div>
    </div>
  );
}
