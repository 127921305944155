// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.driver {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 12px 16px;
  color: #676c7e;
  line-height: 24px;
  width: 100%;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  &:hover {
    box-shadow: 0px 2px 4px 0px #00000014;
    box-shadow: 0px 6px 20px 0px #00000014;
    background-color: #f6f6f6;
  }
}

.mapIconDiv img {
  cursor: pointer;
}

.driver > div:first-child {
  width: 50px;
  word-wrap: break-word;
}

.driver :nth-child(n + 2) {
  width: 150px;
  word-wrap: break-word;
  padding: 0 5px;
}

.driver div:nth-child(5) {
  width: 80px;
}

.driver div:nth-child(6),
.driver div:nth-child(7),
.driver div:nth-child(8) {
  width: 80px;
}

.driver button {
  border: none;
  background-color: transparent;
  font-size: 30px;
  font-weight: 700;
  padding: 0;
  cursor: pointer;
  transition: color 0.3s ease;
  &:hover {
    color: #3082f6;
  }
}

.driver img {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/UI/DriversTable.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;EAClB,cAAc;EACd,iBAAiB;EACjB,WAAW;EACX,4DAA4D;EAC5D;IACE,qCAAqC;IACrC,sCAAsC;IACtC,yBAAyB;EAC3B;AACF;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,WAAW;AACb;;AAEA;;;EAGE,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,6BAA6B;EAC7B,eAAe;EACf,gBAAgB;EAChB,UAAU;EACV,eAAe;EACf,2BAA2B;EAC3B;IACE,cAAc;EAChB;AACF;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".driver {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n  border: 1px solid #f0f0f0;\r\n  border-radius: 8px;\r\n  padding: 12px 16px;\r\n  color: #676c7e;\r\n  line-height: 24px;\r\n  width: 100%;\r\n  transition: background-color 0.3s ease, box-shadow 0.3s ease;\r\n  &:hover {\r\n    box-shadow: 0px 2px 4px 0px #00000014;\r\n    box-shadow: 0px 6px 20px 0px #00000014;\r\n    background-color: #f6f6f6;\r\n  }\r\n}\r\n\r\n.mapIconDiv img {\r\n  cursor: pointer;\r\n}\r\n\r\n.driver > div:first-child {\r\n  width: 50px;\r\n  word-wrap: break-word;\r\n}\r\n\r\n.driver :nth-child(n + 2) {\r\n  width: 150px;\r\n  word-wrap: break-word;\r\n  padding: 0 5px;\r\n}\r\n\r\n.driver div:nth-child(5) {\r\n  width: 80px;\r\n}\r\n\r\n.driver div:nth-child(6),\r\n.driver div:nth-child(7),\r\n.driver div:nth-child(8) {\r\n  width: 80px;\r\n}\r\n\r\n.driver button {\r\n  border: none;\r\n  background-color: transparent;\r\n  font-size: 30px;\r\n  font-weight: 700;\r\n  padding: 0;\r\n  cursor: pointer;\r\n  transition: color 0.3s ease;\r\n  &:hover {\r\n    color: #3082f6;\r\n  }\r\n}\r\n\r\n.driver img {\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
