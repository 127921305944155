import { combineReducers } from "redux";
import userReducer from "./auth/slices/userSlice"; // User related slice
import registerReducer from "./driver/slices/registerDriverSlice"; // Driver registration slice
import validateTokenReducer from "./driver/slices/validateDriverInvitationSlice"; // Driver token validation slice
import inviteDriverSlice from "./driver/slices/inviteDriverSlice"; // Driver invitation slice
import deleteDriverSlice from "./driver/slices/deleteDriverSlice"; // Driver deletion slice
import updateDriverSlice from "./driver/slices/updateDriverSlice"; // Driver update slice
import getDriverReducer from "./driver/slices/getDriverSlice"; // Get drivers slice

import createCustomerSlice from "./customer/slices/createCustomerSlice"; // Customer creation slice
import updateCustomerSlice from "./customer/slices/updateCustomerSlice"; // Customer update slice
import deleteCustomerSlice from "./customer/slices/deleteCustomerSlice"; // Customer deletion slice
import getCustomerSlice from "./customer/slices/getCustomerSlice"; // Customer get slice

import createDepartmentSlice from "./department/slices/createDepartmentSlice"; // Department creation slice
import updateDepartmentSlice from "./department/slices/updateDepartmentSlice"; // Department update slice
import deleteDepartmentSlice from "./department/slices/deleteDepartmentSlice"; // Department deletion slice
import getDepartmentSlice from "./department/slices/getDepartmentSlice"; // Department get slice

import createCompanySlice from "./company/slices/createCompanySlice"; // Company creation slice
import deleteCompanySlice from "./company/slices/deleteCompanySlice"; // Company deletion slice
import updateCompanySlice from "./company/slices/updateCompanySlice"; // Company update slice

import createDeliverySlice from "./package/slices/createDeliverySlice"; // Delivery creation slice
import cancelPackageSlice from "./package/slices/cancelPackageSlice"; // Package cancellation slice
import updatePackageSlice from "./package/slices/updatePackageSlice"; // Package update slice
import getDeliveryReducer from "./package/slices/getDeliverySlice"; // Get deliveries slice
import getCurrentDeliveriesReducer from "./package/slices/getCurrentDeliveriesSlice"; // Get current deliveries slice
import getDeliveryHistoryReducer from "./package/slices/getDeliveryHistorySlice"; // Get delivery history slice
import getDeliveriesByDate from "./package/slices/getDeliveriesByDateSlice"; // Get deliveries by date slice

import getVehiclesReducer from "./vehicle/slices/getVehicleSlice"; // Get vehicles slice

import searchReducer from "./misc/slices/searchSlice"; // Search slice
import reqPassReset from "./requestPassReset/reqPassReset";
import resetPass from "./resetPass/resetPass";
import formDataSlice from "./formData/formDataSlice";
import globalFnSlice from "./globalFunctions/globalFnSlice";
import getDriverLocation from "./driver/slices/getDriverLocation";

const rootReducer = combineReducers({
  // User slices
  user: userReducer,
  resetPassReq: reqPassReset,
  resetPassword: resetPass,

  // Driver related slices
  register: registerReducer,
  validateToken: validateTokenReducer,
  inviteDriver: inviteDriverSlice,
  deleteDriver: deleteDriverSlice,
  updateDriver: updateDriverSlice,
  drivers: getDriverReducer,
  driverLocation: getDriverLocation,

  // Customer related slices
  createCustomer: createCustomerSlice,
  updateCustomer: updateCustomerSlice,
  deleteCustomer: deleteCustomerSlice,
  customers: getCustomerSlice,

  // Department related slices
  createDepartment: createDepartmentSlice,
  updateDepartment: updateDepartmentSlice,
  deleteDepartment: deleteDepartmentSlice,
  departments: getDepartmentSlice,

  // Company related slices
  createCompany: createCompanySlice,
  deleteCompany: deleteCompanySlice,
  updateCompany: updateCompanySlice,

  // Package related slices
  createDelivery: createDeliverySlice,
  cancelDelivery: cancelPackageSlice,
  updatePackage: updatePackageSlice,
  currentDeliveries: getCurrentDeliveriesReducer,
  deliveryHistory: getDeliveryHistoryReducer,
  deliveriesByDate: getDeliveriesByDate,
  deliveries: getDeliveryReducer,

  // Vehicle slices
  vehicles: getVehiclesReducer,

  // Search slice
  search: searchReducer,

  // Form slice
  formData: formDataSlice,

  // Global functions slice
  global: globalFnSlice,
});

export default rootReducer;
