import { createSlice } from "@reduxjs/toolkit";
import { fetchDrivers } from "../thunks/getDriverThunk";

const getDriverReducer = createSlice({
  name: "drivers",
  initialState: {
    drivers: [],
    total_count: 0,
    filtered_count: 0,
    driver_names: [],
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'error'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDrivers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDrivers.fulfilled, (state, action) => {
        state.drivers = action.payload.drivers;
        state.total_count = action.payload.total_count;
        state.filtered_count = action.payload.filtered_count;
        state.driver_names = action.payload.driver_names;
        state.status = "succeeded";
        state.error = null;
      })
      .addCase(fetchDrivers.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      });
  },
});

export default getDriverReducer.reducer;
