export const initialState = {
  selectedDriverId: null,
  isDashboardClicked: true,
  isDeliveriesClicked: false,
  isDriversClicked: false,
  isOrderInfoClicked: false,
  isModalsBgShown: false,
  isAddressesClicked: false,
  isActiveSidebarBtn: "dashboard",
  showThreeDotsModal: false,
  modalPosition: { x: 0, y: 0 },
  isEditMode: false,
  isWithHistory: false,
  isDeleteModalShown: false,
  isAddNewDriverShown: false,
  isDriverLocationShown: false,
  isDriverInfoShown: false,
  isDriverRoutes: false,
  isEditModalShown: false,
  isDeleteConfirmShown: false,
  isDeleteCustomerAddress: false,
  isAddNewCustomerAddress: false,
  selectedDataForEdit: null,
  isCustomerActive: true,
  selectedDelivery: null,
  isAddLaundry: false,
  isErrorMsg: false,
  isMapShown: false,
  errorMsg: "",
  successMsg: "",
  addressData: {},
  selectedDriverInfo: null,
  isDriverChanged: false,
  isDownloadAppShown: false,
};
