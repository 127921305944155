import { useEffect, useRef, useState, useCallback } from "react";
import "./DriverLocation.css";
import CloseIcon from "../../images/deliveries/deliveriesModals/xIcon.svg";
import Icon from "../../images/orderInformation/Icon.svg";
import ArrowBlueLeft from "../../images/orderInformation/arrowBlueLeft.svg";
import ArrowBlueRight from "../../images/orderInformation/arrowBlueRight.svg";
import ArrowGrayLeft from "../../images/orderInformation/arrowGrayLeft.svg";
import ArrowGrayRight from "../../images/orderInformation/arrowGrayRight.svg";
import { useEscapeKeyClose } from "../../helperFunctions/customHooks/useEscapeKeyClose";
import { useClickOutsideClose } from "../../helperFunctions/customHooks/useClickOutsideClose";
import Map, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useDispatch, useSelector } from "react-redux";
import { formatDateString } from "../../helperFunctions/formatDate";
import { formatTime } from "../../helperFunctions/formatTime";
import { toggleDriverLocationModal } from "../../redux/globalFunctions/globalFnSlice";
import translations from "../../translations.json";
import { fetchDriverLocation } from "../../redux/driver/thunks/getDriverLocation";
import CircleWithDotBlue from "../../images/orderInformation/circleWithDotIconBlue.svg";
import CircleWithDotGray from "../../images/orderInformation/circleWithDotIconGray.svg";
import AddressIconBlue from "../../images/orderInformation/addressIconBlue.svg";
import AddressIconGray from "../../images/orderInformation/addressIconGray.svg";
import AddressIconRed from "../../images/orderInformation/addressIconRed.svg";

// Cache for geocoding results
const geocodeCache = {};

export function DriverLocation() {
  const { isDriverRoutes } = useSelector((state) => state.global);
  const currentDeliveriesState = useSelector((state) => state.currentDeliveries);
  const deliveries = currentDeliveriesState.deliveries || [];
  const deliveriesState = useSelector((state) => state.currentDeliveries);
  const deliveryByIdState = useSelector((state) => state.deliveries);
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const mapRef = useRef(null);
  const geocodingTimeoutRef = useRef(null);
  const retryCountRef = useRef(0);
  const MAX_RETRIES = 3;

  const [coordinates, setCoordinates] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isMapInitialized, setIsMapInitialized] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const delivery = deliveries[currentIndex] || {};
  const deliveryById = useSelector((state) => state.deliveries.delivery);
  const mapboxToken = "pk.eyJ1IjoiYm96aWRhcmszNSIsImEiOiJjbTFxNTdzMWUwOWZwMmpzYjN4eHF5Zmt5In0.fVeom9WzttFClBuhzNSjKg";

  const driverLocations = useSelector(
    (state) => state.driverLocation.locations?.data || []
  );

  // Functions for closing the modal when pressing Escape button on keyboard or clicking outside the modal.
  useEscapeKeyClose(() => dispatch(toggleDriverLocationModal()));
  useClickOutsideClose(modalRef, () => dispatch(toggleDriverLocationModal()));

  // Handle navigation between deliveries
  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < deliveries.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const isFirst = currentIndex === 0;
  const isLast = currentIndex === deliveries.length - 1;
  const hasOnlyOneDelivery = deliveries.length === 1;

  // Geocode a location and cache the result - memoized with useCallback
  const geocodeLocation = useCallback(async (location) => {
    if (!location) return null;
    
    // Check cache first
    if (geocodeCache[location]) {
      console.log("Using cached coordinates for:", location);
      return geocodeCache[location];
    }

    try {
      console.log("Geocoding location:", location);
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          location
        )}.json?access_token=${mapboxToken}`
      );
      
      if (!response.ok) {
        throw new Error(`Geocoding API error: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.features && data.features.length > 0) {
        const [longitude, latitude] = data.features[0].center;
        const result = { latitude, longitude };
        
        // Store in cache
        geocodeCache[location] = result;
        console.log("Successfully geocoded:", location, result);
        return result;
      } else {
        console.warn("No features found for location:", location);
      }
    } catch (error) {
      console.error("Error geocoding location:", location, error);
    }
    return null;
  }, [mapboxToken]);

  // Fetch driver locations once on mount
  useEffect(() => {
    dispatch(fetchDriverLocation({}));
    
    // Cleanup timeout on unmount
    return () => {
      if (geocodingTimeoutRef.current) {
        clearTimeout(geocodingTimeoutRef.current);
      }
    };
  }, [dispatch]);

  // Function to update map location - extracted for reuse
  const updateMapLocation = useCallback(async () => {
    setIsLoading(true);
    
    // If we've retried too many times, stop
    if (retryCountRef.current >= MAX_RETRIES) {
      console.warn("Max retries reached for geocoding");
      setIsLoading(false);
      return;
    }
    
    let addressCoordinates = null;
    const address = !isDriverRoutes ? 
      (delivery?.p_pickup_address_name || delivery?.p_pickup_address) : 
      (deliveryById?.pickup_address_name || deliveryById?.pickup_address);

    if (address) {
      addressCoordinates = await geocodeLocation(address);
    }
    
    if (addressCoordinates) {
      console.log("Setting coordinates:", addressCoordinates);
      setCoordinates(addressCoordinates);
      setIsLoading(false);
      
      // If map is ready, fly to location
      if (mapRef.current && isMapInitialized) {
        try {
          mapRef.current.flyTo({
            center: [addressCoordinates.longitude, addressCoordinates.latitude],
            zoom: 14,
            duration: 1000
          });
        } catch (error) {
          console.error("Error flying to location:", error);
        }
      }
    } else {
      // If we still don't have coordinates, retry after a delay (only if we have data to try)
      if (address) {
        console.log("Retrying geocoding, attempt:", retryCountRef.current + 1);
        retryCountRef.current += 1;
        
        // Exponential backoff for retries
        const delay = Math.min(1000 * Math.pow(2, retryCountRef.current), 10000);
        geocodingTimeoutRef.current = setTimeout(updateMapLocation, delay);
      } else {
        setIsLoading(false);
      }
    }
  }, [isDriverRoutes, delivery, deliveryById, geocodeLocation, isMapInitialized]);

  // Update the map when delivery data changes
  useEffect(() => {
    // Only reset retry count and clear pending timeouts
    retryCountRef.current = 0;
    
    if (geocodingTimeoutRef.current) {
      clearTimeout(geocodingTimeoutRef.current);
    }
    
    // Update location if map is already initialized
    if (isMapInitialized) {
      updateMapLocation();
    }
  }, [delivery, deliveryById, isMapInitialized, updateMapLocation]);

  // Handle initial map load and location update
  const handleMapLoad = useCallback(() => {
    setIsMapInitialized(true);
    
    // If we already have coordinates when the map loads, fly to them
    if (coordinates && mapRef.current) {
      try {
        mapRef.current.flyTo({
          center: [coordinates.longitude, coordinates.latitude],
          zoom: 14,
          duration: 1000
        });
      } catch (error) {
        console.error("Error flying to location on map load:", error);
      }
    } else {
      // First time loading - try to update location
      updateMapLocation();
    }
  }, [coordinates, updateMapLocation]);

  const getStatusStyles = () => {
    const status = !isDriverRoutes ? delivery?.p_status : deliveryById?.status;
    const date = !isDriverRoutes ? 
      formatDateString(delivery?.p_order_date) : 
      deliveryById?.order_date;
    const time = !isDriverRoutes ? 
      formatTime(delivery?.p_order_time) : 
      deliveryById?.order_time;

    let firstStatus = "New";
    let secondStatus = "Picked";
    let thirdStatus = "Delivered";
    let firstIcon = CircleWithDotBlue;
    let secondIcon = CircleWithDotGray;
    let thirdIcon = AddressIconGray;
    let firstLineClass = "gray";
    let secondLineClass = "gray";

    switch (status) {
      case "Accepted":
        firstStatus = "Accepted";
        firstIcon = CircleWithDotBlue;
        secondIcon = CircleWithDotGray;
        break;

      case "Picked":
        firstStatus = "Accepted";
        secondIcon = CircleWithDotBlue;
        secondLineClass = "blue";
        break;

      case "Delivered":
        firstStatus = "Accepted";
        secondIcon = CircleWithDotBlue;
        thirdIcon = AddressIconBlue;
        firstLineClass = "blue";
        secondLineClass = "blue";
        break;

      case "Cancelled":
        firstStatus = "Accepted";
        secondIcon = CircleWithDotBlue;
        thirdStatus = "Cancelled";
        thirdIcon = AddressIconRed;
        firstLineClass = "blue";
        secondLineClass = "blue";
        break;

      default:
        break;
    }

    return {
      icon1: firstIcon,
      icon2: secondIcon,
      icon3: thirdIcon,
      lineClass1: firstLineClass,
      lineClass2: secondLineClass,
      statusText1: firstStatus,
      statusText2: secondStatus,
      statusText3: thirdStatus,
      showDate1: status === "New" || status === "Accepted",
      showDate2: status === "Picked",
      showDate3: status === "Delivered" || status === "Cancelled",
      date,
      time
    };
  };

  const statusStyles = getStatusStyles();
  const isStatusLoading =
    deliveriesState.status === "loading" ||
    deliveryByIdState.status === "loading";

  const modalText = translations.driverLocationsModal;

  // Initial view state is only set once when the map is first created
  const initialViewState = {
    latitude: 51.505,  // Default central view
    longitude: -0.09,
    zoom: 3
  };

  return (
    <div className="driverLocationModal" ref={modalRef}>
      <div className="topChildDiv">
        <div style={{ display: "flex", gap: "16px" }}>
          <div className="driverLocationImgDiv">
            <img src={Icon} alt="Icon" />
          </div>
          <div className="driverInfoContainer">
            <div className="driverName"></div>
            <div className="orderNumber">
              {modalText.order}
              {deliveriesState.status === "succeeded" && !isStatusLoading && (
                <span>
                  {!isDriverRoutes ? delivery?.p_id : deliveryById?.package_id}
                </span>
              )}
            </div>
          </div>
        </div>
        <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
          <div style={{ display: "flex", gap: "4px", marginRight: "6px", paddingRight: "12px", borderRight: "1.3px solid lightgray" }}>

            <button
              onClick={handlePrev}
              disabled={isFirst || hasOnlyOneDelivery}
              className="prevBtnDashboard"
            >
              <img
                src={(isFirst || hasOnlyOneDelivery) ? ArrowGrayLeft : ArrowBlueLeft}
                alt={translations.orderInformation.iconAlts.arrowLeftAlt}
              />
            </button>
            <button
              onClick={handleNext}
              disabled={isLast || hasOnlyOneDelivery}
              className="nextBtnDashboard"
            >
              <img
                src={(isLast || hasOnlyOneDelivery) ? ArrowGrayRight : ArrowBlueRight}
                alt={translations.orderInformation.iconAlts.arrowRightAlt}
              />
            </button>
          </div>
          <img
            src={CloseIcon}
            alt={modalText.closeIconAlt}
            className="closeDriverLocationBtn"
            onClick={() => dispatch(toggleDriverLocationModal())}
          />
        </div>
      </div>
      {isStatusLoading && (
        <div className="notificationsDiv">
          <span className="blackLoader"></span>
        </div>
      )}
      {deliveriesState.status === "error" && (
        <div className="notificationsDiv">
          <div>No current deliveries for this driver.</div>
        </div>
      )}
      {deliveries.length > 0 &&
        !isStatusLoading &&
        deliveriesState.status === "succeeded" && (
          <div className="driverLocationContent">
            <div className="leftSideContent">
              <div style={{ 
                position: "relative", 
                borderRadius: "20px", 
                overflow: "hidden",
                height: "100%"
              }}>
                {isLoading && (
                  <div style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    zIndex: 10
                  }}>
                    <div style={{ 
                      width: "40px",
                      height: "40px",
                      border: "4px solid #f3f3f3",
                      borderTop: "4px solid #1E88E5",
                      borderRadius: "50%",
                      animation: "spin 1s linear infinite"
                    }} />
                  </div>
                )}
                
                <Map
                  ref={mapRef}
                  initialViewState={initialViewState}
                  mapStyle="mapbox://styles/mapbox/streets-v11"
                  mapboxAccessToken={mapboxToken}
                  style={{ width: "100%", height: "100%" }}
                  scrollZoom={false}
                  dragRotate={false}
                  attributionControl={false}
                  onLoad={handleMapLoad}
                  reuseMaps
                >
                  {coordinates && (
                    <Marker
                      latitude={coordinates.latitude}
                      longitude={coordinates.longitude}
                      color="#1E88E5"
                    />
                  )}
                </Map>
              </div>
            </div>

            <div className="rightSideContent">
              <div className="orderInformationContent">
                <h6>{modalText.orderInformation}</h6>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <small style={{ width: "70px" }}>{modalText.date}</small>
                  <p>
                    {!isDriverRoutes
                      ? formatDateString(delivery?.p_order_date)
                      : deliveryById?.order_date}
                  </p>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <small style={{ width: "70px" }}>{modalText.time}</small>
                  <p>
                    {!isDriverRoutes
                      ? formatTime(delivery?.p_order_time)
                      : deliveryById?.order_time}
                  </p>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <small style={{ width: "70px" }}>{modalText.address}</small>
                  <p>
                    {!isDriverRoutes
                      ? delivery?.p_dropoff_address_name
                      : deliveryById?.dropoff_address_name}
                  </p>
                </div>
              </div>
              <div className="shippingInfo">
                <div className="shipped">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <img
                      src={statusStyles.icon1}
                      alt={modalText.circleWithDotAlt}
                    />
                    <div className={`line ${statusStyles.lineClass1}`}></div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <small>{statusStyles.statusText1}</small>
                    {statusStyles.showDate1 && <p>{statusStyles.date} {statusStyles.time}</p>}
                  </div>
                </div>
                <div className="onTheWay">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <img
                      src={statusStyles.icon2}
                      alt={modalText.circleWithDotAlt}
                    />
                    <div className={`line ${statusStyles.lineClass2}`}></div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <small>{statusStyles.statusText2}</small>
                    {statusStyles.showDate2 && <p>{statusStyles.date} {statusStyles.time}</p>}
                  </div>
                </div>
                <div className="delivered">
                  <img
                    src={statusStyles.icon3}
                    alt={modalText.addressIconAlt}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <small>{statusStyles.statusText3}</small>
                    {statusStyles.showDate3 && <p>{statusStyles.date} {statusStyles.time}</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      
      {/* Add CSS for loading spinner animation */}
      <style>
  {`
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }

    .prevBtnDashboard,
    .nextBtnDashboard {
      background: white; /* Ensures white background when enabled */
      border: none;
      cursor: pointer;
      padding: 8px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: blue; /* Ensures arrow (icon/text) is blue */
    }

    .prevBtnDashboard:disabled,
    .nextBtnDashboard:disabled {
      background-color: rgb(238, 236, 236);
      cursor: not-allowed;
      color: gray; /* Arrow turns gray when disabled */
    }

    .prevBtnDashboard:not(:disabled):hover,
    .nextBtnDashboard:not(:disabled):hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  `}
</style>

    </div>
  );
}